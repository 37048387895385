@import url('~@/styles/utils.less');

.searchWrapper {
    display: flex;

    &:hover {
        .searchTitle {
            color: var(--theme-color) !important;

            span {
                color: var(--theme-color);
            }
        }
    }

    .searchImage {
        position: relative;
        z-index: 1;
        display: flex;
        flex-shrink: 0;
        align-items: center;
        width: 234px;
        height: 156px;
        margin-right: 12px;
        overflow: hidden;
        border-radius: 4px;

        & img {
            display: block;
            width: 234px;
            height: 156px;
            object-fit: cover;
        }
    }

    .searchVideoImg {
        &::after {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            opacity: 0;
            content: '';
        }

        .video_play_btn {
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            margin: auto;
            display: flex;
            justify-content: center;
            align-items: center;
            background-color: rgba(0, 0, 0, 0.3);
            opacity: 0;
            z-index: 10;
            transition: all 0.3s;
        }

        &:hover {
            .video_play_btn {
                opacity: 1;
            }

            &::after {
                opacity: 1;
            }
        }
    }
    .showVideoImg {
        .video_play_btn {
            opacity: 1;
        }

        &::after {
            opacity: 1;
        }
    }

    .searchMain {
        display: flex;
        flex: 1;
        flex-direction: column;
        overflow: hidden;
    }

    .linkBox {
        display: flex;
        flex: 1;
        flex-direction: column;
    }

    .titleWrapper {
        display: flex;
        margin-bottom: 8px;
    }

    .searchTitle {
        .maxLine(1);

        color: var(--pc-normal-color);
        font-weight: 500;
        font-family: PingFangSC-Medium;
        line-height: 26px;
        font-size: var(--pc-font-size-18);
        white-space: normal;

        span {
            color: var(--pc-base-f7-color);
        }

        &:hover {
            color: var(--pc-primary-color);
        }
    }

    .searchContent {
        .maxLine(2);

        margin-bottom: 32px;
        color: #666;
        font-weight: normal;
        font-size: 14px;
        line-height: 22px;
    }

    .searchBottom {
        display: flex;
        justify-content: space-between;
        overflow: hidden;
        font-size: 14px;
        line-height: 22px;
        color: #999;

        .searchBottomLeft {
            margin-right: 20px;
            display: flex;
            justify-content: center;
            align-items: center;

            .searchRuleName {
                .maxLine(1);
                color: #262b2e;
            }
            .searchTime {
                flex-shrink: 0;
            }
            .view_num {
                display: flex;
                align-items: center;
                span {
                    margin-right: 8px;
                }
                &::before {
                    content: '';
                    display: inline-block;
                    width: 1px;
                    height: 12px;
                    margin: 0 11.5px;
                    background-color: #e5e5e5;
                }
            }

            i {
                height: 12px;
                margin: 0 11.5px;
                display: inline-block;
                flex-grow: 0;
                // transform: rotate(-270deg);
                border: solid 1px #e5e5e5;
            }

            span {
                // height: 22px;
                display: flex;
                align-items: flex-end;
                // line-height: 22px;
                color: #999;
            }
        }

        .searchBottomRight {
            flex: 1;
            overflow: hidden;
            white-space: nowrap;
            text-align: right;
            text-overflow: ellipsis;

            a,
            span {
                flex-shrink: 0;
                color: #999;

                &:hover {
                    color: var(--theme-color);
                }
            }
        }
    }
}
