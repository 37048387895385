.footer {
    box-sizing: border-box;
    width: 100%;
    padding: 16px 12px 90px;
    background-color: #262933;
}

.contain {
    max-width: 1376px;
    margin: 0 auto;
}

.risk_tip,
.icp_msg {
    color: #999;
    font-size: 12px;
    line-height: 1.5;
    word-break: break-word;

    a {
        color: #2c36de;
        font-weight: 500;
        font-family: PingFangSC-Medium;
    }

    p {
        min-height: 24px;
        margin-bottom: 0;

        &:last-child {
            margin: 0;
        }
    }

    * {
        font-size: 12px !important;
    }
}

.icp_msg {
    margin-top: 24px;
}

.barkly_container {
    .risk_tip {
        :global {
            a {
                color: #fff;
            }
        }
    }
}
