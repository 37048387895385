.menu {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 30px;
    height: 30px;
    cursor: pointer;

    span {
        font-size: 16px;
    }
}
.barkly_container {
    ul {
        li {
            background-color: #fff;
        }
    }
}
