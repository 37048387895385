.list {
    display: flex;
    align-items: center;
}

.listItem {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 0 12px;
    position: relative;

    &::after {
        position: absolute;
        top: 2px;
        right: 0;
        width: 0.5px;
        height: calc(100% - 4px);
        content: '';
        background-color: #e5e5e5;
    }

    &:first-child {
        padding-left: 0;
    }

    &:last-child {
        padding-right: 0;

        &::after {
            display: none;
        }
    }
}

.codePhone,
.codeTitle {
    color: #262626;
    font-size: 12px;
    text-align: center;
    line-height: 1.5;
}

.codePhone {
    margin-top: 12px;
}

.content {
    display: flex;
    align-items: center;
    justify-content: center;
    line-height: 24px;
    padding: 7px 12px;
    border-radius: 4px;
    cursor: pointer;

    &:hover {
        background-color: #f7f7f8;
    }

    .icon {
        margin-right: 8px;
        font-size: 20px;
    }

    .name {
        font-size: 16px;
    }
}

.antPopover {
    :global {
        .ant-popover-inner {
            padding: 12px;
        }
    }
}

.barkly_container {
    span {
        color: #fff;
    }
    .icon {
        color: #fff;
    }
}
