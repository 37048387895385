.activity {
    width: 278px;
    padding: 24px 16px;
    margin-bottom: 24px;
    border-radius: 8px;
    box-sizing: border-box;
    position: relative;
    overflow: hidden;
    box-shadow: 0 0 16px 0 rgba(0, 0, 0, 0.05);
    background-image: linear-gradient(to bottom, #fffaf7, #fff 20%, #fff 100%);
}

.watermark {
    display: block;
    width: 111px;
    height: 76px;
    position: absolute;
    top: 0;
    left: 0;
    border-radius: 8px 0 76px 0;
    z-index: 0;
    background-image: linear-gradient(38deg, #fff6f6 20%, #ffe8d3 68%);
}

.activityCon {
    position: relative;

    .activityTit {
        display: flex;
        align-items: center;
        justify-content: space-between;

        & > div {
            display: flex;
            align-items: center;

            span {
                display: block;

                &:nth-of-type(1) {
                    margin-right: 8px;
                    font-size: 24px;
                    font-weight: 500;
                    font-family: PingFangSC-Medium;
                    line-height: 24px;
                    padding-bottom: 2px;
                    color: var(--pc-normal-color);
                }
            }
        }

        .activityMore {
            font-size: var(--pc-font-size-14);
            color: var(--pc-base-f1-color);
            cursor: pointer;

            &:hover {
                color: var(--pc-primary-color);
            }
        }
    }

    .activityList {
        li {
            width: 100%;
            height: 170px;

            &:hover {
                box-shadow: 0 0 16px 0 rgba(0, 0, 0, 0.15);

                // .itemImg {
                //   transform: scale(1.05);
                // }
            }

            a {
                display: block;
                width: 100%;
                height: 100%;
                margin-top: 24px;
                border-radius: 4px;
                overflow: hidden;
                position: relative;
                cursor: pointer;
                background: var(--pc-normal-hover-color);
            }

            .itemImg {
                transition: all 0.1s;
            }

            .itemTitle {
                position: absolute;
                bottom: 0;
                left: 0;
                width: 100%;
                height: 50px;
                padding: 0 24px;
                line-height: 63px;
                font-size: var(--pc-font-size-16);
                color: var(--pc-base-background);
                letter-spacing: 0.5px;
                white-space: pre;
                overflow: hidden;
                text-overflow: ellipsis;
                font-weight: 500;
                font-family: PingFangSC-Medium;
                box-sizing: border-box;
                background-image: linear-gradient(to bottom, rgba(38, 47, 45, 0), #262f2d);
            }
        }
    }
}
