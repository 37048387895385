.menuDrawer {
    position: fixed;
    top: -100vh;
    left: 0;
    z-index: 1000;
    box-sizing: border-box;
    width: 100vw;
    overflow: hidden auto;
    background-color: var(--pc-base-background);
    // height: 100vh;
    opacity: 0;
    transition: all 0.2s;

    &::-webkit-scrollbar {
        width: 4px;
        height: 4px;
    }

    &::-webkit-scrollbar-thumb {
        background: var(--pc-normal-hover-color);
        border-radius: 2px;
    }

    &::-webkit-scrollbar-track {
        background: transparent;
    }
}

.menuOpen {
    opacity: 1;
    // top: 0;
}

.menuList {
    box-sizing: border-box;
    width: 100%;
    padding: 8px 0;

    li {
        box-sizing: border-box;
        width: 100%;
        padding: 0 32px;
        // border-bottom: 1px solid var(--pc-line-background);

        span {
            display: block;
            width: 100%;
            height: 42px;
            overflow: hidden;
            color: #262626;
            font-size: var(--pc-font-size-14);
            line-height: 42px;
            white-space: nowrap;
            text-overflow: ellipsis;
        }

        &.active {
            background-color: #f0f0f0;
        }
    }
}
