@import '~@/styles/utils.less';
@import url('~@/styles/editor.less');

.li_top {
    width: 100%;
    display: flex;
    align-items: center;
    cursor: pointer;
}

.li_top_margin {
    margin-bottom: 12px;
}

.li .logo {
    position: relative;
    width: 154px;
    height: 102px;
    margin-right: 16px;
    border: solid 1px #e5e5e5;
    overflow: hidden;
    border-radius: 4px;
    box-sizing: border-box;
    flex-shrink: 0;
}

.li .logo img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.li .info {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: calc(100% - 154px - 16px);
    height: 102px;
}

.li .info .info_top {
    width: 100%;
    margin: 0;
}

.li .info .info_top p {
    margin: 0;
}

.li .info .info_top .name_box {
    display: flex;
    margin-bottom: 4px;
    position: relative;
}

.li .info .info_top .name_box .name {
    flex: 1;
    height: 26px;
    color: #262626;
    font-weight: 500;
    font-family: PingFangSC-Medium;
    font-size: 18px;
    line-height: 1.44;
    .maxLine(1);
    word-break: break-all;

    &:hover {
        color: var(--theme-color);
    }
}

.li .info .info_top .name_box .open_account {
    min-width: 80px;
    height: 32px;
    padding: 0 16px;
    margin-left: 24px;
    color: #fff;
    border: none;
    background-color: var(--theme-color);

    &:hover,
    &:focus {
        color: #fff;
    }

    &:hover {
        background-color: var(--btn-hover-color);
    }

    &:focus {
        background-color: var(--btn-click-color);
    }
}

// 对比按钮
.li .info .info_top .name_box .compared {
    height: 32px;
    padding: 0 16px;
    margin-left: 12px;

    color: #2c36de;
    border: solid 1px #2c36de;
    background-color: var(--invalid-name);

    &:hover {
        color: #fff;
    }

    &:hover {
        background-color: var(--btn-hover-color);
    }

    &:active {
        color: #2c36de;
        border: solid 1px #2c36de;
        background-color: var(--invalid-name);
    }
}

.li .info .info_top .score {
    display: flex;
    align-items: center;

    span:first-child {
        position: relative;
        display: inline-block;
        margin-right: 8px;
        padding: 0 1px;
        color: #262626;
        font-weight: 500;
        font-family: PingFangSC-Medium;
        font-size: 22px;
        line-height: 1;

        strong {
            position: relative;
            z-index: 1;
            font-family: D-DIN, sans-serif;
        }

        &::after {
            position: absolute;
            bottom: 0;
            left: 0;
            width: 100%;
            height: 6px;
            background-color: #ffa216;
            border-radius: 3px;
            content: '';
        }
    }

    span:last-child {
        color: #999;
        font-size: 14px;
        line-height: 1.57;
    }
}

.li .tag_list {
    overflow: hidden;
    white-space: pre;
    text-overflow: ellipsis;

    span {
        display: inline-block;
        height: 26px;
        margin-right: 8px;
        padding: 0 10px;
        color: #666;
        line-height: 26px;
        background-color: #f5f5f5;
        border-radius: 4px;
    }

    .license {
        color: #999;
        display: inline;
        background-color: transparent;

        a {
            color: #999;
        }
    }
}

.slogan {
    .maxLine(1);
    font-size: 16px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.5;
    letter-spacing: 0.5px;
    text-align: left;
    color: #666;
    margin-top: 12px;
}

.wrapper_main {
    overflow: hidden;
    color: #000;
    line-height: 1.57;
    // background-color: #f7f7f8;
    border-radius: 6px;
    position: relative;
    box-sizing: border-box;
    transition: height 0.3s;
    margin-top: 12px;
    .globalEditorContent();

    .text_mask {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-image: linear-gradient(to bottom, rgba(255, 255, 255, 0), #fff);
    }
}

.main_con {
    word-break: break-word;
}

.wrapper_more {
    display: flex;
    align-items: flex-end;
    justify-content: center;
    width: 100%;

    .more {
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;

        .text_more {
            margin-right: 4px;
            color: #999;
            font-size: 14px;
            line-height: 1.57;
        }

        .arrow_more {
            font-size: 14px;
            color: #999;
            transform-origin: 50% 50%;
            transform: rotateZ(90deg);
        }

        .arrow_more_t {
            transform: rotateZ(-90deg);
        }
    }

    &:hover {
        .text_more,
        .arrow_more {
            color: var(--theme-color);
        }
    }
}
