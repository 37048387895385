.fontMini {
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
}

.textWrapMini {
    display: -webkit-box;
    overflow: hidden;
    white-space: pre-wrap;
    text-overflow: ellipsis;
    text-overflow: -o-ellipsis-lastline;
    word-break: normal;
    -webkit-box-orient: vertical;
}

.ugcWrapper {
    display: flex;
    justify-content: space-between;
    box-sizing: border-box;
    height: 168px;
    margin-bottom: 24px;
    padding: 24px;
    background-color: var(--pc-base-background);
    border-radius: 4px;

    &:hover {
        .ugcContent {
            .ugcMain {
                .ugcTitle {
                    color: var(--pc-primary-color);
                }
            }
        }
    }

    .ugcImg {
        position: relative;
        width: 120px;
        height: 120px;
        margin-right: 16px;
        overflow: hidden;
        border-radius: 50%;
    }

    .ugcContent {
        flex: 1;
        color: #262f2d;

        .ugcMain {
            flex: 1;

            .ugcTop {
                display: flex;
                align-items: flex-start;
                justify-content: space-between;
            }

            .ugcTitle {
                .fontMini();
                .textWrapMini();

                margin-top: 12px;
                color: var(--pc-normal-color);
                font-weight: 500;
                font-family: PingFangSC-Medium;
                font-size: 18px;
                -webkit-line-clamp: 1;
                line-clamp: 1;

                span {
                    color: var(--pc-base-f7-color);
                }
            }

            .ugcPublishNum {
                .fontMini();
                .textWrapMini();

                margin-top: 8px;
                color: var(--pc-base-f1-color);
                color: #262f2d;
                font-size: 14px;
                letter-spacing: 0.5px;
            }

            .ugcBtn {
                position: relative;
                top: 50px;
                cursor: pointer;

                &:hover {
                    span {
                        color: #008c74;
                        background-color: rgba(5, 232, 193, 0.48);
                        border-color: #008c74;
                    }
                }

                span {
                    .fontMini();

                    display: flex;
                    align-items: center;
                    justify-content: center;
                    height: 32px;
                    padding: 0 24px;
                    color: #00ac8f;
                    font-size: 14px;
                    letter-spacing: 1px;
                    white-space: nowrap;
                    border: solid 1px #00e0b9;
                    border-radius: 17px;

                    &:last-child {
                        display: none;
                    }
                }

                &.isFocus {
                    &:hover {
                        span {
                            &:first-child {
                                display: none;
                            }

                            &:last-child {
                                display: flex;
                            }
                        }
                    }
                }
            }
        }

        .ugcIntroduce {
            .fontMini();
            .textWrapMini();

            width: calc(100% - 120px);
            margin-top: 16px;
            font-size: 16px;
            line-height: var(--pc-line-height-a);
            letter-spacing: 0.5px;
            -webkit-line-clamp: 2;
            line-clamp: 2;
        }
    }
}

@media screen and (max-width: 768px) {
    .ugcWrapper {
        box-sizing: border-box;
        height: auto;
        margin-bottom: 0;
        padding: 16px 0 24px;
        border-radius: 0;

        &.ugcWrapperFirst {
            padding-top: 8px;
        }

        .ugcImg {
            width: 80px;
            height: 80px;
            margin-right: 8px;
        }

        .ugcContent {
            width: calc(100% - 88px);

            .ugcMain {
                .ugcTit {
                    width: calc(100% - 120px);
                }

                .ugcTitle {
                    .fontMini();
                    .textWrapMini();

                    margin-top: 0;
                    font-size: 16px;
                }

                .ugcPublishNum {
                    overflow: hidden;
                    font-size: 12px;
                    white-space: pre;
                    text-overflow: ellipsis;
                }

                .ugcBtn {
                    top: 0;
                    cursor: pointer;
                }
            }

            .ugcIntroduce {
                width: 100%;
                margin-top: 8px;
                font-size: 14px;
            }
        }
    }
}
