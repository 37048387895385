@import '~@/styles/utils.less';

.swiperContain {
    // width: 448px !important;
    overflow: hidden;
    height: 298px;
    -webkit-user-select: none;
    user-select: none;
    position: relative;
    box-sizing: border-box;

    :global {
        .swiper-pagination {
            height: 2px;
            bottom: 20px;
            display: flex;
            justify-content: center;
        }

        .swiper-button-disabled {
            opacity: 1;
            cursor: pointer;
            pointer-events: auto;
        }
    }

    & > div {
        height: 100%;
    }

    .swiperCon {
        border-radius: 4px;
        overflow: hidden;
    }

    .swiperSlideBox {
        width: 100%;
        height: 100%;
        position: relative;
    }

    .swiperSlide {
        box-sizing: border-box;
        height: 100%;
    }

    .swiperBullet {
        width: 28px;
        height: 2px;
        border-radius: 1px;
        background: rgba(255, 255, 255, 0.5);
        display: inline-block;
        margin-right: 4px;

        &:last-child {
            margin-right: 0;
        }
    }

    .swiperBulletActive {
        position: relative;
        overflow: hidden;

        &::after {
            content: '';
            width: 0;
            height: 100%;
            position: absolute;
            top: 0;
            left: 0;
            background-color: #fff;
            border-radius: 1px;
            animation: progressMove linear 5s 1 forwards;
        }
    }

    .swiperSlideTitle {
        position: absolute;
        bottom: 0;
        left: 0;
        z-index: 1;
        box-sizing: border-box;
        width: 100%;
        height: 50%;
        color: var(--pc-base-background);
        font-weight: 500;
        font-family: PingFangSC-Medium;
        font-size: 18px;
        background-image: linear-gradient(to bottom, rgba(0, 0, 0, 0) 0%, #000);
        cursor: pointer;

        .swiperSlideTitleText {
            width: 100%;
            position: absolute;
            bottom: 0;
            left: 0;
            box-sizing: border-box;
            margin-bottom: 32px;
            font-weight: 500;
            font-family: PingFangSC-Medium;
            line-height: 1.5;
            text-align: justify;
            padding: 0 24px;
            .maxLine(2);
        }

        .swiperSlideTitleOne {
            margin-bottom: 24px;
        }
    }

    .swiperBtnNext,
    .swiperBtnPrev {
        width: 36px;
        height: 50px;
        top: 50%;
        opacity: 0;
        box-shadow: 0 0 8px 0 rgba(0, 0, 0, 0.2);
        background-color: rgba(0, 0, 0, 0.45);

        &::after {
            font-size: 20px;
            color: var(--pc-base-background);
        }
    }

    .swiperBtnNext {
        right: 0;
    }

    .swiperBtnPrev {
        left: 0;
    }

    &:hover {
        .swiperBtnNext,
        .swiperBtnPrev {
            opacity: 1;
        }
    }

    .adTag {
        height: 24px;
        padding: 0 6px;
        position: absolute;
        top: 4px;
        right: 4px;
        border-radius: 4px;
        background-color: #000000;
        display: flex;
        align-items: center;
        z-index: 2;
        // line-height: 1;

        &.closePad {
            padding: 0 4px;
            cursor: pointer;
        }

        span {
            font-size: 14px;
            letter-spacing: 0.5px;
            color: var(--pc-base-background);
        }

        .closeIcon {
            height: 19px;
            font-size: 19px;
            padding: 0 0.5px;
            overflow: hidden;
            color: var(--pc-base-background);

            &::before {
                height: 19px;
                display: flex;
                align-items: center;
                padding: 0;
            }
        }
    }
}

@keyframes progressMove {
    0% {
        width: 0;
    }

    100% {
        width: 100%;
    }
}
