.videoList {
    .videoItem {
        margin-bottom: 24px;
        background-color: #fff;
        border-radius: 4px;
        box-shadow: 0 0 16px 0 rgba(0, 0, 0, 0.05);
        transition: all 0.2s;

        &:hover {
            transform: translateY(-4px);
        }

        .videoCover {
            position: relative;
            z-index: 1;
            display: block;
            width: 100%;
            height: 164px;
            overflow: hidden;
            background-color: var(--pc-line-background);
            border-bottom: 1px solid var(--pc-border-color);
            border-radius: 4px 4px 0 0;
            cursor: pointer;

            & > div {
                position: relative;
                z-index: 1;
                box-sizing: border-box;
                width: 100%;
                height: 100%;
                overflow: hidden;
                background-repeat: no-repeat;
                background-position: 50%;
                background-size: cover;
                border-radius: 4px;
            }

            .videoPlay {
                position: absolute;
                top: 0;
                left: 0;
                z-index: 1;
                display: none;
                width: 100%;
                height: 100%;
                background-color: rgba(0, 0, 0, 0.4);

                &::before {
                    position: absolute;
                    top: 50%;
                    left: 50%;
                    z-index: 2;
                    display: block;
                    color: var(--pc-base-background);
                    font-size: 32px;
                    transform: translate(-50%, -50%);
                }
            }

            &:hover {
                .videoPlay {
                    display: block;
                }
            }
        }

        .videoContain {
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            box-sizing: border-box;
            height: 160px;
            padding: 8px 16px 24px;
        }

        .videoTitle {
            display: -webkit-box;
            overflow: hidden;
            color: var(--pc-normal-color);
            font-weight: 500;
            font-family: PingFangSC-Medium;
            font-size: var(--pc-font-size-18);
            line-height: var(--pc-line-height-a);
            letter-spacing: 0.5px;
            white-space: pre-wrap;
            text-overflow: ellipsis;
            word-break: normal;
            -webkit-line-clamp: 2;
            -webkit-box-orient: vertical;

            &:hover {
                color: var(--pc-primary-color);
            }

            label {
                cursor: pointer;
            }

            h2 {
                display: inline;
                font-size: inherit;
            }

            .top,
            .origin {
                position: relative;
                top: -2px;
                display: inline-block;
                box-sizing: border-box;
                min-width: 48px;
                height: 24px;
                margin-right: 4px;
                margin-bottom: 2px;
                padding: 0 4px;
                font-weight: normal;
                font-size: var(--pc-font-size-14);
                line-height: 24px;
                text-align: center;
                border-radius: 2px;
            }

            .top {
                color: var(--pc-base-f7-color);
                border: 1px solid var(--pc-base-f7-color);
            }

            .margin {
                margin-right: 8px;
            }

            .origin {
                margin-right: 8px;
                color: var(--pc-primary-color);
                border: 1px solid var(--pc-primary-color);
            }
        }

        .videoMsg {
            display: flex;
            flex-wrap: wrap;
            align-items: center;
            justify-content: space-between;
            color: var(--pc-visited-color);
            font-size: var(--pc-font-size-14);

            .msga {
                margin-top: 8px;
                margin-right: 16px;
                color: var(--pc-normal-color);
                font-weight: 500;
                font-family: PingFangSC-Medium;
            }

            .icon {
                margin-right: 4px;
                color: var(--pc-base-f1-color);
                line-height: normal;
            }

            & > div {
                display: flex;
                flex: 1;
                align-items: flex-end;
                justify-content: space-between;
                min-width: 120px;
                margin-top: 8px;
            }

            .msgc {
                display: flex;
                align-items: center;
                letter-spacing: 1px;
            }
        }
    }
}

@media screen and (max-width: 415px) {
    .videoList {
        padding: 0 24px;
        background-color: var(--pc-base-background);

        :global {
            .ant-col {
                flex: 0 0 100%;
                max-width: 100%;
            }
        }

        .videoItem {
            position: relative;
            width: 100%;
            margin-bottom: 0;
            padding: 16px 0;
            border-radius: 0;
            box-shadow: none;
            transition: none;

            a {
                display: flex;
                align-items: center;
                justify-content: space-between;

                &:visited {
                    .videoTitle {
                        color: var(--pc-visited-color);
                    }
                }

                &:hover {
                    .videoTitle {
                        color: var(--pc-normal-color);
                    }
                }
            }

            &:hover {
                transform: translateY(0);
            }

            &::after {
                position: absolute;
                bottom: 0;
                left: 0;
                width: 100%;
                height: 1px;
                background-color: var(--pc-line-background);
                content: '';
            }

            .videoCover {
                position: relative;
                z-index: 1;
                display: block;
                box-sizing: border-box;
                width: 106px;
                height: 68px;
                margin-right: 8px;
                overflow: hidden;
                border: none;
                border-bottom: none;
                border-radius: 5px;
                cursor: pointer;

                &::after {
                    display: none;
                }

                .videoPlay {
                    &::before {
                        font-size: 24px;
                    }
                }
            }

            .videoContain {
                display: flex;
                flex: 1;
                flex-direction: column;
                justify-content: space-between;
                box-sizing: border-box;
                height: auto;
                min-height: 68px;
                padding: 0;
            }

            .videoTitle {
                font-weight: 500;
                font-family: PingFangSC-Medium;
                font-size: var(--pc-font-size-16);
                line-height: var(--pc-line-height-a);
                letter-spacing: 0.5px;

                label {
                    cursor: pointer;
                }

                .top,
                .origin {
                    width: 40px;
                    height: 18px;
                    font-size: var(--pc-font-size-12);
                    line-height: 18px;
                }

                .margin {
                    margin-right: 4px;
                }

                .origin {
                    margin-right: 4px;
                }
            }

            .videoMsg {
                font-size: var(--pc-font-size-10);

                .icon {
                    margin-right: 2px;
                    font-size: var(--pc-font-size-12);
                }

                & > div {
                    position: relative;
                    top: 1px;
                    flex: 1;
                }
            }
        }
    }
}
