// ------------- 超出省略 Start ------------- //
.maxLine(@x) {
    display: -webkit-box; //特别显示模式
    overflow: hidden; //溢出内容隐藏
    white-space: pre-wrap;
    text-overflow: -o-ellipsis-lastline;
    text-overflow: ellipsis; //文本溢出部分用省略号表示
    word-wrap: break-word;
    word-break: normal;
    overflow-wrap: anywhere;
    -webkit-line-clamp: @x; //行数
    line-clamp: @x;
    -webkit-box-orient: vertical; //盒子中内容竖直排列
}
// ------------- 超出省略 End ------------- //
// ------------- 列表项宽度 Start ------------- //
.listItemWidht(@x, @padding) {
    width: calc(100% / @x - @padding * (@x - 1));
}
// ------------- 列表项宽度 End ------------- //
.wrapper {
    flex: 1;
    padding: 0 0 16px 16px;
    background: #fff;
    border-radius: 0 0 6px 6px;
}
.wrapper__list {
    display: flex;
    gap: 16px 16px;
    flex-wrap: wrap;
}

.wrapper__article {
    width: calc(100% / 4 - 16px);
    height: 340px;
    display: block;
    flex-shrink: 0;
}
.wrapper__article.wrapper__noNewsFlash {
    width: calc(100% / 3 - 16px);
    .wrapper__cover {
        height: 213px;
    }
}
.wrapper__article .wrapper__cover .thumbnail {
    transition: all 0.5s;
}
.wrapper__article:hover {
    .wrapper__title {
        color: #2c36de;
    }
    .wrapper__cover .thumbnail {
        transform: scale(1.1);
    }
}
// ------------- 封面 Start ------------- //
.wrapper__cover {
    position: relative;
    width: 100%;
    height: 216px;
    overflow: hidden;
    border-radius: 6px;
    &:hover {
        .video_play_btn {
            opacity: 1;
        }
    }
}
.audio__btn {
    position: absolute;
    top: 12px;
    right: 12px;
    z-index: 1;
    width: 20px;
    height: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 20px;
    background-color: rgba(0, 0, 0, 0.5);
}
.video_play_btn {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    margin: auto;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgba(0, 0, 0, 0.3);
    opacity: 0;
    z-index: 10;
    transition: all 0.3s;
}
// ------------- 封面 End ------------- //
// ------------- 标题 Start ------------- //
.wrapper__title {
    max-height: 78px;
    padding-top: 16px;
    color: #262626;
    font-size: 18px;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.44;
    letter-spacing: normal;
    .maxLine(3);

    .top {
        display: inline-block;
        width: 19px;
        height: 19px;
        position: relative;
        top: 2px;
        margin-right: 8px;
    }

    h2 {
        display: inline;
        font-size: inherit;
        white-space: normal;
        font-weight: 500;
        font-family: PingFangSC-Medium;
    }
}
// ------------- 标题 End ------------- //
.wrapper__info {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    margin-top: 8px;
}
// ------------- 创作者 Start ------------- //
.wrapper__ugc {
    color: #262626;
    font-weight: normal;
    font-size: 14px;
    font-style: normal;
    font-stretch: normal;
    line-height: 22px;
    letter-spacing: normal;
    .maxLine(1);
}
// ------------- 创作者 End ------------- //
.wrapper__line {
    width: 1px;
    height: 12px;
    margin: 0 12px;
    background-color: #e5e5e5;
}

// ------------- 日期 Start ------------- //
.wrapper__timer {
    flex-shrink: 0;
    color: #999;
    font-weight: normal;
    font-size: 14px;
    font-style: normal;
    font-stretch: normal;
    line-height: 22px;
    letter-spacing: normal;
}
// ------------- 日期 End ------------- //
// ------------- 没有更多 Start ------------- //
.wrapper__noMore {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    margin-top: 50px;
    em {
        width: 213px;
        height: 1px;
        background-color: #e5e5e5;
    }
    span {
        padding: 0 12px;
        color: #999;
        font-weight: normal;
        font-size: 14px;
        font-style: normal;
        font-stretch: normal;
        line-height: 22px;
        letter-spacing: 0.07px;
    }
}
// ------------- 没有更多 End ------------- //

//  暗黑模式
.barkly_container {
    .wrapper__title {
        color: #fff;
    }
    &.wrapper__article:hover {
        .wrapper__title {
            color: #fff;
            text-decoration: underline;
        }
    }
    .wrapper__ugc {
        color: rgba(255, 255, 255, 0.8);
    }
    .wrapper__line {
        background-color: #e5e5e5;
    }
    .wrapper__timer {
        color: rgba(255, 255, 255, 0.8);
    }
}
