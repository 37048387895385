@-theme-color: #2c36de;
@-btn-hover-color: #5461eb;
@-btn-click-color: #1c1eb8;
@-btn-disabled-color: #adbaff;
@-border-btn-disabled-color: #e5e5e5;
@-border-btn-disabled-text-color: #999999;

//-------- 列表
@-list-hover-background: #f7f7f8;
// ------- 滚动条
@-color-other-scroll: #e7e7e7;
// ------- 文字
@-color-text-primary: #262626;
@-color-text-secondary: #666666;
@-color-text-explain: #999999;

//------- 页面背景色
@-page-primary-background: #f5f5fa;
//------- 背景色
@-color-bg-general: #f7f7f8;

// --------- 边缘线颜色
@-pc-primary-border-color: #ebebeb;
@-pc-primary-border-color2: #e5e5e5;
@-pc-primary-border-1: 1px solid #e5e5e5;

@-pc-primary-color: #05e8c1;
@-pc-normal-color: #262b2e;
@-pc-visited-color: #666666;
@-pc-hover-color: #0153fb;
@-pc-base-f1-color: #999999;
@-pc-base-f2-color: #cccccc;
@-pc-base-f3-color: #f8f8f8;
@-pc-base-f4-color: #ac0606;
@-pc-base-f5-color: #ff5353;
@-pc-base-f6-color: #ff8502;
@-pc-base-f7-color: #fe3434;
@-pc-base-f8-color: #6385ff;
@-pc-base-f9-color: #010b27;
@-pc-base-f10-color: #262626;
@-pc-base-background: #ffffff;
@-pc-border-color: #f5f5f5;
@-pc-line-background: #f7f8fb;
@-pc-normal-hover-color: #ebebeb;
@-pc-active-hover-color: rgba(0, 52, 173, 0.1);

// button
@-pc-button-disables-background: #cccccc;
@-pc-button-background: #05e8c1;
@-pc-button-f-color: #262f2d;
// ----- end

// p
@-pc-p-f-color: #3d3d3d;
// ----- end

@-wrapper-width: 1376px;
@-page-con-width: 1184px;
@-page-con-lf-width: 882px;
@-page-mobile-width: 415px;

:root {
    --pc-primary-color: @-pc-primary-color;
    --pc-normal-color: @-pc-normal-color;
    --pc-visited-color: @-pc-visited-color;
    --pc-hover-color: @-pc-hover-color;
    --pc-base-f1-color: @-pc-base-f1-color;
    --pc-base-f2-color: @-pc-base-f2-color;
    --pc-base-f3-color: @-pc-base-f3-color;
    --pc-base-f4-color: @-pc-base-f4-color;
    --pc-base-f5-color: @-pc-base-f5-color;
    --pc-base-f6-color: @-pc-base-f6-color;
    --pc-base-f7-color: @-pc-base-f7-color;
    --pc-base-f8-color: @-pc-base-f8-color;
    --pc-base-f9-color: @-pc-base-f9-color;
    --pc-base-f10-color: @-pc-base-f10-color;
    --pc-base-background: @-pc-base-background;
    // button
    --pc-button-f-color: @-pc-button-f-color;
    --pc-button-background: @-pc-button-background;
    --pc-button-disables-background: @-pc-button-disables-background;
    // ----- end

    // p
    --pc-p-f-color: @-pc-p-f-color;
    // ----- end

    --pc-border-color: @-pc-border-color;
    --pc-line-background: @-pc-line-background;
    --pc-normal-hover-color: @-pc-normal-hover-color;
    --pc-active-hover-color: @-pc-active-hover-color;
    --pc-font-size-28: 28px;
    --pc-font-size-24: 24px;
    --pc-font-size-22: 22px;
    --pc-font-size-20: 20px;
    --pc-font-size-18: 18px;
    --pc-font-size-16: 16px;
    --pc-font-size-14: 14px;
    --pc-font-size-12: 12px;
    --pc-font-size-10: 10px;
    --pc-line-height-a: 150%;
    --pc-line-height-b: 180%;
    --wrapper-width: @-wrapper-width;
    --pc-page-con-width: @-page-con-width;
    --page-con-lf-width: @-page-con-lf-width;
    --page-mobile-width: @-page-mobile-width;

    --page-primary-background: @-page-primary-background;

    --pc-primary-border-color: @-pc-primary-border-color;
    --pc-primary-border-color2: @-pc-primary-border-color2;
    --pc-primary-border-1: @-pc-primary-border-1;

    // ---------------------------- 新官网 Start ----------------------------
    --theme-color: @-theme-color; // 默认
    --invalid-name: @-pc-base-background;
    //-------- 列表
    --list-hover-background: @-list-hover-background;
    // 点击态
    --btn-hover-color: @-btn-hover-color; // 悬停
    --btn-click-color: @-btn-click-color; // 点击
    --btn-disabled-color: @-btn-disabled-color; // 禁用
    --border-btn-disabled-color: @-border-btn-disabled-color; // 边框线按钮的禁用边框色
    --border-btn-disabled-text-color: @-border-btn-disabled-text-color; // 边框线按钮的禁用文字色

    // 滚动条
    --color-other-scroll: @-color-other-scroll; //滚动条
    // ---------- 文字 Start ----------
    --color-text-primary: @-color-text-primary; // 文字默认色
    --color-text-secondary: @-color-text-secondary; // 文字次级色
    --color-text-explain: @-color-text-explain;
    // ---------- 文字 End ----------
    // ---------- 背景色 Start ----------
    --color-bg-general: @-color-bg-general; // 通用背景色
    // ---------- 背景色 End ----------
    // ---------------------------- 新官网 End ----------------------------
}
