.tabsCon {
    min-height: 300px;
}

@media screen and (max-width: 628px) {
    .tabsConBox,
    .tabsCon {
        padding-top: 0 !important;
    }
}
