.edit_modal {
    border-radius: 6px;
    background-repeat: no-repeat;
    background-position: top center;
    background-size: 100% auto;
    background-color: #fff;

    :global {
        .ant-modal-content {
            padding: 172px 24px 60px !important;
            border-radius: 6px;
            background-color: transparent !important;
        }

        .ant-modal-close {
            top: 18px;
            font-size: 20px;

            &:hover {
                background: transparent !important;
                background-color: transparent !important;
            }
        }
    }
}

.close {
    font-size: 20px;
    color: #000;
    line-height: 1;
}

.wrapper {
    background-color: rgba(255, 255, 255, 0.9);
}
