.textWrapMini {
    display: -webkit-box;
    overflow: hidden;
    white-space: pre-wrap;
    text-overflow: ellipsis;
    -webkit-box-orient: vertical;
}

.searchWrapper {
    display: flex;
    box-sizing: border-box;
    margin-bottom: 24px;
    padding: 24px;
    background: #fff;
    border-radius: 4px;
    box-shadow: 0 0 16px 0 rgba(0, 0, 0, 0.05);

    &:hover {
        .searchTitle {
            color: var(--pc-primary-color);

            span {
                color: var(--pc-base-f7-color);
            }
        }
    }

    .searchImage {
        position: relative;
        z-index: 1;
        display: flex;
        flex-shrink: 0;
        align-items: center;
        width: 238px;
        height: 140px;
        margin-right: 16px;
        overflow: hidden;
        border-radius: 4px;

        & img {
            display: block;
            width: 238px;
            height: 140px;
            object-fit: cover;
        }
    }

    .searchVideoImg {
        &::after {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            background-color: rgba(0, 0, 0, 0.4);
            opacity: 0;
            content: '';
        }

        .videoPlay {
            position: absolute;
            top: 50%;
            left: 50%;
            z-index: 2;
            display: block;
            color: var(--pc-base-background);
            font-size: 32px;
            transform: translate(-50%, -50%);
            opacity: 0;
        }

        &:hover {
            .videoPlay {
                opacity: 1;
            }

            &::after {
                opacity: 1;
            }
        }
    }

    .searchMain {
        display: flex;
        flex: 1;
        flex-direction: column;
        overflow: hidden;
    }

    .linkBox {
        display: flex;
        flex: 1;
        flex-direction: column;
    }

    .titleWrapper {
        display: flex;
        margin-bottom: 8px;

        .origin {
            display: inline-block;
            flex-shrink: 0;
            box-sizing: border-box;
            min-width: 48px;
            height: 24px;
            margin-right: 8px;
            padding: 0 4px;
            color: var(--pc-primary-color);
            font-size: var(--pc-font-size-14);
            line-height: 24px;
            text-align: center;
            border: 1px solid var(--pc-primary-color);
            border-radius: 2px;
        }
    }

    .searchTitle {
        .textWrapMini();

        color: var(--pc-normal-color);
        font-weight: 500;
        font-family: PingFangSC-Medium;
        font-size: var(--pc-font-size-18);
        line-height: var(--pc-line-height-a);
        white-space: normal;
        -webkit-line-clamp: 1;

        span {
            color: var(--pc-base-f7-color);
        }

        &:hover {
            color: var(--pc-primary-color);
        }
    }

    .searchContent {
        .textWrapMini();

        flex: 1;
        margin-bottom: 32px;
        color: #666;
        font-weight: normal;
        font-size: 16px;
        line-height: 150%;
        line-height: 1.67;
        text-overflow: -o-ellipsis-lastline;
        word-break: normal;
        -webkit-line-clamp: 2; //行数
        line-clamp: 2;
    }

    .searchBottom {
        display: flex;
        justify-content: space-between;
        overflow: hidden;
        font-size: 14px;

        .searchBottomLeft {
            margin-right: 20px;

            .searchRuleName {
                color: #262b2e;
                font-weight: 500;
                font-family: PingFangSC-Medium;
            }

            span {
                margin-right: 16px;
                color: #666;
            }
        }

        .searchBottomRight {
            flex: 1;
            // display: flex;
            // justify-content: flex-end;
            // flex-wrap: wrap;
            overflow: hidden;
            white-space: nowrap;
            text-align: right;
            text-overflow: ellipsis;

            a {
                flex-shrink: 0;
                margin-left: 8px;
                color: #999;

                &:hover {
                    color: var(--pc-primary-color);
                }
            }

            .searchOriginal {
                color: var(--pc-base-f7-color);
            }
        }
    }
}

@media screen and (max-width: 768px) {
    .searchWrapper {
        position: relative;
        display: flex;
        padding: 16px;
        border-radius: 0;
        box-shadow: none;
        box-shadow: 0 0 16px 0 rgba(0, 0, 0, 0.05);

        &::after {
            position: absolute;
            bottom: 0;
            left: 0;
            width: 100%;
            height: 1px;
            background-color: var(--pc-line-background);
            content: '';
        }

        .searchImage {
            position: relative;
            z-index: 1;
            display: block;
            box-sizing: border-box;
            width: 106px;
            height: 68px;
            margin-right: 8px;
            overflow: hidden;
            border: 1px solid var(--pc-line-background);
            border-bottom: none;
            border-radius: 5px;
            cursor: pointer;
        }

        .searchVideoImg {
            &::after {
                opacity: 1;
            }

            .videoPlay {
                font-size: 24px;
                opacity: 1;
            }
        }

        .searchMain {
            justify-content: space-between;
        }

        .titleWrapper {
            margin-bottom: 0;
        }

        .searchTitle {
            font-weight: 500;
            font-family: PingFangSC-Medium;
            font-size: var(--pc-font-size-16);
            letter-spacing: 0.5px;
            white-space: normal;
        }

        .searchContent {
            margin-bottom: 0;
            font-size: var(--pc-font-size-14);
            -webkit-line-clamp: 1; //行数
            line-clamp: 1;
        }

        .searchBottom {
            flex-wrap: wrap;
            font-size: 10px;

            & > div {
                min-width: 100%;
            }

            .searchBottomLeft {
                display: flex;
                align-items: flex-end;
                margin-right: 0;

                .searchRuleName {
                    color: #262b2e;
                    font-weight: 500;
                    font-family: PingFangSC-Medium;
                }

                span {
                    margin-right: 16px;
                    color: #666;
                    white-space: nowrap;

                    &:last-child {
                        display: flex;
                        flex: 1;
                        align-items: center;
                        justify-content: flex-end;
                        margin-right: 0;
                    }

                    &:first-child {
                        display: -webkit-box; //特别显示模式
                        overflow: hidden; //溢出内容隐藏
                        white-space: normal;
                        text-overflow: -o-ellipsis-lastline;
                        text-overflow: ellipsis; //文本溢出部分用省略号表示
                        word-break: normal;
                        -webkit-line-clamp: 1; //行数
                        line-clamp: 1;
                        -webkit-box-orient: vertical; //盒子中内容竖直排列
                    }
                }
            }

            .searchBottomRight {
                // margin-top: 4px;
                // margin-right: 0;
                display: none;
            }
        }
    }
}
