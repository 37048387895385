// 文字限制一行
.overflow__hiding__one {
    display: -webkit-box; //特别显示模式
    overflow: hidden; //溢出内容隐藏
    text-overflow: -o-ellipsis-lastline;
    text-overflow: ellipsis; //文本溢出部分用省略号表示
    word-break: normal;
    -webkit-line-clamp: 1; //行数
    line-clamp: 1;
    -webkit-box-orient: vertical; //盒子中内容竖直排列
}

// 背景图默认样式
.bg__default {
    background-repeat: no-repeat;
    background-position: center center;
    background-size: cover;
}

.information__wrapper {
    width: 100%;
    padding-bottom: 24px;

    .information__hander {
        display: flex;
        justify-content: space-between;
        color: #999;
        font-size: 16px;
        line-height: 1.5;
        letter-spacing: 0.5px;

        .information__header__text {
            width: calc(100% - 174px);
            color: #666;
            .overflow__hiding__one();
        }

        .information__header__switch {
            display: flex;
            flex-shrink: 0;
            align-items: center;
            justify-content: right;
            cursor: pointer;
        }

        .switch__show__switch {
            width: 20px;
            height: 20px;
            margin-left: 4px;
            background-position: center center;
            .bg__default();

            &.show {
                background-position: center -3px;
            }
        }
    }

    .information__list__item {
        display: flex;
        margin-top: 16px;
        padding: 16px;
        background-color: #fbfbfb;

        &:hover {
            .information__content > p {
                color: var(--pc-primary-color);
            }
        }

        .information__img {
            flex-shrink: 0;
            width: 100px;
            height: 60px;
            margin-right: 16px;
            .bg__default();
        }

        .information__content {
            display: flex;
            align-items: center;

            p {
                color: #666;
                font-size: 16px;
                line-height: 1.5;
                letter-spacing: 0.5px;
                -webkit-line-clamp: 2; //行数
                line-clamp: 2;
                .overflow__hiding__one();
            }
        }
    }
}

@media screen and (max-width: 768px) {
    .information__wrapper {
        padding-bottom: 16px;

        .information__hander {
            font-size: 12px;

            .information__header__text {
                width: calc(100% - 88px);
            }

            .switch__show__switch {
                width: 14px;
                height: 14px;
            }
        }

        .information__list__item {
            margin-top: 8px;

            .information__img {
                width: 86.2px;
                height: 50px;
                margin-right: 8px;
            }

            .information__content {
                p {
                    font-size: 14px;
                }
            }
        }
    }
}
