@import '~@/styles/utils.less';
.before {
    color: #2c36de !important;
    border-color: #2c36de !important;
}

.progressing {
    color: #2c36de !important;
    border-color: #2c36de !important;
}

.end {
    color: #333 !important;
    border-color: #e5e5e5 !important;
}

.listItem {
    display: flex;
    box-sizing: border-box;
    padding: 12px 0;
    overflow: hidden;
    background: #fff;
    cursor: pointer;
    transition: all 0.2s;
    user-select: none;
    border-bottom: 1px solid #e5e5e5;
}

.listItemCover {
    position: relative;
    width: 116px;
    height: 77px;
    border-radius: 4px;
    overflow: hidden;
}

.listItemContent {
    display: flex;
    flex: 1;
    flex-direction: column;
    padding-left: 8px;
}

.title {
    display: -webkit-box; //特别显示模式
    box-sizing: border-box;
    overflow: hidden; //溢出内容隐藏
    color: #262626;
    font-size: 14px;
    line-height: 1.5;
    text-overflow: -o-ellipsis-lastline;
    text-overflow: ellipsis; //文本溢出部分用省略号表示
    word-break: normal;
    -webkit-line-clamp: 1; //行数
    line-clamp: 1;
    -webkit-box-orient: vertical; //盒子中内容竖直排列
}

.time_con {
    margin-top: 8px;
    .time {
        color: #999;
        font-size: 12px;
        line-height: 18px;
        display: flex;
        gap: 4px;
        align-items: center;
        .iconfont {
            display: block;
            font-size: 12px;
        }
    }
}

.btns {
    margin-top: 4px;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.countdown {
    flex-shrink: 0;
    padding: 3px 9px;
    color: #333;
    font-size: 12px;
    line-height: 18px;
    box-sizing: border-box;
    text-align: center;
    background-color: #fff;
    border: 1px solid #e9e9e9;
    border-radius: 4px;
    &.end {
        background-color: #f5f5f5 !important ;
    }
}

.status {
    position: relative;
    display: inline-block;
    box-sizing: border-box;
    padding: 6px 10px;
    color: #0ec370;
    font-weight: 500;
    font-family: PingFangSC-Medium;
    font-size: 14px;
    line-height: 14px;
    text-align: right;
    border: solid 1px;
    border-radius: 14px;

    i {
        font-size: 16px;
    }
}

.city {
    display: flex;
    align-items: center;
    color: #999;
    gap: 6px;
    font-size: 12px;

    i {
        width: 12px;
        height: 12px;
        margin-right: 3px;
        color: #999999;
        font-size: 14px;
        line-height: 1;
    }

    span {
        .maxLine(1);
    }
}
