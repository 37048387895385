.tabs {
    display: flex;
    align-items: center;
    width: 100%;
    overflow: hidden;
    // white-space: nowrap;

    :global {
        .swiper-button-disabled {
            display: none;
        }
    }
    &.has_underline {
        position: relative;
        &::before {
            content: '';
            position: absolute;
            bottom: 0;
            width: 100%;
            height: 1px;
        }
    }
}

&.isFixed {
    background: var(--pc-line-background);
}

.tabsItem {
    display: inline-block;
    width: auto;
    cursor: pointer;

    &::after {
        content: '';
    }

    &:first-child {
        padding-left: 0 !important;
    }

    &:last-child {
        padding-right: 0;

        &::after {
            display: none;
        }
    }
}

.swiperBackPrev,
.swiperBackNext {
    position: absolute;
    top: 0;
    z-index: 1;
    width: 36px;
    height: 36px;
    background-color: var(--pc-base-background);
    border-radius: 18px;
    box-shadow: 0 0 8px 0 rgba(0, 0, 0, 0.1);
}

.swiperBackPrev {
    left: 2px;
}

.swiperBackNext {
    right: 2px;
}

.swiperBtnPrev,
.swiperBtnNext {
    position: absolute;
    top: 60%;
    z-index: 9;
    width: 48px;
    height: 36px;
    background-color: var(--pc-line-background);

    &::after {
        z-index: 2;
        color: var(--pc-normal-color);
        font-size: 14px;
    }
}

.swiperBtnPrev {
    left: 0;

    &::after {
        margin-right: 10px;
    }
}

.swiperBtnNext {
    right: 0;

    &::after {
        margin-left: 10px;
    }
}

.affix {
    :global {
        .ant-affix {
            z-index: 999;
        }
    }
}

@media screen and (max-width: 768px) {
    &.isFixed {
        background: var(--pc-base-background);
    }

    .tabs {
        box-sizing: border-box;
        width: 100%;
        height: 45px;
        padding: 0 24px;

        :global {
            .swiper-button-next,
            .swiper-button-prev {
                display: none;
            }
        }
    }
}
.barkly_container {
    .tabs {
        overflow: visible;
        background-color: #0c0d13 !important;
    }
}
