.menu_box {
    position: fixed;
    top: 0;
    left: 0;
    overflow: hidden;
    z-index: 998;
    box-sizing: border-box;
    width: 208px;
    height: 100vh;
    // padding-bottom: 24px;
    list-style-type: none;
    background-color: #fff;
    // box-shadow: 0 0 15px 1px rgba(0, 0, 0, 0.1);
    transform: translateX(-300px);
    transition: all 0.2s;

    &:hover {
        .recScrollThumbY {
            background: #ebebeb !important;
        }
    }
}

.recScrollTrackY {
    width: 4px !important;
    background: transparent !important;
}

.recScrollThumbY {
    width: 4px !important;
    background: transparent !important;
}

.menu {
    width: 100%;
    height: 100%;
    list-style-type: none;
}

.menu_open {
    transform: translateX(0);
}

.item {
    position: relative;
    box-sizing: border-box;
    width: 208px;
    height: 48px;
    padding: 0 24px;
    font-size: 14px;
    cursor: pointer;

    .aHref {
        display: flex;
        align-items: center;
        justify-content: space-between;
        height: 48px;
        color: var(--pc-normal-color);
        line-height: 0;

        & > div {
            display: flex;
            align-items: center;
            width: 100%;
            height: 100%;

            & > span:first-child {
                display: flex;
                align-items: center;
                justify-content: center;
                width: 20px;
                font-weight: normal;
                font-size: var(--pc-font-size-20);
            }

            & > span:last-child {
                flex: 1;
                height: 100%;
                margin-left: 16px;
                overflow: hidden;
                line-height: 48px;
                white-space: nowrap;
                text-overflow: ellipsis;
            }
        }

        .arrow {
            display: none;
            padding: 0 16px;
        }
    }

    &.active {
        background-color: rgba(5, 232, 193, 0.08);

        .aHref {
            color: var(--pc-primary-color);

            & > div {
                & > span:nth-of-type(2) {
                    font-weight: 500;
                    font-family: PingFangSC-Medium;
                }
            }
        }

        .arrow {
            display: block;
        }
    }

    &:hover {
        background-color: rgba(5, 232, 193, 0.08);

        .aHref {
            color: var(--pc-primary-color);
        }

        .arrow {
            display: block;
        }

        .modelA {
            display: grid;
        }
    }
}
