@import url('~@/styles/utils.less');

.menu_hover {
    width: 100%;
    height: auto;
    position: fixed;
    // z-index: 9;
    overflow: hidden;
    left: 0;
    border-top: 1px solid transparent;
    opacity: 0;

    &.hover_show {
        opacity: 1;
        border-top: 1px solid #f5f5f5;
        box-shadow:
            0 5px 5px -3px rgba(0, 0, 0, 0.1),
            0 8px 10px 1px rgba(0, 0, 0, 0.06);
    }
}

.bg {
    position: absolute;
    width: 50%;
    height: 100%;
    top: 0;
}

.lf_bg {
    left: 0;
    background-color: #fff;
}

.rt_bg {
    right: 0;
    background-color: #f5f5fa;
}

.hover_wrapper {
    max-width: var(--wrapper-width);
    height: 100%;
    margin: 0 auto;
    background-color: #ccc;
    position: relative;
    z-index: 1;
    display: flex;
}

// -------------------------- 左侧

.left_wrapper {
    min-width: 223px;
    background-color: #fff;
    padding: 32px 0 60px;
}

.go_login {
    width: max-content;
    padding: 0 16px;
    min-width: 200px;
    height: 38px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 4px;
    cursor: pointer;
    color: var(--color-text-primary);
    margin-bottom: 24px;
    box-sizing: border-box;
    background-color: var(--page-primary-background);

    span:nth-child(1) {
        .maxLine(1);
    }

    .icon {
        line-height: 1;
        margin-left: 2px;
        font-size: 14px;
        color: #262626;
    }

    &:hover {
        color: var(--theme-color);
        border-color: var(--theme-color);

        .icon {
            color: var(--theme-color);
        }
    }
}

.parent_name {
    font-size: 18px;
    line-height: 1.44;
    color: #666;
}

.child_list {
    display: grid;
    grid-column-gap: 16px;
    grid-template-columns: repeat(2, 1fr);

    .nav_item {
        .maxLine(1);
        width: 199px;
        height: 40px;
        line-height: 40px;
        padding: 0 8px;
        margin-top: 16px;
        border-radius: 4px;
        font-size: 16px;
        cursor: pointer;
        box-sizing: border-box;
    }

    .nav_item:hover {
        color: var(--theme-color);
    }

    .nav_active {
        font-weight: 500;
        font-family: PingFangSC-Medium;
        color: var(--theme-color);
    }
}

// ------------------------------- 右侧
.right_wrapper {
    max-width: calc(100% - 223px);
    background-color: #f5f5fa;
    padding: 32px 0 60px 40px;
    box-sizing: border-box;
}

.recommend {
    line-height: 1.44;
    font-size: 18px;
    color: #666;
    margin-bottom: 8px;

    &.rec_nologin {
        line-height: 38px;
    }
}

.rec_list {
    li > a {
        display: flex;
        align-items: center;
        line-height: 3.5;
        cursor: pointer;
        color: #000;

        &:hover {
            color: var(--theme-color);
        }
    }

    li > a > .icon_row {
        color: var(--theme-color);
        margin-right: 8px;
    }

    li > a > .title {
        width: calc(100% - 16px);
        font-size: 16px;
        .maxLine(1);
    }
}

.null_data {
    font-size: 16px;
    line-height: 2.5;
    color: #999;
}
