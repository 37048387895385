.main {
    position: relative;
    max-width: 100vw;
    padding: 0 16px; // 增加间距
    // overflow-x: hidden; // 暂时注释，解决稿件详情页右侧目录内容无法吸顶的问题

    .section {
        box-sizing: border-box;
        max-width: var(--wrapper-width);
        min-height: calc(100vh - 60px);
        margin: 0 auto;
        padding: 0 0 144px;
        position: relative;
    }

    .aside {
        position: fixed;
        top: 60px;
        z-index: 999;
        box-sizing: border-box;
        max-width: 272px;
        height: 718px;
        padding: 16px;
        border-radius: 4px;
        overflow: hidden;

        &.left_aside {
            left: 0;
        }

        &.right_aside {
            right: 0;
        }
    }
}

.barkly_container {
    background-color: #12141c;
}
