.flashNewsList {
    margin-bottom: 24px;

    &:last-child {
        margin-bottom: 0;
    }
}

.flashNewsListContent {
    padding: 25px 32px 33px;
    background-color: #fff;
    border-radius: 6px;
}

.flashNewsDate {
    width: 100%;
    margin-bottom: 24px;
    color: #262626;
    font-size: var(--pc-font-size-18);
    line-height: normal;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    line-height: 26px;
    .flashNewsDate_icon {
        margin-right: 12px;
        display: inline-block;
        width: 20px;
        height: 20px;
        background-image: url(/img/newsFlash/flash_time.svg);
        background-size: cover;
    }
}

.timeline_item_box_act:hover {
    .flashNewsTitle {
        color: #2c36de !important;
    }
    :global {
        .ant-timeline-item-head {
            background-image: url('/img/place/img_news_flash.webp') !important;
            width: 16px !important;
            height: 20px !important;
            background-size: cover;
            background-color: initial !important;
            margin-inline-start: 19px !important;
            margin-top: -3px;
        }
    }
}

.timeline_item_box {
    &:first-child {
        margin-top: 16px !important;
        :global {
            .ant-timeline-item-tail {
                margin-top: -26px !important;
                height: calc(100% + 24px) !important;
            }
        }
    }
}

.timeline_item_box:last-child {
    padding-bottom: 0 !important;
    .flashNewsItem {
        margin-bottom: 0;
    }
    :global {
        .ant-timeline-item-tail {
            display: block !important;
            height: calc(100% - 18px);
        }
    }
}

.timeline_item_box {
    :global {
        .ant-timeline-item-label {
            text-align: start !important;
            width: initial !important;
            font-size: 18px;
            font-weight: 500;
            font-family: PingFangSC-Medium;
            color: #262626;
        }
        .ant-timeline-item-tail {
            inset-inline-start: 42px !important;
            margin: 0 26px;
            border-inline-start: dashed 1px #e5e5e5 !important;
        }
        .ant-timeline-item-head {
            inset-inline-start: 42px !important;
            margin-inline-start: 22.3px !important;
            width: 8px !important;
            height: 8px !important;
            border: none !important;
            background-color: #999999 !important;
            margin-top: 5px;
        }
        .ant-timeline-item-content {
            inset-inline-start: 100px !important;
            margin-inline-start: initial !important;
            width: calc(100% - 100px) !important;
        }
    }
}

.flashNewsItem {
    position: relative;
    display: flex;
    justify-content: space-between;
    box-sizing: border-box;
    margin-bottom: 20px;
    // padding: 24px;
    // background-color: var(--pc-base-background);
    // border-radius: 4px;

    .flashNewsTimer {
        width: 40px;
        padding-right: 24px;
        color: var(--pc-normal-color);
        font-size: var(--pc-font-size-16);
        white-space: pre;
    }

    .flashNewsCon {
        width: calc(100%);

        .flashNewsTitle {
            display: block;
            width: 100%;
            margin-bottom: 8px;
            overflow: hidden;
            color: var(--pc-normal-color);
            font-weight: 500;
            font-family: PingFangSC-Medium;
            font-size: var(--pc-font-size-18);
            line-height: normal;
            white-space: nowrap;
            text-overflow: ellipsis;
            cursor: pointer;
            line-height: 26px;

            &:hover {
                color: var(--pc-primary-color);
            }

            & > span {
                color: var(--pc-base-f7-color);
            }
        }

        .flashNewsdesc_box {
            :global {
                span:nth-child(2) {
                    margin-right: 15px;
                }
                // .ant-typography-expand {
                //     display: none;
                // }
            }
        }
        .flashNewsdesc_link {
            color: #2c36de;
            font-size: 14px;
            line-height: 22px;
            text-align: right;
            cursor: pointer;
            display: inline-block;
            width: 76px;

            .flashNewsdesc_link_down,
            .flashNewsdesc_link_up {
                display: flex;
                justify-content: center;
                align-items: center;
                position: absolute;
                right: 0;
                bottom: 42px;
                color: #999;

                &:hover,
                i:hover,
                e:hover {
                    color: #2c36de !important;
                }
            }
        }

        .flashNewsIntrod {
            display: -webkit-box;
            overflow: hidden;
            color: var(--pc-visited-color);
            font-size: var(--pc-font-size-14);
            line-height: 22px;
            white-space: pre-wrap;
            text-overflow: ellipsis;
            word-break: normal;
            -webkit-line-clamp: 3;
            -webkit-box-orient: vertical;
            margin-bottom: 12px;
        }

        .sourceUrl {
            display: flex;
            justify-content: center;
            align-items: center;
            color: #2c36de;
            text-align: center;
            .sourceUrl_icon {
                text-align: center;
                margin-right: 4px;
                width: 14px;
                height: 14px;
                display: inline-block;
                background-image: url(/img/newsFlash/img_flash_link.webp);
                background-size: cover;
            }

            &.sourceUrlNull {
                display: none;
            }

            a {
                display: flex;
                justify-content: center;
                align-items: center;
                color: #2c36de;
            }
        }

        .flashNewsMsg {
            display: flex;
            align-items: flex-end;
            justify-content: space-between;
            margin-top: 12px;
        }

        .flashNewsTag {
            display: flex;
            align-items: center;
            line-height: 22px;

            a {
                color: var(--pc-base-f1-color);
                font-size: var(--pc-font-size-14);

                &:hover {
                    color: #2c36de;
                }

                &.searchOriginal {
                    color: var(--pc-base-f7-color);
                }
            }
        }

        .flashNewsIcon {
            display: flex;
            align-items: center;
        }
    }
}

.link_down_icon {
    display: inline-block;
    width: 14px;
    height: 14px;
    background-image: url(/img/newsFlash/icon-arrow-new-down.svg);
    background-size: cover;
    margin-left: 4px;
}

@media screen and (max-width: 628px) {
    .flashNewsDate {
        padding: 0 24px;
    }
}
