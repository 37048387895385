@border-raduis: 13px;

.wrapper {
    height: 26px;
    padding: 0 10px;
    position: absolute;
    background-color: #f5f5fa;
    font-size: 14px;
    line-height: 26px;
    color: var(--theme-color);
    border-radius: 0 @border-raduis @border-raduis 0;
}

.leftTop {
    left: 0;
    top: 0;
}

.leftBottom {
    left: 0;
    bottom: 0;
}

.rightTop {
    right: 0;
    top: 0;
    border-radius: @border-raduis 0 0 @border-raduis;
}

.rightBottom {
    right: 0;
    bottom: 0;
    border-radius: @border-raduis 0 0 @border-raduis;
}
