.flashNewsContain {
    padding: 0px 17px 18px 15px;
    // background-color: #fff;
    // overflow-y: scroll;
}

.flashNewsList {
    margin-bottom: 12px;
}

.flashNewsListContent {
    // padding: 15px 12px 28px;
    // background-color: #fff;
    // border-radius: 6px;
}

.flashNewsTitle {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 40px;

    .flashNewsTitle_tag {
        margin-bottom: 0;
    }

    .flashNewsTitle_more {
        font-size: 14px;
        color: #999;
        line-height: 22px;
    }
}

.flashNewsDate {
    width: 100%;
    color: #262626;
    font-size: var(--pc-font-size-14);
    line-height: 1.5;
    display: flex;
    justify-content: flex-start;
    align-items: center;
}

.timeline_item_box_act:hover {
    .flashNewsTitle {
        color: #2c36de !important;
    }
    :global {
        .ant-timeline-item-content {
            div {
                color: #2c36de !important;
            }
        }
        .ant-timeline-item-head {
            width: 16px !important;
            height: 20px !important;
            background-image: url('/img/place/img_news_flash.webp') !important;
            background-size: cover;
            background-color: initial !important;
            margin-inline-start: -3px !important;
            margin-top: -3px;
        }
    }
}

// .flashNewsDate_icon {
//     width: 10px;
//     height: 10px;
//     background-image: url(/img/newsFlash/flash_time.svg);
//     background-size: cover;
// }

.timeline_item_box:first-child {
    margin-top: 16px !important;
    :global {
        .ant-timeline-item-tail {
            margin-top: -26px !important;
            height: calc(100% + 24px) !important;
        }
    }
}
.timeline_item_box:last-child {
    padding-bottom: 0 !important;
    .flashNewsItem {
        margin-bottom: 0;
    }
    :global {
        .ant-timeline-item-tail {
            display: block !important;
            height: calc(100% - 18px);
        }
    }
}

.timeline_item_box {
    :global {
        .ant-timeline-item-label {
            text-align: start !important;
            width: initial !important;
            font-size: 18px;
            font-weight: 500;
            font-family: PingFangSC-Medium;
            color: #262626;
        }

        .ant-timeline-item-tail {
            border-inline-start: dashed 1px #e5e5e5 !important;
        }

        .ant-timeline-item-head {
            width: 6px !important;
            height: 6px !important;
            border: none !important;
            background-color: #999999 !important;
            margin-top: 2px;
            margin-left: 1.5px;
        }

        .ant-timeline-item-content {
            inset-block-start: -5px !important;
            margin-inline-start: 28px !important;
        }
    }
}

.timeline_item_date {
    :global {
        .ant-timeline-item-head {
            width: 14px !important;
            height: 14px !important;
            border: none !important;
            background-image: url(/img/newsFlash/flash_time.svg) !important;
            margin-top: -1.5px;
            margin-left: -2px;
            background-size: cover;
        }
    }
}

.flashNewsItem {
    position: relative;
    display: flex;
    justify-content: space-between;
    box-sizing: border-box;
    flex-direction: column;
    // margin-bottom: 20px;

    .flashNewsTimer {
        display: flex;
        justify-content: space-between;
        // margin-bottom: 4px;
        span {
            color: var(--pc-normal-color);
            font-size: var(--pc-font-size-14);
            white-space: pre;
            line-height: 22px;
            color: #999;
        }
        .flashNewsIcon {
            display: flex;
            align-items: center;
        }
    }

    .flashNewsCon {
        width: calc(100%);

        .flashNewsTitle {
            display: block;
            width: 100%;
            margin-bottom: 8px;
            overflow: hidden;
            color: var(--pc-normal-color);
            font-size: var(--pc-font-size-16);
            text-align: left;
            cursor: pointer;
            line-height: 1.5;

            & > span {
                color: var(--pc-base-f7-color);
            }
        }

        .flashNewsdesc_box {
            :global {
                span:nth-child(2) {
                    margin-right: 15px;
                }
            }
        }
        .flashNewsdesc_link {
            color: #2c36de;
            font-size: 14px;
            line-height: 22px;
            cursor: pointer;
        }

        .flashNewsIntrod {
            color: #666;
            font-size: var(--pc-font-size-12);
            line-height: 20px;
            margin-bottom: 4px;
        }

        .sourceUrl {
            display: flex;
            justify-content: flex-start;

            &.sourceUrlNull {
                display: none;
            }

            a {
                font-size: 12px;
                list-style: 20px;
                text-align: left;
                color: #2c36de;
            }
        }

        .flashNewsMsg {
            display: flex;
            align-items: flex-end;
            justify-content: space-between;
        }
    }
}

@media screen and (max-width: 628px) {
    .flashNewsDate {
        // padding: 0 24px;
    }
}
