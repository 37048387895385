// ----------------------通用
.maxLine(@x) {
    display: -webkit-box; //特别显示模式
    overflow: hidden; //溢出内容隐藏
    white-space: pre-wrap;
    text-overflow: -o-ellipsis-lastline;
    text-overflow: ellipsis; //文本溢出部分用省略号表示
    word-wrap: break-word;
    word-break: normal;
    overflow-wrap: anywhere;
    -webkit-line-clamp: @x; //行数
    line-clamp: @x;
    -webkit-box-orient: vertical; //盒子中内容竖直排列
}

.wrapper__container {
    flex: 1;
    width: 100%;
    height: 100%;
    overflow: hidden;
}

.wrapper__default {
    display: flex;
    align-items: center;
    // justify-content: space-between;
    justify-content: flex-end;
    width: 100%;
    height: 100%;
}
.wrapper__title {
    display: flex;
    align-items: center;
    box-sizing: border-box;
    width: 100%;
    height: 100%;
    color: #262843;
    font-weight: 500;
    font-family: PingFangSC-Medium;
    font-size: 32px;
    line-height: 72px;
    letter-spacing: 0.5px;
    padding-left: 24px;
    opacity: 0;
    .maxLine(1);
}
.wrapper__default,
.wrapper__title {
    transition: all 0.3s;
}

.roll {
    .wrapper__default {
        margin-top: -72px;
        opacity: 0;
    }
    .wrapper__title {
        opacity: 1;
    }
}
