@import '~@/styles/utils.less';
@import url('~@/styles/editor.less');

.li_top {
    width: 100%;
    display: flex;
    align-items: center;
    cursor: pointer;
}

.li_top_margin {
    margin-bottom: 12px;
}

.li .logo_box {
    position: relative;
    width: 116px;
    height: 77px;
    margin-right: 8px;
    overflow: hidden;
    background-color: #ffffff;
    border-radius: 4px;
}

.li .logo {
    position: relative;
    width: 100%;
    height: 100%;
    overflow: hidden;
    border: solid 1px #e5e5e5;
    box-sizing: border-box;
    border-radius: 4px;
}

.li .logo img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.li .logo_box .business {
    position: absolute;
    top: -1px;
    left: -1px;
    height: 18px;
    line-height: 18px;
    padding: 0 6px;
    border-radius: 4px 9px 9px 0;
    background-color: #f5f5fa;
    color: var(--theme-color);
    font-size: 12px;
}

.li .info {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: calc(100% - 124px);
    height: 77px;
}

.li .info .info_top {
    width: 100%;
    margin: 0;
}

.li .info .info_top p {
    margin: 0;
}

.li .info .info_top .name_box {
    width: 100%;
    display: flex;
    justify-content: space-between;
    margin-bottom: 4px;
}

.li .info .info_top .name_box .name {
    flex: 1;
    margin-right: 16px;
    line-height: 1.57;
    color: #262626;
    .maxLine(1);
    word-break: break-all;
}

.li .info .info_top .name_box .open_account {
    min-width: 56px;
    height: 22px;
    padding: 0 10px;
    color: #fff;
    border: none;
    background-color: var(--theme-color);
    font-size: 12px;

    &:hover,
    &:focus {
        color: #fff;
    }

    &:hover {
        background-color: var(--btn-hover-color);
    }

    &:focus {
        background-color: var(--btn-click-color);
    }
}

.li .info .info_top .score {
    display: flex;
    align-items: center;

    span:first-child {
        position: relative;
        display: inline-block;
        margin-right: 8px;
        padding: 0 1px;
        color: #262626;
        font-weight: 500;
        font-family: PingFangSC-Medium;
        font-size: 16px;
        line-height: 1;

        strong {
            position: relative;
            z-index: 1;
            font-family: D-DIN, sans-serif;
        }

        &::after {
            position: absolute;
            bottom: 0;
            left: 0;
            width: 100%;
            height: 4px;
            background-color: #ffa216;
            border-radius: 2px;
            content: '';
        }
    }

    span:last-child {
        color: #999;
        font-size: 12px;
        line-height: 1.5;
    }
}

.li .tag_list {
    overflow: hidden;
    white-space: pre;
    text-overflow: ellipsis;
    font-size: 12px;
    line-height: 1.5;
    letter-spacing: normal;
    text-align: left;
    color: #666;

    a {
        color: #999;
    }
}

.wrapper_main {
    overflow: hidden;
    color: #000;
    line-height: 1.57;
    // background-color: #f7f7f8;
    border-radius: 6px;
    position: relative;
    box-sizing: border-box;
    transition: height 0.3s;
    .globalEditorContent();
}

.main_con {
    word-break: break-word;
}

.wrapper_more {
    display: flex;
    align-items: flex-end;
    justify-content: center;
    width: 100%;

    &.show_all {
        margin-top: 12px;
    }

    .more {
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;

        .text_more {
            margin-right: 4px;
            color: #999;
            font-size: 12px;
            line-height: 1.57;
        }

        .arrow_more {
            font-size: 12px;
            color: #999;
            transform-origin: 50% 50%;
            transform: rotateZ(90deg);
        }

        .arrow_more_t {
            transform: rotateZ(-90deg);
        }
    }
}
