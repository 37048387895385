@import url('~@/styles/utils.less');

.icon {
    display: block;
    color: var(--color-text-primary);
    font-size: 24px;
    line-height: 1;
    margin-left: -3px;
}
.go_top,
.tool {
    transition: bottom 0.3s;
}
// -------------------------------- 跳转到顶部 Start --------------------------------
.go_top {
    width: 54px;
    height: 54px;
    right: 9%;
    z-index: 998;
    overflow: hidden;
    border: none;
    background-color: var(--invalid-name);
    box-shadow:
        0 5px 10px -3px rgba(0, 0, 0, 0.05),
        0 8px 14px 1px rgba(0, 0, 0, 0.05),
        0 3px 14px 2px rgba(0, 0, 0, 0.04);

    &:hover,
    &:focus {
        :global {
            .ant-float-btn-body {
                background-color: var(--theme-color);
            }
        }
        .icon {
            color: var(--invalid-name);
        }
    }

    :global {
        .ant-float-btn-body {
            background-color: var(--invalid-name);
        }

        .ant-float-btn-content {
            min-height: auto !important;
        }
    }
}
// -------------------------------- 跳转到顶部 End --------------------------------

.tool {
    z-index: 998;
    position: fixed;
    right: 9%;
    width: 54px;
    height: 54px;
    border-radius: 54px;
    background-color: var(--invalid-name);
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;

    box-shadow:
        0 5px 10px -3px rgba(0, 0, 0, 0.05),
        0 8px 14px 1px rgba(0, 0, 0, 0.05),
        0 3px 14px 2px rgba(0, 0, 0, 0.04);

    .icon {
        margin-left: 0;
    }

    &:hover,
    &:focus {
        background-color: var(--theme-color);
        .icon {
            color: var(--invalid-name);
        }
    }
}

.tawk {
    .bg__default();
    background-image: url('/img/common/tawk.webp');
    background-size: 26px;

    &:hover,
    &:focus {
        background-image: url('/img/common/tawk_active.webp');
    }
}

.contrast {
    display: none;
}
// -------------------------------- icon底部定位 End --------------------------------

.bottom_icon:nth-last-child(1) {
    bottom: calc(48px);
}
.bottom_icon:nth-last-child(2) {
    bottom: calc(48px + 76px * 1);
}
.bottom_icon:nth-last-child(3) {
    bottom: calc(48px + 76px * 2);
}
.bottom_icon:nth-last-child(4) {
    bottom: calc(48px + 76px * 3);
}
// -------------------------------- icon底部定位 End --------------------------------

@media screen and (max-width: 768px) {
    .go_top,
    .tool {
        bottom: 12%;
        width: 40px;
        height: 40px;
        transition: all 0.3s;

        .icon {
            margin-left: 0;
            margin-top: 0;
            margin-bottom: 0;
            font-size: 18px;
        }
    }
    .contrast {
        display: flex;
    }
    .tawk {
        background-size: 20px;
    }

    // -------------------------------- icon底部定位 End --------------------------------
    .bottom_icon:nth-last-child(1) {
        bottom: calc(12%);
    }
    .bottom_icon:nth-last-child(2) {
        bottom: calc(12% + 52px * 1);
    }
    .bottom_icon:nth-last-child(3) {
        bottom: calc(12% + 52px * 2);
    }
    .bottom_icon:nth-last-child(4) {
        bottom: calc(12% + 52px * 3);
    }
    // -------------------------------- icon底部定位 End --------------------------------
}
