.swiperContain {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    height: 370px;
    overflow: hidden;
    -webkit-user-select: none;
    user-select: none;

    :global {
        .swiper-pagination {
            height: 5px;
            bottom: 15px;
        }

        .swiper-button-disabled {
            opacity: 1;
            cursor: pointer;
            pointer-events: auto;
        }
    }
}

.swiperLeft {
    width: calc(100% - 516px);
    height: 100%;

    .swiperBullet {
        min-width: 3.5px;
        min-height: 3.5px;
        position: relative;
        bottom: 12px;
        width: 3.5px;
        height: 3.5px;
        border-radius: 50%;
        background: var(--pc-base-background);
        display: inline-block;
        margin-right: 3.5px;
        opacity: 0.4;
        cursor: pointer;

        &:last-child {
            margin-right: 0;
        }
    }

    .swiperBulletActive {
        width: 10.4px;
        opacity: 1;
        border-radius: 2px;
    }

    .swiperBtnNext,
    .swiperBtnPrev {
        width: 36px;
        height: 50px;
        top: 50%;
        opacity: 0;
        box-shadow: 0 0 8px 0 rgba(0, 0, 0, 0.2);
        background-color: rgba(0, 0, 0, 0.45);

        &::after {
            font-size: 20px;
            color: var(--pc-base-background);
        }
    }

    .swiperBtnNext {
        right: 0;
    }

    .swiperBtnPrev {
        left: 0;
    }

    &:hover {
        .swiperBtnNext,
        .swiperBtnPrev {
            opacity: 1;
        }
    }

    .swiperCon {
        overflow: hidden;
    }

    .swiperSlide {
        box-sizing: border-box;
        height: 370px;
    }

    .swiperSlideBox {
        height: 100%;
        position: relative;
    }

    .swiperSlideTitle {
        position: absolute;
        left: 0;
        bottom: 0;
        width: 100%;
        height: 80px;
        background-color: rgba(38, 47, 45, 0.35);
        cursor: pointer;
        z-index: 1;

        .swiperSlideTitleText {
            padding: 18px 21px;
            box-sizing: border-box;
            font-size: 20px;
            letter-spacing: 0.5px;
            line-height: normal;
            color: var(--pc-base-background);
            white-space: pre;
            overflow: hidden;
            text-overflow: ellipsis;
            box-sizing: border-box;
        }
    }

    .swiperSlideTitleOne {
        height: 64px;

        .swiperSlideTitleText {
            padding: 18px 21px;
        }
    }
}

.swiperRight {
    width: 492px;
    height: 100%;
    display: grid;
    grid-template-columns: repeat(2, calc((100% - 24px) / 2));
    grid-column-gap: 24px;
    grid-row-gap: 24px;
    margin: 0;
    padding: 0;

    & > li {
        width: 234px;
        height: 173px;

        .swiperSlideBox {
            height: 100%;
            position: relative;
        }

        .swiperSlide {
            box-sizing: border-box;
            height: 173px;
        }

        .swiperSlideTitle {
            position: absolute;
            left: 0;
            bottom: 0;
            width: 100%;
            height: 38px;
            background-color: rgba(38, 47, 45, 0.35);
            cursor: pointer;
            z-index: 1;

            .swiperSlideTitleText {
                font-size: 16px;
                letter-spacing: 0.5px;
                line-height: 38px;
                padding: 0 15px;
                color: var(--pc-base-background);
                white-space: pre;
                overflow: hidden;
                text-overflow: ellipsis;
                box-sizing: border-box;
            }
        }
    }
}

@media screen and (max-width: 998px) {
    .swiperSlideTitle {
        font-size: var(--pc-font-size-18);
        min-height: 60px;
        max-height: 108px;

        .swiperSlideTitleText {
            min-height: 28px;
            max-height: 70px;
            margin: 12px 16px 22px;
        }

        .swiperSlideTitleOne {
            line-height: 150%;
            margin: 12px;
        }
    }
}
