.pwdBtns {
    display: flex;
    align-items: center;
    justify-content: center;
    user-select: none;
}

.pwd {
    svg {
        font-size: 20px;
    }
}
.divider {
    width: 61%;
    margin: 0 auto;
}

.pwdLogin {
    cursor: pointer;
    color: var(--theme-color);
    font-size: 16px;
    text-align: right;
}

.tip {
    color: #999;
    font-size: 14px;
    margin-top: 24px;
}

.thirdParties {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 24px;
    gap: 40px;

    .thirdLogo {
        cursor: pointer;
        width: 40px;
        height: 40px;
        box-sizing: border-box;
        border: 1px solid #ebebeb;
        border-radius: 50%;
        display: flex;
        align-items: center;
        justify-content: center;

        img {
            object-fit: contain;
        }
    }
}
