.brokerSelectBox {
    width: 100%;
    height: 100%;
    position: relative;

    :global {
        .ant-select {
            height: 100%;
        }

        .ant-select-selector {
            padding-left: 44px !important;
        }

        .ant-select-focused {
            .ant-select-selector {
                border-color: var(--theme-color) !important;
                box-shadow: 0 0 0 2px rgba(44, 54, 222, 0.1) !important;
            }
        }
    }

    .place {
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: flex-start;
        color: #999;

        .icon {
            font-size: 14px;
            margin-right: 12px;
            line-height: 1;
            display: flex;
            align-items: center;
        }
    }

    .prefix {
        display: flex;
        align-items: center;
        height: 16px;
        position: absolute;
        left: 16px;
        top: calc(50% + 1px);
        transform: translateY(-50%);
        color: #262626;
        font-size: 16px;
    }
}

.brokerSelect {
    width: 100%;

    :global {
        .ant-select-selector,
        .ant-select-selector input {
            height: 100% !important;
        }

        .ant-select-selection-placeholder {
            height: 100%;
            padding-inline-end: 0 !important;
        }

        .ant-select-selector input {
            padding-left: 32px !important;
            font-size: 16px;
        }
    }

    &:hover {
        :global {
            .ant-select-selector {
                border-color: var(--theme-color) !important;
            }
        }
    }
}

// 搜索框 居中
.brokerSelectCenter {
    :global {
        .ant-select-selector,
        .ant-select-selector input {
            text-align: center;
        }

        .ant-select-selector {
            padding-left: 11px !important;
        }

        .ant-select-selector input {
            padding-left: 0 !important;
        }

        .ant-select-selection-placeholder {
            text-align: center;
        }
    }

    .place {
        justify-content: center;
        padding: 0;
    }

    .prefix {
        display: none;
    }
}

// 搜索框 大 Large
.brokerSelectLarge {
    .place {
        .icon {
            font-size: 16px;
            margin-right: 16px;
        }
    }
}

.popupClass {
    width: 100% !important;
    padding: 0 !important;
    border-radius: 4px !important;
    z-index: 900 !important;

    :global {
        .ant-select-item {
            padding: 0 12px !important;
        }

        .rc-virtual-list-scrollbar {
            width: 8px !important;
        }

        .rc-virtual-list-scrollbar-thumb {
            width: 8px !important;
            background: #e7e7e7 !important;
        }
    }
}

.swiperBox {
    height: 38px;
    padding: 0 12px;
}

.tabsItem {
    position: relative;
    width: auto;
    color: #999;
    font-size: 12px;
    line-height: 38px;

    &:last-child {
        margin-right: 0 !important;
    }

    &.active {
        color: var(--theme-color);
        font-size: 14px;
    }
}

.selOption {
    width: 100%;
    height: 40px;
    border-radius: 0 !important;
}

.selItem {
    height: 40px;
    display: flex;
    align-items: center;

    .logo {
        position: relative;
        display: flex;
        align-items: center;
        justify-content: center;
        box-sizing: border-box;
        width: 20px;
        height: 20px;
        border: solid 1px #e5e5e5;
        border-radius: 2px;
    }

    .img {
        background-color: #fff;
        border-radius: 2px;
    }

    .name {
        flex: 1;
        margin-left: 12px;
        overflow: hidden;
        color: #010b27;
        font-weight: normal;
        font-size: 14px;
        line-height: 1.5;
        letter-spacing: 0.5px;
        white-space: nowrap;
        text-overflow: ellipsis;
        cursor: pointer;
    }
}

// 搜索框 popup居中
.popupCenterClass {
    font-size: 1px;

    .swiperBox {
        border-bottom: 1px solid #f2f2f4;
    }
}

.nullData {
    padding: 24px;
}

.noMore {
    cursor: auto !important;

    .noMoreText {
        padding: 8px 0;
        color: var(--pc-base-f1-color);
        font-size: 12px;
        text-align: center;
    }
}
