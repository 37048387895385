.market {
    height: 46px;
    overflow: hidden;
    background-color: #fff;

    &.market_loading {
        position: absolute;
        top: -20%;
        z-index: -1;
        width: 100%;
        opacity: 0;
    }
}

.wrapper {
    position: relative;
    display: flex;
    align-items: flex-start;
    overflow: hidden;
    background-color: #000;

    & > div {
        display: none;
    }

    & > div:first-child {
        display: block;
    }
}
