.overlay {
    z-index: 1000;
    cursor: pointer;

    :global {
        .ant-popover-inner {
            padding: 0 !important;
        }
        .ant-popover-inner-content {
            overflow: hidden;
            border-radius: 4px;
        }
    }

    .p {
        display: flex;
        align-items: center;
        justify-content: center;
        min-width: 100px;
        height: 48px;
        color: #262b2e;
        background: #ffffff;

        &:hover,
        &.active {
            background: #f4f4f4;
        }
    }

    &:hover {
        cursor: pointer;
    }
}

.content {
    display: flex;
    align-items: center;
    justify-content: center;
    box-sizing: border-box;
    width: 130px;
    height: 40px;
    border: solid 1px #ebebeb;
    border-radius: 4px;

    .prefix {
        color: #33354e;
        font-size: 16px;
    }

    .suffix {
        color: #33354e;
        font-size: 12px;
    }

    .text {
        margin: 0 4px;
        color: #33354e;
    }

    &:hover {
        border-color: #00d5b0;

        .prefix,
        .suffix,
        .text {
            color: #33354e;
        }
    }
}
