.nav {
    width: calc(100% - 214px);
    height: 100%;
    overflow: hidden;
    transition: all 0.3s;
}

.nav_hide {
    width: 0;
}

.list {
    display: flex;
    height: 100%;
    // padding-left: 24px;
}

.list_item {
    width: auto !important;
    padding: 0 16px;
    color: #262626;
    font-size: 16px;
    cursor: pointer;
    user-select: none;

    &:first-child {
        padding-left: 0;

        &.active_bg {
            margin-left: 12px;
        }
    }

    &:last-child {
        padding-right: 0;
    }

    &:hover,
    &.active {
        .aHref {
            color: var(--theme-color);
        }
    }

    &.active {
        .aHref {
            font-weight: 500;
            font-family: PingFangSC-Medium;

            &::after {
                position: absolute;
                bottom: 0;
                left: 0;
                width: 100%;
                height: 4px;
                background-color: var(--theme-color);
                border-radius: 4px 4px 0 0;
                animation: line_fun 0.2s ease-in-out 1 forwards;
                content: '';
            }
        }
    }

    .j {
        display: none;
    }

    &.active_bg {
        padding: 0 16px;
        background-color: #f5f5fa;
        margin-top: 5px;
        padding-bottom: 5px;
        height: calc(100% - 5px);
        border-radius: 8px 8px 0 0;
        position: relative;
        box-sizing: border-box;

        .aHref {
            color: var(--theme-color);
            font-weight: 500;
            font-family: PingFangSC-Medium;
        }
    }

    &.active_bg .j {
        display: inline-block;
        width: 12px;
        height: 12px;
        position: absolute;
        bottom: 0;
        background-color: #f5f5fa;

        &::after {
            position: absolute;
            bottom: 0;
            width: 12px;
            height: 12px;
            background-color: #fff;
            content: '';
        }
    }

    &.active_bg .j_l {
        left: -12px;

        &::after {
            right: 0;
            border-radius: 0 0 8px 0;
        }
    }

    &.active_bg .j_r {
        right: -12px;

        &::after {
            left: 0;
            border-radius: 0 0 0 8px;
        }
    }

    .aHref {
        position: relative;
        display: flex;
        align-items: center;
        height: 100%;
        color: #262626;
        white-space: nowrap;
    }
}

@keyframes line_fun {
    from {
        bottom: -4px;
        opacity: 0.4;
    }

    to {
        bottom: 0;
        opacity: 1;
    }
}

.barkly_container {
    .list_item {
        .aHref {
            color: #fff;
            &::after {
                background-color: #fff;
            }
        }
        &.active_bg {
            background-color: #3c3e46;
            .aHref {
                color: #fff;
            }
            .j {
                background-color: #3c3e46;
                &::after {
                    background-color: #12141c;
                }
            }
        }
    }
}
