@import '~@/styles/setting_form.pc.less';

.tabs {
    padding: 0;
    display: flex;
    flex-direction: column;
    align-items: center;

    :global {
        .ant-tabs-content-holder {
            width: 100%;
        }
        .ant-tabs-nav {
            margin: 0 0 24px 0;

            &::before {
                display: none;
            }
        }

        .ant-tabs-ink-bar {
            display: none;
        }

        .ant-tabs-tab {
            color: var(--color-text-explain);
            font-size: 20px;
            line-height: 1.4;
            margin: 0;
            padding: 0 0 8px 0;
            :hover {
                color: var(--color-text-primary);
            }
        }

        .ant-tabs-tab-active {
            position: relative;

            .ant-tabs-tab-btn {
                color: var(--color-text-primary) !important;
            }

            &::after {
                content: ' ';
                position: absolute;
                bottom: 0;
                left: 50%;
                transform: translateX(-50%);
                width: 32px;
                height: 4px;
                border-radius: 4px;
                background-color: var(--color-text-primary);
            }
        }
    }
}
.formType {
    font-size: 20px;
    font-weight: 500;
    font-family: PingFangSC-Medium;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.4;
    letter-spacing: normal;
    text-align: center;
    color: var(--color-text-primary);
}
