.ugcRec {
    width: 278px;
    padding: 30px 16px 24px;
    margin-bottom: 24px;
    border-radius: 8px;
    box-sizing: border-box;
    position: relative;
    overflow: hidden;
    box-shadow: 0 0 16px 0 rgba(0, 0, 0, 0.05);
    background-image: linear-gradient(to bottom, #f4fffd 0%, #fff 38%, #fff 100%);
}

.watermark {
    display: block;
    width: 111px;
    height: 76px;
    position: absolute;
    top: 0;
    left: 0;
    border-radius: 8px 0 76px 0;
    z-index: 0;
    background-image: linear-gradient(34deg, #f8fffe 17%, #e1fffa 67%);
}

.ugcRecCon {
    position: relative;

    .ugcRecTit {
        display: flex;
        align-items: center;

        span {
            display: block;

            &:nth-of-type(1) {
                margin-right: 8px;
                font-size: 24px;
                font-weight: 500;
                font-family: PingFangSC-Medium;
                line-height: 24px;
                padding-bottom: 2px;
                color: var(--pc-normal-color);
            }
        }
    }

    .ugcRecList {
        li {
            .itemCon {
                display: flex;
                align-items: flex-start;
                position: relative;
                padding: 24px 0;
                color: var(--pc-normal-color);

                &::after {
                    content: '';
                    position: absolute;
                    bottom: 0;
                    left: 0;
                    width: 100%;
                    height: 1px;
                    background-color: var(--pc-normal-hover-color);
                }
            }

            .itemCover {
                width: 48px;
                height: 48px;
                border-radius: 4px;
                overflow: hidden;
                margin-right: 8px;
                cursor: pointer;
                color: var(--pc-base-background);
                position: relative;
                border: 1px solid rgba(0, 0, 0, 0.02);

                .avatar {
                    background-color: transparent !important;
                }
            }

            .itemContain {
                width: calc(100% - 56px);
                height: 48px;
                position: relative;
                padding-right: 10px;
                cursor: pointer;
                display: flex;
                flex-direction: column;
                justify-content: space-between;
                box-sizing: border-box;

                .itemTitle {
                    font-size: var(--pc-font-size-18);
                    color: var(--pc-normal-color);
                    white-space: pre;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    font-weight: 500;
                    font-family: PingFangSC-Medium;
                    margin-top: -4px;

                    &:hover {
                        color: var(--pc-primary-color);
                    }
                }

                .itemMsg {
                    line-height: normal;
                    font-size: var(--pc-font-size-14);
                    color: var(--pc-base-f1-color);
                    margin-bottom: -2px;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    white-space: nowrap;
                }

                .itemArrow {
                    position: absolute;
                    top: 50%;
                    right: 0;
                    transform: translateY(-50%);
                    font-size: var(--pc-font-size-14);
                    color: var(--pc-normal-color);
                }
            }

            &:last-child {
                a {
                    &::after {
                        display: none;
                    }
                }
            }
        }
    }

    .loadMore {
        width: 100%;
        height: 40px;
        display: block;
        text-align: center;
        line-height: 40px;
        background-color: var(--pc-base-f3-color);
        font-size: var(--pc-font-size-14);
        letter-spacing: 0.6px;
        color: var(--pc-visited-color);
        cursor: pointer;

        &:hover {
            color: var(--pc-primary-color);
        }
    }
}
