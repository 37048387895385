@import url('~@/styles/utils.less');

.content {
    display: flex;
    align-items: center;
    justify-content: center;
    box-sizing: border-box;
    height: 32px;

    .prefix {
        width: 20px;
        height: 20px;
        position: relative;
    }
}

.drawer {
    :global {
        .ant-drawer-body {
            display: flex;
            flex-direction: column;
            padding: 0;
        }
    }

    .header {
        width: 100%;
        flex-shrink: 0;
        box-sizing: border-box;
        padding: 24px 20px;
        span {
            font-size: 20px;
        }
    }
    .go_login {
        width: calc(100% - 32px);
        margin: 0 16px;
        height: 38px;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 4px;
        cursor: pointer;
        color: var(--color-text-primary);
        margin-bottom: 16px;
        box-sizing: border-box;
        border: solid 1px #e5e5e5;

        span:nth-child(1) {
            .maxLine(1);
        }

        .icon {
            line-height: 1;
            margin-left: 2px;
            font-size: 14px;
            color: #262626;
        }
    }

    .list {
        flex: 1;
        overflow: auto;
    }
    .list__item {
        width: 100%;
        padding: 14px;
        background-color: #f7f7f8;
        display: flex;
        line-height: 1.57;
        color: var(--color-text-secondary);
        background-color: #ffffff;

        &.active {
            color: var(--color-text-primary);
            background-color: #f7f7f8;
        }
    }
}
