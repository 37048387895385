@import '~@/styles/utils.less';
@import url('~@/styles/editor.less');

.li_top {
    width: 100%;
    display: flex;
    align-items: center;
}

.li .logo_box {
    position: relative;
    width: 130px;
    height: 86px;
    margin-right: 8px;
    overflow: hidden;
    background-color: #ffffff;
    border-radius: 4px;
    flex-shrink: 0;
}

.li .logo {
    position: relative;
    width: 100%;
    height: 100%;
    overflow: hidden;
    border: solid 1px #e5e5e5;
    box-sizing: border-box;
    border-radius: 4px;
}

.li .logo img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.li .logo_box .business {
    position: absolute;
    top: -1px;
    left: -1px;
    height: 18px;
    line-height: 18px;
    padding: 0 6px;
    border-radius: 4px 9px 9px 0;
    background-color: #f5f5fa;
    color: var(--theme-color);
    font-size: 12px;
}

.li .info {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: calc(100% - 124px);
    height: 100%;
}

.li .info .info_top {
    width: 100%;
    margin: 0;
}

.li .info .info_top p {
    margin: 0;
}

.li .info .info_top .name_box {
    width: 100%;
    display: flex;
    justify-content: space-between;
    margin-bottom: 4px;
}

.li .info .info_top .name_box .name {
    flex: 1;
    margin-right: 16px;
    line-height: 1.57;
    color: #262626;
    .maxLine(1);
    word-break: break-all;
}

.li .info .info_top .name_box .open_account {
    min-width: 56px;
    height: 22px;
    padding: 0 10px;
    color: #fff;
    border: none;
    background-color: var(--theme-color);
    font-size: 12px;

    &:hover,
    &:focus {
        color: #fff;
    }

    &:hover {
        background-color: var(--btn-hover-color);
    }

    &:focus {
        background-color: var(--btn-click-color);
    }
}

.li .info .info_top .score {
    display: flex;
    align-items: center;

    span:first-child {
        position: relative;
        display: inline-block;
        margin-right: 8px;
        padding: 0 1px;
        color: #262626;
        font-weight: 500;
        font-family: PingFangSC-Medium;
        font-size: 16px;
        line-height: 1;

        strong {
            position: relative;
            z-index: 1;
            font-family: D-DIN, sans-serif;
        }

        &::after {
            position: absolute;
            bottom: 0;
            left: 0;
            width: 100%;
            height: 4px;
            background-color: #ffa216;
            border-radius: 2px;
            content: '';
        }
    }

    span:last-child {
        color: #999;
        font-size: 12px;
        line-height: 1.5;
    }
}
.li .btn__wrapper {
    display: flex;
    gap: 8px;
    justify-content: flex-end;
    width: 100%;
    margin-top: 10px;
}
.li .btn__wrapper .open_account,
.li .btn__wrapper .compared {
    height: 26px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 4px;
    font-size: 12px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    letter-spacing: normal;
}
.li .btn__wrapper .open_account {
    color: var(--invalid-name);
    background-color: #2c36de;
    width: 96px;

    &:hover,
    &:active {
        color: var(--invalid-name);
        background-color: #2c36de;
        border: solid 1px #2c36de;
    }
}
.li .btn__wrapper .compared {
    width: 84px;
    border: solid 1px #2c36de;
    background-color: var(--invalid-name);
    color: #2c36de;

    &:hover,
    &:active {
        border: solid 1px #2c36de;
        background-color: var(--invalid-name);
        color: #2c36de;
    }
}

.li .tag_list {
    overflow: hidden;
    white-space: pre;
    text-overflow: ellipsis;
    font-size: 12px;
    line-height: 1.5;
    letter-spacing: normal;
    text-align: left;
    color: #666;
}
.wrapper_main {
    margin-top: 12px;
    overflow: hidden;
    color: #000;
    line-height: 1.57;
    // background-color: #f7f7f8;
    // padding: 12px;
    border-radius: 6px;
    position: relative;
    box-sizing: border-box;
    .globalEditorContent();

    .text_mask {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100px;
        background-image: linear-gradient(to bottom, rgba(255, 255, 255, 0), #fff);
    }
}
.wrapper_bottom {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 22px;
    margin-top: 12px;
}
.wrapper_bottom .bottom_left {
    .maxLine(1);
    flex: 1;
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 22px;
    letter-spacing: 0.5px;
    text-align: left;
    color: #666;
    margin-right: 4px;
}
.wrapper_more {
    display: flex;
    align-items: flex-end;
    justify-content: center;
    flex-shrink: 0;

    &.show_all {
    }

    .more {
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        font-size: 12px;

        .text_more {
            margin-right: 4px;
            color: #999;
            line-height: 18px;
        }

        .arrow_more {
            color: #999;
            transform-origin: 50% 50%;
            transform: rotateZ(90deg);
        }

        .arrow_more_t {
            transform: rotateZ(-90deg);
        }
    }
}
