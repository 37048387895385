.hotNews {
    position: relative;
    box-sizing: border-box;
    width: 278px;
    margin-bottom: 24px;
    padding: 30px 16px 24px;
    overflow: hidden;
    background-image: linear-gradient(to bottom, #f4fffd, #fff 38%, #fff 100%);
    border-radius: 8px;
    box-shadow: 0 0 16px 0 rgba(0, 0, 0, 0.05);
}

.watermark {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 0;
    display: block;
    width: 111px;
    height: 76px;
    background-image: linear-gradient(34deg, #f8fffe 18%, #e1fffa 67%);
    border-radius: 8px 0 76px;
}

.hotNewsCon {
    position: relative;

    .hotNewsTit {
        display: flex;
        align-items: center;
        margin-bottom: 24px;

        span {
            display: block;

            &:nth-of-type(1) {
                margin-right: 8px;
                padding-bottom: 2px;
                color: var(--pc-normal-color);
                font-weight: 500;
                font-family: PingFangSC-Medium;
                font-size: 24px;
                line-height: 24px;
            }
        }
    }

    .hotNewsList {
        li {
            position: relative;
            display: flex;
            align-items: flex-start;
            margin-bottom: 24px;
            color: var(--pc-normal-color);

            a {
                display: flex;
                overflow: hidden;
            }

            .itemCover {
                position: relative;
                width: 90px;
                height: 70px;
                margin-right: 16px;
                overflow: hidden;
                border: 1px solid rgba(0, 0, 0, 0.02);
                border-radius: 1px;
                cursor: pointer;

                img {
                    display: block;
                    width: 100%;
                    height: 100%;
                    object-fit: cover;
                }

                .rank {
                    position: absolute;
                    top: 0;
                    left: 0;
                    display: block;
                    width: 22px;
                    height: 22px;
                    color: var(--pc-base-background);
                    font-size: var(--pc-font-size-14);
                    line-height: 22px;
                    text-align: center;
                    background-color: var(--pc-base-f1-color);
                    border-radius: 0 0 10px 10px;
                }
            }

            .itemContain {
                display: flex;
                flex-direction: column;
                justify-content: space-between;
                width: calc(100% - 106px);
                height: 70px;
                overflow: hidden;
                cursor: pointer;

                .itemTitle {
                    display: -webkit-box;
                    margin-top: -2px;
                    overflow: hidden;
                    color: var(--pc-normal-color);
                    font-size: var(--pc-font-size-16);
                    line-height: var(--pc-line-height-a);
                    letter-spacing: 1.5px;
                    white-space: pre-wrap;
                    text-overflow: ellipsis;
                    word-break: normal;
                    -webkit-line-clamp: 2;
                    -webkit-box-orient: vertical;

                    &:hover {
                        color: var(--pc-primary-color);
                    }
                }

                .itemMsg {
                    margin-bottom: -2px;
                    overflow: hidden;
                    color: var(--pc-base-f1-color);
                    font-size: var(--pc-font-size-14);
                    line-height: normal;
                    white-space: nowrap;
                    text-overflow: ellipsis;
                }

                &:hover {
                    color: var(--pc-primary-color);
                }
            }

            &:nth-of-type(1) {
                .itemCover {
                    .rank {
                        background-color: var(--pc-base-f5-color);
                    }
                }
            }

            &:nth-of-type(2) {
                .itemCover {
                    .rank {
                        background-color: var(--pc-base-f6-color);
                    }
                }
            }

            &:nth-of-type(3) {
                .itemCover {
                    .rank {
                        background-color: var(--pc-base-f8-color);
                    }
                }
            }

            &:last-child {
                margin-bottom: 0;
            }
        }
    }
}

@media screen and (max-width: 628px) {
    .hotNewsCon {
        .hotNewsList {
            li {
                .itemContain {
                    .itemMsg {
                        font-size: var(--pc-font-size-10);
                    }
                }
            }
        }
    }
}
