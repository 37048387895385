.hide {
    display: none;
}

.mask,
.auth {
    position: absolute;
    width: 100%;
    height: 100%;
    top: -0;
    left: 0;
    z-index: 998;

    &.page {
        z-index: 990;
    }

    &.components {
        display: flex;
        justify-content: center;
        align-items: center;
    }
}

.mask {
    background-color: rgba(255, 255, 255, 0.8);
    -webkit-backdrop-filter: blur(8px);
    backdrop-filter: blur(8px);
}

.contain {
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding-bottom: 80px;

    .tip {
        margin-top: 20px;
        letter-spacing: 0.5px;
        text-align: center;
        color: var(--color-text-primary);
    }

    .btn {
        min-width: 118px;
        height: 38px;
        margin-top: 12px;
        border-radius: 4px;
        background-color: var(--theme-color);
        line-height: 1.57;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        &:hover {
            background-color: var(--btn-hover-color);
        }
    }

    .btn span {
        letter-spacing: 0.5px;
        font-size: 14px;
        text-align: center;
        color: var(--invalid-name);
    }
}
