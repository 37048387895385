.wrapper {
    margin-top: 40px;
}

.wlWraper {
    :global {
        .wl-comment {
            margin-bottom: 0;

            .wl-login-info {
                margin-right: 10px;
                min-width: 58px;

                .wl-avatar {
                    .wl-logout-btn {
                        display: none;
                    }

                    .wl-login-nick {
                        width: 100%;
                        height: 100%;

                        img {
                            object-fit: cover;
                        }
                    }
                }

                .wl-login-nick {
                    color: var(--pc-primary-color);
                    word-wrap: break-word;
                }
            }

            .wl-panel {
                margin: 0;
                border-radius: 4px;

                .wl-editor {
                    padding: 8px;
                    box-sizing: border-box;
                    border-radius: 4px;
                    margin-bottom: 0;


                    &:focus {
                        background-color: var(--pc-line-background);
                    }
                }

                .wl-footer {
                    .wl-actions {
                        display: none;
                    }

                    .wl-info {
                        button.wl-btn {
                            display: none;
                        }

                        button.wl-btn[type='submit'] {
                            display: block;
                            border-color: var(--pc-primary-color);
                            background-color: var(--pc-primary-color);
                        }
                    }
                }
            }

            .wl-close {
                right: 0;
                top: 0;
            }

            .login-btn {
                display: inline-block;
                vertical-align: middle;
                min-width: 2.5em;
                margin-bottom: 0;
                padding: 0.5em 1em;
                border: 1px solid var(--waline-border-color);
                border-radius: 0.5em;
                background: rgba(0, 0, 0, 0);
                color: var(--waline-color);
                font-weight: 400;
                font-size: .75em;
                line-height: 1.5;
                text-align: center;
                white-space: nowrap;
                cursor: pointer;
                -webkit-user-select: none;
                -moz-user-select: none;
                -ms-user-select: none;
                user-select: none;
                transition-duration: .4s;
                touch-action: manipulation;
            }
        }

        .wl-meta-head {
            padding: .5em 0 .375em;

            .wl-sort>.active {
                color: var(--pc-primary-color);
            }
        }

        .wl-cards {
            .wl-card-item {
                padding: 16px 0;

                .wl-like {
                    margin-right: 8px;
                }

                .wl-user {
                    img {
                        object-fit: cover;
                    }
                }

                .wl-content {
                    a {
                        color: var(--pc-primary-color);
                    }
                }
            }
        }
    }
}

.loginOut {
    :global {
        .wl-comment {
            .wl-login-info {
                display: none;
            }

            .wl-panel {
                .wl-footer {
                    .wl-info {
                        button.wl-btn[type='submit'] {
                            display: none;
                        }
                    }
                }
            }
        }

        .wl-cards {
            .wl-card-item {
                .wl-comment-actions {
                    display: none;
                }
            }
        }
    }
}

@media screen and (max-width: 768px) {
    .wlWraper {
        padding: 0 24px;
        box-sizing: border-box;

        :global {
            .wl-comment {
                margin-bottom: 0;

                .wl-login-info {
                    margin-right: 5px;

                    .wl-avatar {
                        .wl-logout-btn {
                            display: none;
                        }

                        .wl-login-nick {
                            width: 100%;
                            height: 100%;

                            img {
                                object-fit: cover;
                            }
                        }
                    }

                    .wl-login-nick {
                        color: var(--pc-primary-color);
                        word-wrap: break-word;
                    }
                }

                .wl-panel {
                    margin: 0;
                    border-radius: 4px;

                    .wl-editor {
                        padding: 8px;
                        box-sizing: border-box;
                        border-radius: 4px;
                        margin-bottom: 0;


                        &:focus {
                            background-color: var(--pc-line-background);
                        }
                    }

                    .wl-footer {
                        .wl-actions {
                            display: none;
                        }

                        .wl-info {
                            button.wl-btn {
                                display: none;
                            }

                            button.wl-btn[type='submit'] {
                                display: block;
                                border-color: var(--pc-primary-color);
                                background-color: var(--pc-primary-color);
                            }
                        }
                    }
                }

                .wl-close {
                    right: 0;
                    top: 0;
                }
            }
        }
    }
}