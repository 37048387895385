.articleContain {
    width: 100%;
    margin: 0 auto;
}

.articleList {
    display: grid;
    grid-column-gap: 24px;
    padding: 0 12px;
    background-color: var(--pc-base-background);
    border-radius: 0 0 6px 6px;
    & > div {
        width: 100% !important;
        overflow: hidden;
    }

    &.listOne {
        grid-template-columns: repeat(1, 1fr);
    }
}

.articleItem:last-child {
    &::after {
        display: none;
    }
}

.articleItem {
    position: relative;
    box-sizing: border-box;
    width: 100%;
    padding: 12px 0;
    overflow: hidden;
    background-color: var(--pc-base-background);
    border-radius: 0;
    box-shadow: 0;
    transition: 0;

    &:hover {
        transform: translateY(0);
    }

    &::after {
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        height: 1px;
        background-color: var(--pc-line-background);
        content: '';
    }

    & > a {
        display: flex;
        align-items: center;
        justify-content: space-between;
        &:visited {
            .articleCon {
                .articleTitle {
                    color: var(--pc-visited-color);
                }
            }
        }

        &:hover {
            .articleCon {
                .articleTitle {
                    color: var(--pc-normal-color);
                }
            }
        }
    }

    .articleItem_a_box {
        display: flex;
        flex-direction: row;
    }

    .articleCover {
        position: relative;
        flex-shrink: 0;
        z-index: 1;
        display: block;
        box-sizing: border-box;
        width: 112px;
        height: 74px;
        margin-right: 8px;
        overflow: hidden;
        background-color: var(--pc-line-background);
        border-radius: 4px;
        cursor: pointer;

        &::after {
            display: none;
        }

        .videoPlay {
            position: absolute;
            top: 50%;
            left: 50%;
            z-index: 2;
            display: block;
            color: var(--pc-base-background);
            font-size: 32px;
            transform: translate(-50%, -50%);
            opacity: 0;
        }
    }

    .articleCoverRight {
        order: 2;
        margin-right: 0;
        margin-left: 8px;
    }

    .articleConRight {
        order: 1;
    }

    .articleCon {
        display: flex;
        flex: 1;
        flex-direction: column;
        justify-content: space-between;
        box-sizing: border-box;
        max-width: 100%;
        height: auto;
        padding: 0;
    }

    .articleTitle {
        display: block;
        display: -webkit-box;
        margin-bottom: 12px;
        overflow: hidden;
        color: var(--pc-normal-color);
        color: #262626;
        font-weight: 500;
        font-family: PingFangSC-Medium;
        font-weight: normal;
        font-size: var(--pc-font-size-14);
        line-height: var(--pc-line-height-a);
        line-height: 1.57;
        letter-spacing: 0.5px;
        white-space: pre-wrap;
        text-overflow: ellipsis;
        word-break: normal;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;

        &:hover {
            color: var(--pc-primary-color);
        }

        label {
            cursor: pointer;
        }

        h2 {
            display: inline;
            font-weight: normal;
            font-size: inherit;
            white-space: normal;
        }

        .top,
        .origin {
            position: relative;
            top: -2px;
            display: inline-block;
            width: 40px;
            height: 18px;
            margin-right: 4px;
            margin-bottom: 2px;
            font-weight: normal;
            font-size: var(--pc-font-size-12);
            line-height: 18px;
            text-align: center;
            border-radius: 2px;
        }

        .top {
            color: var(--pc-base-f7-color);
            border: 1px solid var(--pc-base-f7-color);
        }

        .margin {
            margin-right: 8px;
        }

        .origin {
            margin-right: 8px;
            color: var(--pc-primary-color);
            border: 1px solid var(--pc-primary-color);
        }

        .en_origin {
            width: 60px;
        }
    }

    .articleMsg {
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        justify-content: space-between;
        color: var(--pc-visited-color);
        font-size: var(--pc-font-size-12);

        .msga {
            max-width: 100%;
            overflow: hidden;
            color: #262626;
            font-weight: normal;
            font-size: var(--pc-font-size-12);
            line-height: 1.5;
            white-space: nowrap;
            text-align: left;
            text-overflow: ellipsis;
            :global {
                .ant-divider-vertical {
                    color: #e5e5e5;
                    border-color: #e5e5e5;
                }
            }
        }

        .msgb {
            color: #999;
            font-size: var(--pc-font-size-12);
            line-height: 1.5;
            text-align: left;
        }

        .icon {
            max-width: 100%;
            margin-right: 4px;
            overflow: hidden;
            color: var(--pc-base-f1-color);
            line-height: normal;
            white-space: nowrap;
            text-overflow: ellipsis;
        }

        & > div {
            position: relative;
            display: flex;
            flex: 1;
            align-items: flex-end;
            min-width: 120px;
        }

        .msgc {
            display: flex;
            align-items: center;
            letter-spacing: 1px;
        }
    }
}

.typeOne {
    .articleCon {
        height: auto;
        min-height: 68px;
        padding: 0;
    }
}
.audio__btn {
    position: absolute;
    top: 4px;
    right: 4px;
    z-index: 1;
    width: 16px;
    height: 16px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 16px;
    background-color: rgba(0, 0, 0, 0.5);
}
.video_play_btn {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    margin: auto;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgba(0, 0, 0, 0.3);
    z-index: 10;
}
.articleItem.barkly_container {
    background-color: #0c0d13;
    a {
        display: flex;
        align-items: center;
        justify-content: space-between;
        .articleCon {
            .articleTitle {
                color: #fff;
            }
        }
    }
    .articleMsg {
        .msga {
            color: #fff;
        }
    }
    &::after {
        background-color: rgba(255, 255, 255, 0.3);
    }
}
