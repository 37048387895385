.wrapper {
    position: relative;
    box-sizing: border-box;
    width: 100%;
    min-width: 920px;
    min-height: 100vh;
    background-color: var(--pc-line-background);
}

.container {
    display: flex;
}
