.wrapper {
    position: relative;
    box-sizing: border-box;
    width: 100%;
    min-height: 100vh;
    background-color: #f5f5fa;
    display: flex;
    flex-direction: column;
}
.barkly_container {
    background-color: #0c0d13;
}
