@import '~@/styles/utils.less';

.li {
    display: block;
    box-sizing: border-box;
    height: 124px;
    padding: 16px;
}

.li .item_top {
    display: flex;
    align-items: center;
    margin-bottom: 12px;
}

.li .logo {
    position: relative;
    box-sizing: border-box;
    width: 58px;
    height: 58px;
    margin-right: 12px;
    overflow: hidden;
    border: solid 1px #e5e5e5;
    border-radius: 4px;
    box-sizing: border-box;
}

.li .logo img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.li .info {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: calc(100% - 70px);
    height: 58px;
}

.li .info p {
    width: 100%;
    margin: 0;
}

.li .info .name {
    width: 100%;
    color: #262626;
    font-weight: 500;
    font-family: PingFangSC-Medium;
    line-height: 1.86;
    .maxLine(1);

    &:hover {
        color: var(--theme-color);
    }
}

.li .info .score {
    display: flex;
    align-items: center;

    span:first-child {
        position: relative;
        display: inline-block;
        margin-right: 8px;
        padding: 0 1px;
        color: #262626;
        font-weight: 500;
        font-family: PingFangSC-Medium;
        font-size: 22px;
        line-height: 1;

        strong {
            position: relative;
            z-index: 1;
            font-family: D-DIN, sans-serif;
        }

        &::after {
            position: absolute;
            bottom: 0;
            left: 0;
            width: 100%;
            height: 6px;
            background-color: #ffa216;
            border-radius: 3px;
            content: '';
        }
    }

    span:last-child {
        color: #999;
        font-size: 14px;
        line-height: 1.57;
    }
}

.li .tag_list {
    overflow: hidden;
    color: #999;
    white-space: pre;
    text-overflow: ellipsis;
}
