// 文字限制一行
.overflow__hiding__one {
    display: -webkit-box; //特别显示模式
    overflow: hidden; //溢出内容隐藏
    text-overflow: -o-ellipsis-lastline;
    text-overflow: ellipsis; //文本溢出部分用省略号表示
    word-break: normal;
    -webkit-line-clamp: 1; //行数
    line-clamp: 1;
    -webkit-box-orient: vertical; //盒子中内容竖直排列
}

.flashNewsList {
    & > div:last-child > .flashNewsItem {
        margin-bottom: 24px;
    }
}

.flashNewsDate {
    width: 100%;
    margin-bottom: 16px;
    color: var(--pc-visited-color);
    font-size: var(--pc-font-size-16);
    line-height: normal;
}

.flashNewsItem {
    position: relative;
    display: flex;
    justify-content: space-between;
    box-sizing: border-box;
    margin-bottom: 16px;
    padding: 24px;
    background-color: var(--pc-base-background);
    border-radius: 4px;

    .flashNewsTimer {
        // width: 40px;
        padding-right: 24px;
        color: var(--pc-normal-color);
        font-size: var(--pc-font-size-16);
        white-space: pre;
    }

    .flashNewsCon {
        flex: 1;

        .flashNewsTitle {
            display: block;
            width: 100%;
            margin-bottom: 8px;
            overflow: hidden;
            color: var(--pc-normal-color);
            font-weight: 500;
            font-family: PingFangSC-Medium;
            font-size: var(--pc-font-size-18);
            line-height: normal;
            white-space: normal;
            text-overflow: ellipsis;
            cursor: pointer;
            .overflow__hiding__one();

            &:hover {
                color: var(--pc-primary-color);
            }

            & > span {
                color: var(--pc-base-f7-color);
            }
        }

        .flashNewsIntrod {
            display: -webkit-box;
            overflow: hidden;
            color: var(--pc-visited-color);
            font-size: var(--pc-font-size-16);
            line-height: 150%;
            white-space: pre-wrap;
            text-overflow: ellipsis;
            word-break: keep-all;
            -webkit-line-clamp: 3;
            -webkit-box-orient: vertical;
        }

        .sourceUrl {
            display: flex;
            justify-content: flex-start;

            a {
                color: #1e80ff;
            }
        }

        .flashNewsMsg {
            position: relative;
            display: flex;
            align-items: center;
            justify-content: space-between;
            margin-top: 32px;
        }

        .flashNewsTag {
            display: flex;
            align-items: center;
            line-height: 16px;

            a {
                margin-right: 8px;
                color: var(--pc-base-f1-color);
                font-size: var(--pc-font-size-14);

                &:hover {
                    color: var(--pc-primary-color);
                }

                &.searchOriginal {
                    color: var(--pc-base-f7-color);
                }
            }
        }

        .flashNewsIcon {
            position: absolute;
            top: 50%;
            right: 0;
            display: flex;
            align-items: center;
            transform: translateY(-50%);
        }
    }
}

@media screen and (max-width: 768px) {
    .flashNewsDate {
        box-sizing: border-box;
    }

    .flashNewsItem {
        position: relative;
        padding: 16px;
        box-shadow: 0 0 16px 0 rgba(0, 0, 0, 0.05);

        &::after {
            position: absolute;
            bottom: 0;
            left: 0;
            width: 100%;
            height: 1px;
            background-color: var(--pc-line-background);
            content: '';
        }

        .flashNewsTimer {
            width: 40px;
            padding-top: 3px;
            padding-right: 8px;
            font-size: var(--pc-font-size-14);
        }

        .flashNewsCon {
            width: calc(100% - 48px);

            .flashNewsTitle {
                font-size: var(--pc-font-size-16);
            }

            .flashNewsIntrod {
                margin-bottom: 24px;
                font-size: var(--pc-font-size-14);
            }

            .flashNewsMsg {
                flex-wrap: wrap;
            }

            .flashNewsTag {
                a {
                    font-size: var(--pc-font-size-10);
                }
            }

            .flashNewsIcon {
                flex: 1;
                justify-content: flex-end;
            }
        }
    }
}
