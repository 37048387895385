.tagTitle {
    display: flex;
    align-items: center;
    gap: 8px;

    .text {
        font-size: 24px;
        height: 1.25;
        color: var(--color-text-primary);
        font-family: PingFangSC-Medium;
        font-size: 24px;
    }
}
