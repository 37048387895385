.main {
    position: relative;
    padding-left: 208px;

    .section {
        box-sizing: border-box;
        max-width: var(--pc-page-con-width);
        min-height: calc(100vh - 60px);
        margin: 0 auto;
        padding: 24px 0 40px;
    }
}
