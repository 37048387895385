.nav {
    width: 100%;
    height: 40px;
    overflow: hidden;
    transition: all 0.3s;
}

.nav_hide {
    display: none;
}

.list {
    display: flex;
    height: 100%;
    overflow: visible;
    // padding-left: 24px;
}

.list_item {
    width: auto !important;
    padding: 0 16px;
    color: #262626;
    cursor: pointer;
    user-select: none;

    &:hover,
    &.active {
        .aHref {
            color: var(--theme-color);
        }
    }

    &.active {
        .aHref {
            font-weight: 500;
            font-family: PingFangSC-Medium;

            &::after {
                position: absolute;
                bottom: 0;
                left: 0;
                width: 100%;
                height: 2px;
                background-color: var(--theme-color);
                border-radius: 1px 1px 0 0;
                animation: line_fun 0.2s ease-in-out 1 forwards;
                content: '';
            }
        }
    }

    .aHref {
        position: relative;
        display: flex;
        align-items: center;
        height: 100%;
        color: #262626;
        white-space: nowrap;
    }
}

@keyframes line_fun {
    from {
        bottom: -4px;
        opacity: 0.4;
    }

    to {
        bottom: 0;
        opacity: 1;
    }
}

.barkly_container {
    background-color: #12141c;
    .list_item {
        .aHref {
            color: #fff;
            &::after {
                background-color: #fff;
            }
        }
        &.active_bg {
            .aHref {
                color: #fff;
            }
        }
    }
}
