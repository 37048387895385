.code {
    color: var(--theme-color);
    cursor: pointer;
    font-size: 16px;
}

@media screen and (max-width: 768px) {
    .code {
        font-size: 14px;
    }
}
