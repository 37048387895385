.flashNewsList {
    margin-bottom: 12px;
}

.flashNewsListContent {
    padding: 15px 12px 28px;
    background-color: #fff;
    border-radius: 6px;
}

.flashNewsDate {
    width: 100%;
    margin-bottom: 12px;
    color: #262626;
    font-size: var(--pc-font-size-14);
    line-height: 22px;
    margin-left: 7px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    .flashNewsDate_icon {
        margin-right: 9px;
        display: inline-block;
        width: 14px;
        height: 14px;
        background-image: url(/img/newsFlash/flash_time.svg);
        background-size: cover;
    }
}

.timeline_item_box:first-child {
    margin-top: 16px !important;
    :global {
        .ant-timeline-item-tail {
            margin-top: -26px !important;
            height: calc(100% + 24px) !important;
        }
    }
}
.timeline_item_box:last-child {
    padding-bottom: 0 !important;
    .flashNewsItem {
        margin-bottom: 0;
    }
    :global {
        .ant-timeline-item-tail {
            display: block !important;
            height: calc(100% - 18px);
        }
    }
}

.timeline_item_box {
    :global {
        .ant-timeline-item-label {
            text-align: start !important;
            width: initial !important;
            font-size: 18px;
            font-weight: 500;
            font-family: PingFangSC-Medium;
            color: #262626;
        }

        .ant-timeline-item-tail {
            border-inline-start: dashed 1px #e5e5e5 !important;
        }

        .ant-timeline-item-head {
            width: 4px !important;
            height: 4px !important;
            border: none !important;
            background-color: #999999 !important;
            margin-top: 2px;
            margin-left: 2px;
        }

        .ant-timeline-item-content {
            margin-inline-start: 16px !important;
        }
    }
}

.flashNewsItem {
    position: relative;
    display: flex;
    justify-content: space-between;
    box-sizing: border-box;
    flex-direction: column;
    // margin-bottom: 20px;

    .flashNewsTimer {
        display: flex;
        justify-content: space-between;
        margin-bottom: 4px;
        span {
            font-size: var(--pc-font-size-14);
            white-space: pre;
            line-height: 22px;
            color: #262626;
        }
        .flashNewsIcon {
            display: flex;
            align-items: center;
        }
    }

    .flashNewsCon {
        width: calc(100%);

        .flashNewsTitle {
            display: block;
            width: 100%;
            margin-bottom: 4px;
            overflow: hidden;
            color: #262626;
            font-weight: 500;
            font-family: PingFangSC-Medium;
            font-size: var(--pc-font-size-14);
            text-align: left;
            cursor: pointer;
            line-height: 22px;

            & > span {
                color: var(--pc-base-f7-color);
            }
        }

        .flashNewsdesc_box {
            :global {
                span:nth-child(2) {
                    margin-right: 15px;
                }
            }
        }
        .flashNewsdesc_link {
            color: #2c36de;
            font-size: 14px;
            line-height: 22px;
            cursor: pointer;
        }

        .flashNewsIntrod {
            color: #666;
            font-size: var(--pc-font-size-12);
            line-height: 20px;
            margin-bottom: 4px;
        }

        .sourceUrl {
            height: 20px;
            font-size: var(--pc-font-size-12);
            line-height: 20px;
            .sourceUrl_icon {
                text-align: center;
                margin-right: 4px;
                width: 14px;
                height: 14px;
                display: inline-block;
                background-image: url(/img/newsFlash/img_flash_link.webp);
                background-size: cover;
            }

            &.sourceUrlNull {
                display: none;
            }

            a {
                font-size: 12px;
                list-style: 20px;
                text-align: left;
                display: flex;
                justify-content: center;
                align-items: center;
                color: #2c36de;
            }
        }

        .flashNewsMsg {
            display: flex;
            align-items: flex-end;
            justify-content: space-between;
        }
    }
}
