.header {
    position: fixed;
    top: 0;
    z-index: 1000;
    width: 100%;
    background-color: var(--pc-base-background);
}

.top_banner {
    min-height: 80px;
    width: 100%;
    position: relative;
    :global {
        .ant-image {
            img {
                min-height: 80px;
            }
        }
    }
}

.header_box {
    display: flex;
    align-items: center;
    justify-content: space-between;
    box-sizing: border-box;
    width: 100%;
    height: 72px;
}

// ----------------------------------------- left
.header_left {
    display: flex;
    align-items: center;
    min-width: 208px;
    height: 100%;
}

.logo {
    display: block;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 142px;
    height: 20px;
    margin-left: 24px;
    overflow: hidden;
    cursor: pointer;

    a {
        position: relative;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        height: 100%;
    }

    img {
        object-fit: contain !important;
    }
}

// ----------------------------------------- right
.header_right {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: calc(100% - 208px);
    height: 100%;
}

// ----------------------------------------- nav_con
.nav_con {
    display: flex;
    align-items: center;
    width: 100%;
    height: 100%;
    max-width: var(--pc-page-con-width);
    margin: 0 auto;
    padding: 0 24px;
}

.code_list {
    display: flex;
    margin-left: 32px;

    li,
    .down {
        display: flex;
        align-items: center;
        margin-right: 32px;
        color: #262626;
        font-size: var(--pc-font-size-14);
        white-space: nowrap;
        cursor: pointer;

        &:last-child {
            margin-right: 0;
        }

        .icon {
            margin-right: 4px;
            font-size: var(--pc-font-size-14);
            line-height: 1;
        }

        &:hover {
            color: var(--pc-primary-color);
        }
    }
}

.return_home {
    margin-left: 80px;
    overflow: hidden;
    color: #010b27;
    font-weight: normal;
    font-size: 16px;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: 0.5px;
    white-space: nowrap;
    text-overflow: ellipsis;
    cursor: pointer;
}

.lang__icon {
    display: none;
    margin-right: 14px;
}

.search__icon {
    position: relative;
    display: none;
    width: 20px;
    height: 20px;
    margin-right: 16px;
    color: #000000;
    font-size: 16px;
}

.app_header {
    .logo {
        margin-left: 0;
    }

    .header_box {
        justify-content: space-evenly;
    }

    .header_right {
        margin: 0;
        width: var(--pc-page-con-width);

        .container {
            margin: 0;
        }
    }
}
