.icpMsg {
    font-size: 12px;
    line-height: 200%;
    color: var(--pc-base-f1-color);
    padding: 24px 20px 24px 24px;
    border-top: 1px solid var(--pc-line-background);
    margin-top: 40px;

    * {
        margin: 0 !important;
        padding: 0 !important;
    }
    a {
        color: var(--pc-base-f1-color);

        &:hover {
            text-decoration: underline !important;
        }
    }
}
