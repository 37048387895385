.empty {
    width: 100%;
    height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin: 0 auto;
    text-align: center;
    padding-bottom: 20px;
    box-sizing: border-box;

    span {
        margin-top: 31px;
        font-size: var(--pc-font-size-14);
        color: var(--pc-base-f1-color);
    }
}

@media screen and (max-width: 628px) {
    .empty {
        width: 200px;

        img {
            width: 100%;
        }

        span {
            margin-top: 16px;
            white-space: nowrap;
        }
    }
}