.wrapper {
    inset: 0 !important;
}

.scroller {
    margin-right: -4px;
}

.track-y-props {
    top: 0 !important;
    height: 100% !important;
}

.thumb-y-props {
    display: inline-block;
}
