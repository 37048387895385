.menu {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 20px;
    height: 20px;
    cursor: pointer;

    ul {
        position: relative;
        width: 15px;
        height: 13px;

        li {
            position: absolute;
            left: 0;
            width: 100%;
            height: 1px;
            background: #000;
            transition: all 0.3s;

            &:nth-of-type(1) {
                top: 0;
            }

            &:nth-of-type(2) {
                top: 50%;
                transform: translateY(-50%);
            }

            &:nth-of-type(3) {
                bottom: 0;
            }
        }

        &.active {
            li {
                &:nth-of-type(1) {
                    transform: translateY(6px) rotate(-45deg);
                }

                &:nth-of-type(2) {
                    opacity: 0;
                }

                &:nth-of-type(3) {
                    transform: translateY(-6px) rotate(45deg);
                }
            }
        }
    }
}
