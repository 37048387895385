@logo-width: 238px;
@page-width: var(--wrapper-width);

.header {
    width: 100%;
}

.header_height {
    position: fixed;
    top: 0;
}

.header_top {
    width: 100%;
    position: fixed;
    top: 0;
    z-index: 1000;
}

.top_banner {
    :global {
        .ant-image {
            img {
                min-height: 80px;
            }
        }
    }
}

.header_box {
    display: flex;
    align-items: center;
    justify-content: space-between;
    box-sizing: border-box;
    width: 100%;
    height: 60px;
    padding: 0 16px;
    gap: 32px;
    position: relative;
    z-index: 9;
    background-color: var(--pc-base-background);
}

// ----------------------------------------- left
.header_left {
    display: flex;
    align-items: center;
    height: 100%;
    // padding-right: 16px;
    position: relative;
    z-index: 1;
}

.logo {
    width: 133px;
    height: 30px;
    margin-left: 24px;
    overflow: hidden;
    cursor: pointer;

    a {
        position: relative;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        height: 100%;
    }

    img {
        object-fit: contain !important;
    }
}

// ----------------------------------------- right
.header_right {
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: calc(100% - (100% - @page-width) / 2);
    height: 100%;
    position: relative;
    z-index: 0;
}

// ----------------------------------------- nav_con
.nav_con {
    display: flex;
    align-items: center;
    box-sizing: border-box;
    height: 100%;
}

.code_list {
    display: flex;
    height: 100%;
    position: relative;

    & > li,
    .down {
        display: flex;
        align-items: center;
        margin-right: 8px;
        color: #262626;
        font-size: var(--pc-font-size-14);
        white-space: nowrap;
        cursor: pointer;

        &:last-child {
            margin-right: 0;
        }

        .icon {
            margin-right: 8px;
            font-size: 16px;
            line-height: 1;
        }

        .name {
            font-size: 16px;
        }

        .down {
            line-height: 24px;
            padding: 7px 12px;
            border-radius: 4px;
            cursor: pointer;

            &:hover {
                background-color: #f7f7f8;
            }
        }
    }

    & > li {
        &:first-child {
            margin-left: 8px;
        }
    }
    & > li {
        &:nth-last-child(2) {
            margin-right: 16px;
        }
    }
}

.return_home {
    margin-left: 24px;
    overflow: hidden;
    color: #010b27;
    font-weight: normal;
    font-size: 16px;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: 0.5px;
    white-space: nowrap;
    text-overflow: ellipsis;
    cursor: pointer;
}

.lang__icon {
    display: none;
    margin-right: 16px;
}

.search__icon {
    position: relative;
    display: none;
    width: 20px;
    height: 20px;
    margin-right: 16px;
    color: #000000;
    font-size: 16px;
}

.app_header {
    .header_box {
        justify-content: space-between;
    }
}

.barkly_container {
    .header_box {
        background-color: #12141c;
    }

    .down {
        &:hover {
            background-color: rgba(0, 0, 0, 0.5) !important;
        }
        span {
            color: #fff;
        }

        .icon {
            color: #fff;
        }
    }
}
