// ------------- 超出省略 Start ------------- //
.maxLine(@x) {
    display: -webkit-box; //特别显示模式
    overflow: hidden; //溢出内容隐藏
    white-space: pre-wrap;
    text-overflow: -o-ellipsis-lastline;
    text-overflow: ellipsis; //文本溢出部分用省略号表示
    word-wrap: break-word;
    word-break: normal;
    overflow-wrap: anywhere;
    -webkit-line-clamp: @x; //行数
    line-clamp: @x;
    -webkit-box-orient: vertical; //盒子中内容竖直排列
}
// ------------- 超出省略 End ------------- //
.wrapper {
    flex: 1;
    background: #fff;
    border-radius: 0 0 6px 6px;
}
.wrapper__list {
    display: flex;
    flex-wrap: wrap;
    gap: 16px 16px;
}

.wrapper__article {
    display: block;
    flex-shrink: 0;
    width: calc((100% - 32px) / 3);
    height: 312px;
}
.wrapper__article.wrapper__noNewsFlash {
}
.wrapper__article:hover {
    .wrapper__title {
        color: #2c36de;
    }
    .wrapper__cover img {
        transform: scale(1.1);
        transition: all 0.5s;
    }
}
// ------------- 封面 Start ------------- //
.wrapper__cover {
    position: relative;
    width: 100%;
    height: 188px;
    overflow: hidden;
    border-radius: 6px;
}
// ------------- 封面 End ------------- //
// ------------- 标题 Start ------------- //
.wrapper__title {
    max-height: 78px;
    padding-top: 16px;
    color: #262626;
    font-weight: 500;
    font-family: PingFangSC-Medium;
    font-size: 18px;
    font-style: normal;
    font-stretch: normal;
    line-height: 26px;
    letter-spacing: normal;
    .maxLine(3);

    .top {
        display: inline-block;
        width: 19px;
        height: 19px;
        position: relative;
        top: 2px;
        margin-right: 8px;
    }

    h2 {
        display: inline;
        font-size: inherit;
        white-space: normal;
        font-weight: 500;
        font-family: PingFangSC-Medium;
    }
}
// ------------- 标题 End ------------- //
.wrapper__info {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    margin-top: 8px;
}
// ------------- 创作者 Start ------------- //
.wrapper__ugc {
    color: #262626;
    font-weight: normal;
    font-size: 14px;
    font-style: normal;
    font-stretch: normal;
    line-height: 22px;
    letter-spacing: normal;
    .maxLine(1);
}
// ------------- 创作者 End ------------- //
.wrapper__line {
    width: 1px;
    height: 12px;
    margin: 0 12px;
    background-color: #e5e5e5;
}

// ------------- 日期 Start ------------- //
.wrapper__timer {
    flex-shrink: 0;
    color: #999;
    font-weight: normal;
    font-size: 14px;
    font-style: normal;
    font-stretch: normal;
    line-height: 22px;
    letter-spacing: normal;
}
// ------------- 日期 End ------------- //
// ------------- 没有更多 Start ------------- //
.wrapper__noMore {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    margin-top: 50px;
    em {
        width: 213px;
        height: 1px;
        background-color: #e5e5e5;
    }
    span {
        padding: 0 12px;
        color: #999;
        font-weight: normal;
        font-size: 14px;
        font-style: normal;
        font-stretch: normal;
        line-height: 22px;
        letter-spacing: 0.07px;
    }
}
// ------------- 没有更多 End ------------- //
