.wrapper {
    position: absolute;
    padding: 2px 12px;
    background-color: #EBFCF9; 
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.5;
    letter-spacing: 0.5px;
    color: #00967c;
    border-radius: 0 100px 100px 0;
}
.leftTop {
    left: 0;
    top: 0;
}
.leftBottom {
    left: 0;
    bottom: 0;
}
.rightTop {
    right: 0;
    top: 0;
    border-radius: 100px 0 0 100px;
}
.rightBottom {
    right: 0;
    bottom: 0;
    border-radius: 100px 0 0 100px;
}

@media screen and (max-width: 768px) {
    .wrapper {
        font-size: 12px;
        padding: 2px 6px;
    }
}
