.menu {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 24px;
    height: 24px;
    cursor: pointer;

    ul {
        position: relative;
        width: 24px;
        height: 18px;

        li {
            position: absolute;
            left: 0;
            width: 100%;
            height: 2px;
            background: #000;
            // transition: all 0.3s;

            &:nth-of-type(1) {
                top: 0;
            }

            &:nth-of-type(2) {
                top: 50%;
                transform: translateY(-50%);
            }

            &:nth-of-type(3) {
                bottom: 0;
            }
        }
    }
}
