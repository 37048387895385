.brokerMenu {
    position: fixed;
    top: -100vh;
    left: 0;
    width: 100vw;
    height: calc(100vh - 45px);
    padding-bottom: 24px;
    opacity: 0;
    box-sizing: border-box;
    background-color: var(--pc-base-background);
    z-index: 1000;
    transition: all 0.2s;
    overflow-x: hidden;
    overflow-y: auto;
}

.brokerMenuOpen {
    opacity: 1;
}

.searchInputBox {
    padding: 12px 12px 0;
    position: sticky;
    top: 0;
    background-color: #ffffff;
    z-index: 1;

    .searchInput {
        padding: 0 16px;
        height: 36px;
        border-radius: 4px;
        border: solid 1px #ebebeb;
        background-color: #f5f5f5;
        caret-color: var(--theme-color);
        box-sizing: border-box;

        input {
            background-color: transparent;
            padding-left: 12px !important;
        }

        &:hover {
            border-color: var(--theme-color) !important;
        }

        .prefix {
            color: #999999;
        }

        .closeIcon {
            cursor: pointer;
            width: 16px;
            height: 16px;
            display: block;
            font-size: 16px !important;
            display: flex;
            align-items: center;
            justify-content: center;
            color: #cccccc;
            position: relative;
            top: 1px;
        }
    }

    :global {
        .ant-input-prefix {
            margin-right: 8px;
        }

        .ant-input-affix-wrapper-focused {
            border-color: var(--theme-color) !important;
            box-shadow: 0 0 0 2px rgba(44, 54, 222, 0.1) !important;
        }
    }
}

.swiperBox {
    margin-top: 12px;
}

.tabsItem {
    position: relative;
    width: auto;
    color: #999;
    font-size: 12px;
    line-height: 36px;

    &:last-child {
        margin-right: 0 !important;
    }

    &.active {
        color: var(--theme-color);
        font-size: 14px;
    }
}

.brokerMenuList {
    width: 100%;
    box-sizing: border-box;

    .selOption {
        display: flex;
        align-items: center;
        padding: 10px 12px;

        &:last-child {
            margin-bottom: 0;
        }

        .logo {
            width: 20px;
            height: 20px;
            margin-right: 12px;
            border-radius: 2px;
            overflow: hidden;
            box-sizing: border-box;
            position: relative;
            border: solid 1px #e5e5e5;
        }

        .text {
            line-height: 1.5;
            letter-spacing: 0.5px;
            color: #262f2d;
            flex: 1;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: pre;
        }

        .business__name {
            font-size: 14px;
            font-weight: normal;
            font-stretch: normal;
            font-style: normal;
            line-height: 1.5;
            letter-spacing: 0.5px;
            text-align: right;
            color: #00c6a4;
        }
    }

    .selOptionActive {
        background-color: #f7f7f8;
    }
}

.null_data {
    margin: 20% auto 0;
}
