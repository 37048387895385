.footer {
    font-size: 12px;
    line-height: 1.5;
    color: #999;
    padding: 24px 0 24px 208px;
    background: #fff;

    .info {
        margin: 0 auto;
        max-width: 920px;

        p {
            margin-bottom: 8px;

            &:last-child {
                margin: 0;
            }
        }
    }
}
