.swiperContain {
    :global {
        .swiper-pagination {
            bottom: 16px;
            height: 5px;
        }

        .swiper-button-disabled {
            cursor: pointer;
            opacity: 1;
            pointer-events: auto;
        }
    }

    height: 100%;
    overflow: hidden;
    -webkit-user-select: none;
    user-select: none;

    & > div {
        height: 100%;
    }

    .swiperBtnNext,
    .swiperBtnPrev {
        top: 50%;
        width: 38px;
        height: 38px;
        object-fit: contain;
        background-color: rgba(255, 255, 255, 0.1);
        // box-shadow: 0 0 8px 0 rgba(0, 0, 0, 0.2);
        // opacity: 0;
        border-radius: 50%;

        &::after {
            color: var(--pc-base-background);
            font-weight: 400;
            font-size: 12px;
        }
    }

    .swiperBtnNext {
        right: 16px;
    }

    .swiperBtnPrev {
        left: 16px;
    }

    &:hover {
        .swiperBtnNext,
        .swiperBtnPrev {
            background-color: #fff;
            box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.25);
            &::after {
                color: #171617;
            }
        }
    }
}

.swiperCon {
    overflow: hidden;
    // border-radius: 4px;
    :global {
        .swiper-pagination-horizontal {
            display: flex;
            align-items: center;
            // justify-content: flex-start;
            justify-content: center;
            padding-left: 24px;
        }
    }
}

.swiperSlideBox {
    position: relative;
    width: 100%;
    height: 100%;
}

.swiperSlide {
    box-sizing: border-box;
    height: 100%;
}

.swiperBullet {
    // position: relative;
    // bottom: 12px;
    display: inline-block;
    width: 28px;
    height: 2px;
    margin-right: 4px;
    background: rgba(255, 255, 255, 0.4);
    border-radius: 20px;
    cursor: pointer;

    &:last-child {
        margin-right: 0;
    }
}

.swiperBulletActive {
    position: relative;
    // bottom: 12px;
    overflow: hidden;

    &::after {
        position: absolute;
        top: 0;
        left: 0;
        width: 0;
        height: 100%;
        background-color: #fff;
        border-radius: 1px;
        animation: progressMove linear 5s 1 forwards;
        content: '';
    }
}

.swiperSlideTitle:hover {
    // height: 294px;
    // max-height: 294px;
    // padding-top: 0;
    // padding-bottom: 42px;
    // .swiperSlideTitleText {
    //     display: block;
    // }
}

.swiperSlideTitle {
    position: absolute;
    bottom: 0;
    left: 0;
    z-index: 1;
    box-sizing: border-box;
    width: 100%;
    min-height: 80px;
    max-height: 148px;
    // max-height: 108px;

    padding: 34px 24px 16px;
    color: var(--pc-base-background);
    font-weight: 500;
    font-family: PingFangSC-Medium;
    font-size: var(--pc-font-size-24);
    background-image: linear-gradient(to bottom, rgba(0, 0, 0, 0) 0%, #000);
    cursor: pointer;
    transition: max-height 0.5s;

    .swiperSlideTitleText {
        display: -webkit-box;
        box-sizing: border-box;
        min-height: 32px;
        max-height: calc(100% - 24px);
        // max-height: 70px;
        // margin: 16px 24px 26px;
        margin-bottom: 30px;
        overflow: hidden;
        line-height: 140%;
        letter-spacing: 0.2px;
        white-space: pre-wrap;
        text-overflow: ellipsis;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        text-align: justify;
    }

    .swiperSlideTitleOne {
        margin: 22px 24px;
        line-height: 150%;
    }
}

@media screen and (max-width: 998px) {
    .swiperSlideTitle {
        min-height: 60px;
        max-height: 148px;
        font-size: var(--pc-font-size-16);

        .swiperSlideTitleText {
            min-height: 28px;
            max-height: 70px;
            // margin: 12px 0 20px;
            margin-bottom: 20px;
        }

        .swiperSlideTitleOne {
            margin: 12px;
            line-height: 150%;
        }
    }
}

@keyframes progressMove {
    0% {
        width: 0;
    }

    100% {
        width: 100%;
    }
}

@media screen and (max-width: 768px) {
    .swiperContain {
        height: 238px !important;
        // margin-top: 12px;
    }
    .swiperCon {
        background-color: #d9d9d9;
        border-radius: 4px;
        .swiperBtnNext,
        .swiperBtnPrev {
            display: none;
        }
        :global {
            .swiper-pagination-horizontal {
                bottom: 12px;
                display: flex;
                align-items: center;
                justify-content: center;
                padding-left: 0;
            }
        }
    }
    .swiperSlideTitle {
        // min-height: 60px;
        max-height: 86px;
        padding: 35px 16px 12px 12px;
        color: #fff;
        font-size: var(--pc-font-size-16);

        .swiperSlideTitleText {
            min-height: 28px;
            max-height: 70px;
            font-size: var(--pc-font-size-16);
            line-height: 1.63;
            // margin-bottom: 20px;
            -webkit-line-clamp: 1;
        }

        .swiperSlideTitleOne {
            margin: 12px;
            line-height: 150%;
        }
    }
}
