.switch {
    display: flex;
    align-items: center;
}

.switch_btn {
    width: 20px;
    height: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 12px;
    box-shadow:
        0 2px 4px -1px rgba(0, 0, 0, 0.12),
        0 4px 5px 0 rgba(0, 0, 0, 0.08),
        0 1px 10px 0 rgba(0, 0, 0, 0.05);
    background-color: #fff;
    margin-right: 12px;
    cursor: pointer;
    color: #262626;

    &:last-child {
        margin-right: 0;
    }

    &.disabled {
        color: #c6c6c6;
    }

    .icon {
        font-size: 10px;
        line-height: 1;
    }

    .left {
        transform: rotateY(180deg);
    }
}
