@import '~@/styles/utils.less';

.li {
    display: flex;
    align-items: center;
}

.li .logo_box {
    position: relative;
    width: 154px;
    height: 102px;
    margin-right: 16px;
    background-color: #ffffff;
    border-radius: 4px;
    overflow: hidden;
}

.li .logo {
    position: relative;
    width: 100%;
    height: 100%;
    border: solid 1px #e5e5e5;
    overflow: hidden;
    border-radius: 4px;
    box-sizing: border-box;
}

.li .logo img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.li .info {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: calc(100% - 166px);
    height: 102px;
}

.li .info .info_top {
    width: 100%;
    margin: 0;
}

.li .info .info_top p {
    margin: 0;
}

.li .info .info_top .name {
    width: 100%;
    margin-bottom: 4px;
    color: #262626;
    font-weight: 500;
    font-family: PingFangSC-Medium;
    font-size: 18px;
    line-height: 1.44;
    .maxLine(1);
    word-break: break-all;

    &:hover {
        color: var(--theme-color);
    }
}

.li .info .info_top .score {
    display: flex;
    align-items: center;

    span:first-child {
        position: relative;
        display: inline-block;
        margin-right: 8px;
        padding: 0 1px;
        color: #262626;
        font-weight: 500;
        font-family: PingFangSC-Medium;
        font-size: 22px;
        line-height: 1;

        strong {
            position: relative;
            z-index: 1;
            font-family: D-DIN, sans-serif;
        }

        &::after {
            position: absolute;
            bottom: 0;
            left: 0;
            width: 100%;
            height: 6px;
            background-color: #ffa216;
            border-radius: 3px;
            content: '';
        }
    }

    span:last-child {
        color: #999;
        font-size: 14px;
        line-height: 1.57;
    }
}

.li .tag_list {
    width: 100%;
    display: flex;
    align-items: center;

    .tags {
        display: flex;
        align-items: center;

        span {
            display: inline-block;
            height: 26px;
            margin-right: 8px;
            padding: 0 10px;
            color: #666;
            line-height: 26px;
            background-color: #f5f5f5;
            border-radius: 4px;
            white-space: pre;
        }
    }

    .license {
        display: block;
        color: #999;
        background-color: transparent;
        overflow: hidden;
        white-space: pre;
        text-overflow: ellipsis;

        a {
            color: #999;
        }
    }

    .del {
        font-size: 14px;
        line-height: 1.57;
        color: #999;
        padding-left: 40px;

        &:hover {
            color: var(--theme-color);
        }
    }
}
