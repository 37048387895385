.main {
    position: relative;
    // min-height: calc(100vh - 45px);
    background-color: transparent;
    flex: 1;
}

.section {
    padding: 0 12px 24px;
}
