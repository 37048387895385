.footer {
    width: 100%;
    margin-top: 16px;
    padding: 60px 0 40px;
    background-color: #262933;
    position: relative;
    z-index: 998;
}

.contain {
    max-width: 1376px;
    margin: 0 auto;
}

.risk_tip,
.icp_msg {
    font-size: 16px;
    line-height: 1.5;
    word-break: break-word;
    color: rgba(255, 255, 255, 0.8);

    div,
    p {
        min-height: 24px;
        margin-bottom: 0;

        &:last-child {
            margin: 0;
        }

        a {
            color: #fff;
            font-weight: 500;
            font-family: PingFangSC-Medium;
        }

        span {
            color: rgba(255, 255, 255, 0.5);
        }
    }
}

.icp_msg {
    margin-top: 24px;
}
