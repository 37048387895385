.menu {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 20px;
    height: 20px;
    cursor: pointer;

    ul {
        position: relative;
        width: 15px;
        height: 13px;

        li {
            position: absolute;
            left: 0;
            width: 100%;
            height: 1px;
            background-color: #000;

            &:nth-of-type(1) {
                top: 0;
            }

            &:nth-of-type(2),
            &:nth-of-type(3) {
                top: 50%;
                transition: all 0.3s;
                transform: translateY(-50%);
                transform-origin: center center;
            }

            &:nth-of-type(4) {
                bottom: 0;
            }
        }

        &.active {
            li {
                &:nth-of-type(1),
                &:nth-of-type(4) {
                    opacity: 0;
                }

                &:nth-of-type(2) {
                    width: 18px;
                    transform: rotate(45deg);
                }

                &:nth-of-type(3) {
                    width: 18px;
                    transform: rotate(-45deg);
                }
            }
        }
    }
}
