.drop_btn {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 8px;
    border-radius: 8px;

    .drop_name {
        position: relative;
        z-index: 2;
        min-width: 30px;
        margin-right: 4px;
        overflow: hidden;
        color: #333;
        font-size: 14px;
        line-height: 1.57;
        letter-spacing: 0.5px;
        white-space: nowrap;
        text-overflow: ellipsis;
    }

    &.drop_btn_open {
        position: relative;
        background-color: var(--pc-base-background);
        border-radius: 8px 8px 0 0;
    }
}

.drop_menu {
    position: fixed;
    bottom: -200%;
    left: 0;
    z-index: 1010;
    width: 100vw;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.5);
    opacity: 0;

    &.drop_menu_open {
        bottom: 0;
        opacity: 1;

        .drop_list {
            bottom: 0;
        }
    }

    .drop_list {
        padding-top: 24px;
        position: absolute;
        left: 0;
        bottom: -200%;
        z-index: 1;
        width: 100%;
        height: 40%;
        max-height: 304px;
        overflow: hidden;
        border-radius: 12px 12px 0 0;
        background-color: #fff;
        transition: bottom 0.3s;
    }

    .drop_list ul {
        width: 100%;
        height: 100%;
        overflow-y: auto;
    }

    .drop_list li {
        display: flex;
        align-items: center;
        height: 40px;
        padding: 0 24px;
        color: #262626;
        line-height: 1.57;

        &.active {
            font-weight: 500;
            font-family: PingFangSC-Medium;
            background-color: #f7f7f8;
        }
    }

    .drop_modal {
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
    }

    .drop_close {
        width: 16px;
        height: 16px;
        position: absolute;
        right: 16px;
        top: 16px;
        z-index: 2;
    }
}
