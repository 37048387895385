.mobile {
    display: flex;
    flex-direction: column;
    align-items: center;

    .form {
        width: 100%;

        input::-webkit-outer-spin-button,
        input::-webkit-inner-spin-button {
            -webkit-appearance: none !important;
        }

        input[type='number'] {
            appearance: textfield;
            -moz-appearance: textfield;
        }

        .email,
        .pwd {
            padding: 12px 16px !important;
        }
        .icon {
            width: 22px;
            height: 22px;
        }

        :global {
            .ant-input,
            .ant-input-password {
                &:hover,
                &:focus,
                &:focus-within {
                    border-color: var(--theme-color);
                    box-shadow: none;
                }
            }
            .ant-input-affix-wrapper {
                border-radius: 4px;
                padding: 12px 16px !important;

                &:hover {
                    border-color: var(--theme-color);
                }
            }

            .ant-input-group-addon {
                border: none;
                padding: 4px 0 4px 24px;
            }
        }

        input {
            &::placeholder {
                color: #999;
            }
        }
    }

    .prefix {
        display: flex;
        align-items: center;
        color: #262b2e;
        font-size: 16px;

        i {
            margin-right: 4px;
        }
    }

    .btn {
        color: #262f2d !important;
        height: 48px !important;
        border-radius: 4px !important;
        padding: 0 !important;
        letter-spacing: 0.5px;
        font-size: 16px !important;
        color: var(--invalid-name) !important;
        background-color: var(--theme-color);

        &:disabled {
            background-color: var(--btn-disabled-color);
            border-color: var(--btn-disabled-color);
        }
    }
}
