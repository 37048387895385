@import '~@/styles/utils.less';

.dropDownBox {
    width: 208px;
    height: 30px;
    text-wrap: nowrap;
}
.select__list {
    padding: 0 !important;
    :global {
        .ant-select-item {
            padding: 0 !important;
        }
        .ant-select-item.ant-select-item-option-selected {
            background-color: var(--invalid-name) !important;
        }
    }
}
// ----------------------- 列表项 Start -----------------------
.select__list__item {
    display: flex;
    padding: 10px 12px;
    align-items: center;
    justify-content: flex-start;
    &:hover {
        background-color: #f5f5fa;
    }
    .frame {
        display: inline-block;
        flex-shrink: 0;
        width: 14px;
        height: 14px;
        opacity: 0.9;
        border-radius: 2px;
        box-sizing: border-box;
        border: solid 1px #999;
    }
    .label {
        .maxLine(1);
        margin-left: 8px;
        flex: 1;
        font-size: 14px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: 20px;
        letter-spacing: normal;
        color: #000;
    }
}
// ----------------------- 列表项 End -----------------------
