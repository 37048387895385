@import '~@/styles/utils.less';

.before {
    color: #2c36de !important;
    border-color: #2c36de !important;
}

.progressing {
    color: #2c36de !important;
    border-color: #2c36de !important;
}

.end {
    color: #999999 !important;
    border-color: #999999 !important;
}
.wrapper {
    background: #fff;
    padding: 0 16px;
    box-sizing: border-box;
    min-height: 181px;
    width: 100%;
    display: block;
    cursor: pointer;
    transition: all 0.2s;
    user-select: none;
    overflow: hidden;

    &:hover {
        background-color: var(--list-hover-background);
        .title {
            color: #2c36de;
        }
    }
}

.listItem {
    display: flex;
    padding: 24px 0;
    .synopsis {
        font-size: 14px;
        letter-spacing: 0.07px;
        margin-top: 4px;
        color: #666666;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 2;
    }
}

.listItemCover {
    position: relative;
    width: 224px;
    height: 149px;
    border-radius: 4px;
    overflow: hidden;
    &:hover {
        img {
            transform: scale(1.1);
            transition: all 0.5s;
        }
    }
}

.listItemContent {
    display: flex;
    flex: 1;
    flex-direction: column;
    justify-content: space-between;
    padding-left: 16px;
}

.title {
    display: -webkit-box; //特别显示模式
    box-sizing: border-box;
    height: 26px;
    overflow: hidden; //溢出内容隐藏
    color: #262b2e;
    font-weight: 500;
    font-family: PingFangSC-Medium;
    font-size: 18px;
    line-height: 1.5;
    text-overflow: -o-ellipsis-lastline;
    text-overflow: ellipsis; //文本溢出部分用省略号表示
    word-break: normal;
    -webkit-line-clamp: 1; //行数
    line-clamp: 1;
    -webkit-box-orient: vertical; //盒子中内容竖直排列
}

.time {
    display: flex;
    align-items: center;
    color: #999;
    gap: 8px;
    font-size: 14px;
}

.btns {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.countdown {
    flex-shrink: 0;
    padding: 7px 10px;
    color: #333;
    font-size: 14px;
    line-height: 14px;
    min-width: 99px;
    box-sizing: border-box;
    text-align: center;
    background-color: #fff;
    border: 1px solid #e9e9e9;
    border-radius: 4px;
}

.status {
    position: relative;
    display: inline-block;
    box-sizing: border-box;
    padding: 6px 10px;
    color: #0ec370;
    font-weight: 500;
    font-family: PingFangSC-Medium;
    font-size: 14px;
    line-height: 14px;
    text-align: right;
    border: solid 1px;
    border-radius: 14px;

    i {
        font-size: 16px;
    }
}

.city {
    display: flex;
    align-items: center;
    color: #999;
    font-size: 12px;
    gap: 6px;
    i {
        margin-right: 3px;
        color: #999;
        font-size: 16px;
        line-height: 1;
    }
    span {
        .maxLine(1);
    }
}
// ---------------------------- 小布局 Start ----------------------------
.small {
    height: max-content;
    min-height: auto;
    max-height: auto;
    // 图片
    .listItemCover {
        width: 148px;
        height: 98px;
    }
    // 内容容器
    .listItemContent {
        padding: 0 0 0 12px;
    }
    // 标题
    .title {
        font-size: 16px;
        height: 24px;
    }
    .synopsis {
        -webkit-line-clamp: 1;
    }
    // 简介
    .time,
    .city {
        font-size: 12px;
    }
}
// ---------------------------- 小布局 End ----------------------------
