@import url('~@/styles/utils.less');
@page-width: var(--wrapper-width);

.wrapper__container {
    flex: 1;
    width: 100%;
    height: 100%;
    overflow: hidden;
}

.wrapper__default {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    height: 100%;
}
.wrapper__title {
    margin-left: calc((100vw - @page-width) / 2 - 237px);
    display: flex;
    align-items: center;
    box-sizing: border-box;
    max-width: 1028px;
    height: 100%;
    color: #262843;
    font-weight: 500;
    font-family: PingFangSC-Medium;
    font-size: 32px;
    line-height: 60px;
    letter-spacing: 0.5px;
    opacity: 0;
    .maxLine(1);
}
.wrapper__default,
.wrapper__title {
    transition: all 0.3s;
}

.roll {
    .wrapper__default {
        margin-top: -60px;
        opacity: 0;
    }
    .wrapper__title {
        opacity: 1;
    }
}

.barkly_container {
    .wrapper__title {
        color: #fff;
    }
}
