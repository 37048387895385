.articleContain {
    width: 100%;
    margin: 0 auto;
}

.articleList {
    display: grid;
    grid-column-gap: 24px;

    & > div {
        width: 100% !important;
        overflow: hidden;
    }

    &.listOne {
        grid-template-columns: repeat(1, 1fr);
    }

    &.listTwo {
        grid-template-columns: repeat(2, 1fr);
    }

    &.listThree {
        grid-template-columns: repeat(3, 1fr);
    }

    &.listFour {
        grid-template-columns: repeat(4, 1fr);
    }
}

.articleItem {
    position: relative;
    box-sizing: border-box;
    // width: 278px;
    margin-bottom: 24px;
    overflow: hidden;
    background-color: var(--pc-base-background);
    border-radius: 4px;
    box-shadow: 0 0 16px 0 rgba(0, 0, 0, 0.05);
    transition: all 0.2s;

    &:hover {
        transform: translateY(-4px);
    }

    & > a {
        display: block;
    }

    .articleCover {
        position: relative;
        z-index: 1;
        display: block;
        width: 100%;
        height: 164px;
        overflow: hidden;
        background-color: var(--pc-line-background);
        border-bottom: 1px solid var(--pc-border-color);
        border-radius: 4px 4px 0 0;
        cursor: pointer;

        .videoPlay {
            position: absolute;
            top: 50%;
            left: 50%;
            z-index: 2;
            display: block;
            color: var(--pc-base-background);
            font-size: 32px;
            transform: translate(-50%, -50%);
            opacity: 0;
        }
    }

    .articleCon {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        box-sizing: border-box;
        height: 175px;
        padding: 24px 16px;
    }

    .articleTitle {
        display: block;
        display: -webkit-box;
        overflow: hidden;
        color: var(--pc-normal-color);
        font-weight: 500;
        font-family: PingFangSC-Medium;
        font-size: var(--pc-font-size-18);
        line-height: var(--pc-line-height-a);
        letter-spacing: 0.5px;
        white-space: pre-wrap;
        text-overflow: ellipsis;
        word-break: normal;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;

        &:hover {
            color: var(--pc-primary-color);
        }

        label {
            cursor: pointer;
        }

        h2 {
            display: inline;
            font-size: inherit;
            white-space: normal;
            font-weight: 500;
            font-family: PingFangSC-Medium;
        }

        .top,
        .origin {
            position: relative;
            top: -2px;
            display: inline-block;
            box-sizing: border-box;
            min-width: 48px;
            height: 24px;
            margin-right: 4px;
            margin-bottom: 2px;
            padding: 0 4px;
            font-weight: normal;
            font-size: var(--pc-font-size-14);
            line-height: 24px;
            text-align: center;
            border-radius: 2px;
        }

        .top {
            color: var(--pc-base-f7-color);
            border: 1px solid var(--pc-base-f7-color);
        }

        .margin {
            margin-right: 8px;
        }

        .origin {
            margin-right: 8px;
            color: var(--pc-primary-color);
            border: 1px solid var(--pc-primary-color);
        }
    }

    .articleMsg {
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        justify-content: space-between;
        color: var(--pc-visited-color);
        font-size: var(--pc-font-size-14);

        .msga {
            max-width: 100%;
            margin-top: 8px;
            margin-right: 16px;
            overflow: hidden;
            color: var(--pc-normal-color);
            font-weight: 500;
            font-family: PingFangSC-Medium;
            white-space: nowrap;
            text-overflow: ellipsis;
        }

        .icon {
            max-width: 100%;
            margin-right: 4px;
            overflow: hidden;
            color: var(--pc-base-f1-color);
            line-height: normal;
            white-space: nowrap;
            text-overflow: ellipsis;
        }

        & > div {
            display: flex;
            flex: 1;
            align-items: flex-end;
            justify-content: space-between;
            min-width: 120px;
            margin-top: 8px;
        }

        .msgc {
            display: flex;
            align-items: center;
            letter-spacing: 1px;
        }
    }
}

.typeOne {
    .articleCon {
        height: 160px;
        padding-top: 8px;
    }
}

.typeTwo {
    .articleCover {
        position: relative;

        &::after {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            background-color: rgba(0, 0, 0, 0.4);
            opacity: 0;
            content: '';
        }

        &:hover {
            &::after {
                opacity: 1;
            }

            .videoPlay {
                opacity: 1;
            }
        }
    }
}

@media screen and (max-width: 415px) {
    .articleList {
        // display: block;
        background-color: var(--pc-base-background);
    }

    .articleItem {
        width: 100%;
        margin-bottom: 0;
        padding: 16px 0;
        border-radius: 0;
        box-shadow: none;
        transition: none;

        &:hover {
            transform: translateY(0);
        }

        &::after {
            position: absolute;
            bottom: 0;
            left: 0;
            width: 100%;
            height: 1px;
            background-color: var(--pc-line-background);
            content: '';
        }

        & > a {
            display: flex;
            align-items: center;
            justify-content: space-between;

            &:visited {
                .articleCon {
                    .articleTitle {
                        color: var(--pc-visited-color);
                    }
                }
            }

            &:hover {
                .articleCon {
                    .articleTitle {
                        color: var(--pc-normal-color);
                    }
                }
            }
        }

        .articleCover {
            position: relative;
            z-index: 1;
            display: block;
            box-sizing: border-box;
            width: 106px;
            height: 68px;
            margin-right: 8px;
            overflow: hidden;
            border: 1px solid var(--pc-line-background);
            border-bottom: none;
            border-radius: 5px;
            cursor: pointer;

            &::after {
                display: none;
            }

            .videoPlay {
                font-size: 24px;
            }
        }

        .articleCon {
            display: flex;
            flex: 1;
            flex-direction: column;
            justify-content: space-between;
            box-sizing: border-box;
            max-width: 100%;
            height: auto;
            padding: 0;
        }

        .articleTitle {
            font-weight: 500;
            font-family: PingFangSC-Medium;
            font-size: var(--pc-font-size-16);

            label {
                cursor: pointer;
            }

            .top,
            .origin {
                width: 40px;
                height: 18px;
                font-size: var(--pc-font-size-12);
                line-height: 18px;
            }

            .margin {
                margin-right: 4px;
            }

            .origin {
                margin-right: 4px;
            }
        }

        .articleMsg {
            font-size: var(--pc-font-size-10);

            .icon {
                margin-right: 2px;
                font-size: var(--pc-font-size-12);
            }

            & > div {
                position: relative;
                flex: 1;
                // top: 1px;
            }
        }
    }

    .typeOne {
        .articleCon {
            height: auto;
            min-height: 68px;
            padding: 0;
        }
    }

    .typeTwo {
        .articleCover {
            .videoPlay {
                font-size: 24px;
            }
        }
    }
}
