.flash_box {
    box-sizing: border-box;
    width: 100%;
    margin-top: 12px;
    // margin-bottom: 12px;
    padding: 12px 12px 13px;
    background-color: var(--pc-base-background);
    border-radius: 6px;
}

.top_box {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 24px;
    margin-bottom: 8px;

    .top_title {
        z-index: 9;
        color: #000;
        font-weight: 500;
        font-family: PingFangSC-Medium;
        font-size: 16px;
        line-height: 1.5;
        text-align: left;
    }
    .top_link {
        display: flex;
        align-items: center;
        justify-content: center;
        color: #999;
        font-size: 12px;
        line-height: 1.5;
        text-align: left;
        cursor: pointer;
    }
    .top_link::after {
        margin-left: 6px;
        color: #999 !important;
        font-weight: 500;
        font-family: PingFangSC-Medium;
        font-size: 10px;
        /* stylelint-disable-next-line font-family-no-missing-generic-family-keyword */
        font-family: swiper-icons;
        font-variant: normal;
        font-variant: initial;
        line-height: 1;
        letter-spacing: 0;
        text-align: center;
        text-transform: none !important;
        content: 'next';
    }
}

.flash_list {
    .flash_item a {
        display: -webkit-box;
        overflow: hidden;
        color: #262626;
        font-size: 14px;
        line-height: 1.57;
        white-space: pre-wrap;
        text-align: left;
        text-overflow: ellipsis;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
    }
}
