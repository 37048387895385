.video-js {
    border-radius: 8px;
    overflow: hidden;
    // min-height: 454px;
}

.vjs-poster {
    background-color: transparent;
}

/* 中间大的播放按钮 */
.video-js .vjs-big-play-button {
    width: 70px !important;
    height: 70px !important;
    margin-left: -35px !important;
    margin-top: 0 !important;
    background-image: url('../../public/img/video/play.webp') !important;
    background-size: cover !important;
    border: none !important;
    background-color: transparent !important;
    transform: translateY(-50%);
    z-index: 3;

    .vjs-icon-placeholder {
        &::before {
            content: '' !important;
        }
    }
}

.video-js .vjs-big-play-button .vjs-icon-placeholder:before {
    color: #717272;
}

.vjs-ended .vjs-big-play-button {
    /* 视频暂停时显示播放按钮 */
    display: block;
}

.vjs-button:focus,
.vjs-progress-holder:focus {
    outline: none !important;
    border-color: transparent !important;
    box-shadow: none !important;
}

.vjs-time-control {
    display: block !important;
    padding: 0 !important;
    min-width: auto !important;
    line-height: 30px !important;
}

.vjs-control-bar {
    height: 40px !important;
    margin-bottom: 16px !important;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    // background: linear-gradient(180deg, rgba(27, 33, 44, 0) 0%, rgba(27, 33, 44, 0.8) 100%) !important;
}

.vjs-control-bar > div,
.vjs-control-bar > button {
    height: 30px !important;
}

.video-js-more {
    border-radius: 0 !important;
}

.video-js-more .vjs-big-play-button {
    display: none !important;
}

.video-js-more .vjs-control-bar {
    height: 50px !important;
    background: linear-gradient(180deg, rgba(27, 33, 44, 0) 0%, rgba(27, 33, 44, 0.8) 100%) !important;
}

.video-js-more .vjs-control-bar .vjs-volume-panel,
.video-js-more .vjs-control-bar .vjs-fullscreen-control {
    width: 40px;
    height: 30px;
}

// --------- 控件栏
.video-js .vjs-control-bar {
    background-color: transparent !important;
    flex-wrap: wrap !important;
    justify-content: flex-start !important;
    height: auto !important;
}

// 时间
.video-js .vjs-duration,
.video-js .vjs-current-time {
    font-size: 16px !important;
    line-height: 30px !important;
    padding: 0 !important;
}

.video-js .vjs-current-time {
    text-align: left !important;
    padding-left: 40px !important;
}
// 时间分割斜杠
.vjs-time-divider {
    min-width: 8px !important;
    text-align: center !important;
    font-size: 16px !important;
}

.video-js .vjs-duration {
    text-align: left !important;
    flex: 1;
}

// ------------ 进度条 Start ------------
// 已播放进度条
.vjs-play-progress.vjs-slider-bar {
    background: #fff !important;
}
.video-js .vjs-progress-control .vjs-progress-holder .vjs-play-progress.vjs-slider-bar:before {
    font-size: 16px !important;
    top: 0px !important;
}

.video-js .vjs-progress-control {
    flex-basis: 100% !important;

    // 未播放进度
    .vjs-slider {
        background-color: rgba(255, 255, 255, 0.2) !important;
        height: 6px !important;
    }

    .vjs-progress-holder {
        margin: 0 16px !important;

        // 已播放进度
        .vjs-play-progress {
            &:before {
                color: #fff !important;
                font-size: 22px !important;
                top: -6px !important;
            }
        }

        .vjs-load-progress {
            background-color: rgba(255, 255, 255, 0.2) !important;

            div {
                background-color: rgba(255, 255, 255, 0.2) !important;
            }
        }
    }
}
// ------------ 进度条 End ------------

// 左下角按钮
.video-js .vjs-play-control {
    // flex: auto !important;
    // flex-basis: 5% !important;

    .vjs-icon-placeholder {
        // 播放按钮
        text-align: left !important;
        margin-left: 16px !important;
        width: 24px !important;
        height: 24px !important;
        background-size: cover !important;

        &:before {
            content: '' !important;
        }
    }
    // 播放时
    &.vjs-playing .vjs-icon-placeholder {
        background-image: url('../../public/img/video/pause.webp') !important;
    }
    // 暂停时
    &.vjs-paused .vjs-icon-placeholder {
        background-image: url('../../public/img/video/play-small.webp') !important;
    }
    // 结束时
    &.vjs-ended .vjs-icon-placeholder {
        background-image: url('../../public/img/video/play-small.webp') !important;
    }

    .vjs-icon-placeholder:before {
        position: initial !important;
        font-size: 28px !important;
        line-height: 28px !important;
    }
}

// 音量
.video-js .vjs-volume-panel {
    flex: auto !important;
    justify-content: flex-end !important;
    margin-bottom: 5px !important;

    .vjs-vol-0 .vjs-icon-placeholder {
        background: url('../../public/img/video/icon_jingyin.webp');
        background-size: cover;
    }

    .vjs-icon-placeholder {
        background: url('../../public/img/video/icon_youshengyin.webp');
        background-size: cover;
        width: 22px !important;
        height: 22px !important;

        &::before {
            content: '' !important;
        }
    }
}

// 全屏
.video-js .vjs-fullscreen-control {
    width: 24px !important;
    height: 24px !important;
    margin-left: auto !important;
    margin-right: 16px !important;
    background-image: url('../../public/img/video/icon_quanping.webp');
    background-size: cover;

    .vjs-icon-placeholder {
        &::before {
            content: '' !important;
        }
    }
}
.vjs-fullscreen.video-js .vjs-fullscreen-control {
    background-image: url('../../public/img/video/icon_no_quanping.webp');
}
