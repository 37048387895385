.flashNews {
    width: 278px;
    padding: 30px 16px 24px;
    margin-bottom: 24px;
    border-radius: 8px;
    box-sizing: border-box;
    position: relative;
    overflow: hidden;
    box-shadow: 0 0 16px 0 rgba(0, 0, 0, 0.05);
    background-image: linear-gradient(to bottom, #fff8f2, #fff 38%, #fff 100%);
}

.watermark {
    display: block;
    width: 111px;
    height: 76px;
    position: absolute;
    top: 0;
    left: 0;
    border-radius: 8px 0 76px 0;
    z-index: 0;
    background-image: linear-gradient(38deg, #fffcfa 20%, #ffedde 68%);
}

.flashNewsCon {
    position: relative;

    .flashNewsTit {
        display: flex;
        align-items: center;

        span {
            display: block;

            &:nth-of-type(1) {
                width: 48px;
                height: 24px;
                margin-right: 6px;
                color: var(--pc-base-background);
                font-size: var(--pc-font-size-14);
                font-style: oblique;
                background-color: var(--pc-base-f5-color);
                border-radius: 12px 12px 0 12px;
                text-align: center;
                line-height: 24px;
                user-select: none;
            }

            &:nth-of-type(2) {
                margin-right: 8px;
                font-size: 24px;
                font-weight: 500;
                font-family: PingFangSC-Medium;
                line-height: 24px;
                padding-bottom: 2px;
                color: var(--pc-normal-color);
            }
        }
    }

    .flashNewsList {
        li {
            a {
                display: flex;
                align-items: flex-start;
                position: relative;
                padding: 16px 0;
                color: var(--pc-normal-color);

                &::after {
                    content: '';
                    position: absolute;
                    bottom: 0;
                    left: 0;
                    width: 100%;
                    height: 1px;
                    background-color: var(--pc-normal-hover-color);
                }

                .itemTimer {
                    width: 40px;
                    height: 22px;
                    padding-right: 8px;
                    font-size: var(--pc-font-size-16);
                    white-space: pre;
                }

                .itemContain {
                    width: calc(100% - 48px);
                    line-height: var(--pc-line-height-a);
                    font-size: var(--pc-font-size-16);
                    cursor: pointer;
                    white-space: pre-wrap;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    display: -webkit-box;
                    -webkit-line-clamp: 3;
                    -webkit-box-orient: vertical;

                    &:hover {
                        color: var(--pc-primary-color);
                    }
                }
            }

            &:first-child {
                a {
                    color: var(--pc-base-f5-color);

                    .itemContain {
                        &:hover {
                            color: var(--pc-base-f5-color);
                        }
                    }
                }
            }

            &:last-child {
                a {
                    &::after {
                        display: none;
                    }
                }
            }
        }
    }

    .loadMore {
        width: 100%;
        height: 40px;
        display: block;
        text-align: center;
        line-height: 40px;
        background-color: var(--pc-base-f3-color);
        font-size: var(--pc-font-size-14);
        letter-spacing: 0.6px;
        color: var(--pc-visited-color);
        cursor: pointer;

        &:hover {
            color: var(--pc-primary-color);
        }
    }
}

@media screen and (max-width: 628px) {
    .flashNewsBox {
        box-sizing: border-box;
        // background-color: var(--pc-base-background);
    }

    .flashNewsTit {
        margin: 0 16px 8px;
        top: 0;
        padding: 12px;

        span {
            display: block;

            &:nth-of-type(1) {
                width: 40px;
                height: 20px;
                margin-right: 4px;
                font-size: var(--pc-font-size-10);
                font-style: oblique;
                background-color: var(--pc-base-f5-color);
                border-radius: 10px 10px 0 10px;
                line-height: 20px;
            }

            &:nth-of-type(2) {
                font-size: 20px;
            }
        }

        &::after {
            height: 2px;
        }

        &::before {
            height: 2px;
        }
    }

    .tabs {
        margin-bottom: 16px;
        // border-bottom: 1px solid var(--pc-base-background);
    }
}
