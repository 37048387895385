.custom_modal {
    :global {
        .ant-modal-content {
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            min-height: 120px;
            padding: 24px !important;
            box-sizing: border-box;
            border-radius: 9px;

            .ant-modal-title {
                font-size: 14px;
                font-weight: 500;
                font-family: PingFangSC-Medium;
                font-stretch: normal;
                font-style: normal;
                line-height: 1.57;
                letter-spacing: normal;
                text-align: left;
                color: rgba(0, 0, 0, 0.9);
            }

            .ant-modal-footer {
                display: grid;
                grid-gap: 22px;
                grid-template-columns: repeat(2, calc(50% - 11px));

                button {
                    height: 32px;
                    box-sizing: border-box;
                    line-height: 32px;
                    padding: 5px 16px;
                    border-radius: 3px;
                    background-color: #e7e7e7;
                    font-size: 14px;
                    font-weight: normal;
                    font-stretch: normal;
                    font-style: normal;
                    line-height: 22px;
                    letter-spacing: normal;
                    color: #262626;
                    border: none;
                    margin-inline-start: 0 !important;

                    &:hover {
                        border: none;
                        background-color: #e7e7e7;
                        color: #262626;
                    }

                    &:last-child {
                        background-color: #2c36de;
                        color: rgba(255, 255, 255, 0.9);

                        &:hover {
                            background-color: var(--btn-hover-color);
                            color: var(--invalid-name);
                        }
                    }
                }
            }
        }
    }
}

// 标题
.title__wrapper {
    display: flex;
    align-items: flex-start;

    .warning__icon {
        width: 20px;
        height: 20px;
        flex-shrink: 0;
        margin-right: 6px;
        position: relative;
        line-height: 1.57;
    }

    p {
        overflow: hidden;
        font-size: 14px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.57;
        letter-spacing: normal;
        color: rgba(0, 0, 0, 0.85);
    }
}
