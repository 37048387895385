.loadMore {
    // cursor: pointer;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    box-sizing: border-box;
    width: 100%;
    height: 40px;
    margin: 48px auto 0;
    padding-left: 50px;

    /**
    *   Bear
    *   1. 去除上横向线
    * */

    // &::before {
    //     position: absolute;
    //     top: 0;
    //     left: 0;
    //     width: 100%;
    //     height: 1px;
    //     background-color: var(--pc-line-background);
    //     content: '';
    // }

    span {
        color: var(--pc-normal-color);
        font-size: var(--pc-font-size-14);
        letter-spacing: 0.44px;
        -webkit-user-select: none;
        user-select: none;
    }

    ol {
        display: flex;
        margin-left: 5px;
        list-style: none;
        opacity: 0;
        @animateTime: 0.45s;

        li {
            width: 4px;
            height: 4px;
            margin: 0 6px;
            background-color: var(--theme-color);
            border-radius: 50%;
            animation: scale @animateTime infinite alternate;
            list-style-type: none !important;
        }

        li:nth-child(2) {
            animation-delay: @animateTime * (1 / 3);
        }

        li:nth-child(3) {
            animation-delay: @animateTime * (2 / 3);
        }
    }

    @keyframes scale {
        from {
            transform: scale(1);
        }

        to {
            transform: scale(1.75);
        }
    }
}

.loadMoreLoading {
    span {
        color: var(--theme-color);
    }

    ol {
        opacity: 1;
    }
}

.loadMoreLoaded {
    padding: 0 16px;

    &::before {
        display: none;
    }
    i {
        width: 213px;
        height: 1px;
        background-color: #e5e5e5;
    }

    & i:first-child {
        margin-right: 12px;
    }

    & i:last-child {
        margin-left: 12px;
    }

    span {
        color: var(--pc-base-f1-color);
        text-wrap: nowrap;
    }
}

.error {
    color: var(--pc-normal-color);
    font-size: var(--pc-font-size-14);
    text-align: center;
    cursor: pointer;
}

.empty {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: var(--invalid-name);
    margin: auto;
    text-align: center;
    height: 100%;
    border-radius: 6px;
    .empty__img {
        width: 128px;
        height: 122px;
        position: relative;
    }

    span {
        margin-top: 31px;
        color: var(--pc-base-f1-color);
        font-size: var(--pc-font-size-14);
    }
}
// ---------- 暗黑模式 Start ----------
.empty.empty__barkly_container {
    background-color: transparent;
}
// ---------- 暗黑模式 End ----------

.pcLoadMore {
    box-sizing: border-box;
    width: 220px;
    height: 44px;
    border: 1px solid #2c36de;

    margin: 38px auto 0;
    color: #2c36de;
    font-size: 16px;
    line-height: 42px;
    letter-spacing: 0.2px;
    text-align: center;
    background-color: transparent;
    border-radius: 4px;
    cursor: pointer;
    // &:hover {
    //     color: var(--theme-color);
    // }
}

@media screen and (max-width: 768px) {
    .loadMoreLoaded {
        padding: 0;
    }
    .loadMore {
        margin: 24px auto 0;
        padding-left: 0;

        &::before {
            display: none;
        }
    }
    .pcLoadMore {
        width: auto;
        height: auto;
        border: none;
        margin: 24px auto 0;
        line-height: 14px;
        font-size: 14px;
    }
}

@media screen and (max-width: 628px) {
    .loadMoreLoaded {
        i {
            width: 100px;
        }
    }
    .empty {
        .empty__img {
            width: 80px;
            height: 76.2px;
        }
        img {
            width: 100%;
        }

        span {
            margin-top: 12px;
            font-size: 14px;
            font-weight: normal;
            font-stretch: normal;
            font-style: normal;
            line-height: 22px;
            letter-spacing: 0.07px;
            color: #999;
        }
    }
}
.pcLoadMore.barkly_container {
    border: 1px solid #fff;
    color: #fff;
}
@media screen and (max-width: 768px) {
    .pcLoadMore.barkly_container {
        border: none;
        color: #fff;
        text-decoration: underline;
    }
}
