.no_more {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 42px;

    span:first-child,
    span:last-child {
        width: 16%;
        height: 1px;
        background-color: #e5e5e5;
    }

    span:nth-of-type(2) {
        margin: 0 12px;
        color: #999;
        line-height: 1.57;
        letter-spacing: 0.07px;
    }
}
