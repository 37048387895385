.mobile {
    display: flex;
    flex-direction: column;
    align-items: center;

    .form {
        width: 100%;

        input::-webkit-outer-spin-button,
        input::-webkit-inner-spin-button {
            -webkit-appearance: none !important;
        }

        input[type='number'] {
            appearance: textfield;
            -moz-appearance: textfield;
        }

        .pwd {
            padding: 12px 16px !important;
        }

        :global {
            .ant-input-affix-wrapper {
                border-radius: 4px;
                padding: 12px 16px !important;
                &:hover,
                &:focus,
                &:focus-within {
                    border-color: var(--theme-color);
                    box-shadow: none;
                }
            }

            .ant-input-group-addon {
                border: none;
                background-color: #fff;
                padding: 0 16px;
            }
        }

        input {
            outline: none;
            color: #262b2e;

            &::placeholder {
                color: #999;
            }
        }

        .mobileInput {
            height: 48px;
            border: var(--pc-primary-border-1);
            border-radius: 4px;
            &:hover,
            &:focus,
            &:focus-within {
                border-color: var(--theme-color);
                box-shadow: none;
            }

            :global {
                .ant-input-group-addon {
                    height: 46px !important;
                    padding: 0;
                    border: none;
                }

                .ant-select {
                    height: 100% !important;
                    margin: 0 !important;
                }

                .ant-select-selector,
                .ant-input-affix-wrapper {
                    height: 46px !important;
                    border: none !important;
                    box-shadow: none !important;

                    input {
                        height: 100% !important;
                    }
                }

                .ant-select-selection-item {
                    height: 100% !important;
                    line-height: 46px !important;
                    padding: 0;
                    font-size: 16px;
                    color: var(--color-text-explain);
                }
            }

            svg {
                color: var(--color-text-explain);
            }

            input {
                border: none;
                box-shadow: none;

                &:focus {
                    outline: none;
                    border: none;
                    box-shadow: none;
                }
            }
        }
    }

    .btn {
        color: #262f2d !important;
        height: 48px !important;
        border-radius: 4px !important;
        padding: 0 !important;
        letter-spacing: 0.5px;
        font-size: 16px !important;
        color: var(--invalid-name) !important;
        background-color: var(--theme-color);

        &:disabled {
            background-color: var(--btn-disabled-color);
            border-color: var(--btn-disabled-color);
        }
    }
}

.phone_code {
    position: relative;

    &::after {
        content: '';
        position: absolute;
        top: 50%;
        right: 0;
        width: 1px;
        height: 16px;
        background-color: #999;
        transform: translateY(-50%);
    }
}
