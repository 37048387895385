@import url('~@/styles/utils.less');

.wrapper {
    padding-top: 16px;
}

.breadcrumbs {
    margin: 0 auto;
    display: flex;
    align-items: center;
    gap: 6px;
    height: 22px;
}

.breadcrumb_itme {
    display: flex;
    align-items: center;
    gap: 6px;
    height: 22px;
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.57;
    letter-spacing: normal;
    color: var(--color-text-explain);
    .title {
        .maxLine(1);
        max-width: 139px;
        height: 22px;
        cursor: pointer;
        color: var(--color-text-explain);
        &:hover {
            color: var(--theme-color);
        }
    }
    span {
        font-size: 10px;
    }
    &.active {
        .title {
            .font__bold();
            color: var(--color-text-primary);
        }
        span {
            display: none;
        }
    }
}
.breadcrumbs.showBarkly .breadcrumb_itme {
    color: rgba(255, 255, 255, 0.5);

    .title {
        color: rgba(255, 255, 255, 0.5);
        &:hover {
            color: rgba(255, 255, 255, 1);
        }
    }
    &.active .title {
        color: rgba(255, 255, 255, 1);
    }
}
