.mobile {
    display: flex;
    flex-direction: column;
    align-items: center;

    .form {
        width: 400px;

        :global {
            .ant-input-affix-wrapper {
                padding: 12px 16px;
            }

            .ant-input-group-addon {
                border: none;
                padding: 4px 0 4px 24px;
            }
        }
    }

    .prefix {
        display: flex;
        align-items: center;
        color: #262b2e;
        font-size: 16px;

        i {
            margin-right: 4px;
        }
    }

    .btn {
        color: #262f2d !important;
        height: 48px !important;
        border-radius: 4px !important;
        padding: 0 !important;
        letter-spacing: 0.5px;
        font-size: 16px !important;
        color: var(--invalid-name) !important;
        background-color: var(--theme-color);

        &:disabled {
            background-color: var(--btn-disabled-color);
            border-color: var(--btn-disabled-color);
        }
    }

    .val {
        margin: 48px 0 12px;
        color: #262b2e;
        font-size: 14px;
    }
}
