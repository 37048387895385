.wrapper__menus {
    max-width: 1376px;
    margin: 0 auto 40px;
}
// ------------------- 通用 start -------------------
.aHref {
    display: inline-block;
    color: inherit;
    &:hover {
        color: inherit;
    }
}

// ------------------- 通用 end -------------------

// ------------------- 导航 start -------------------
.main__menus {
    display: flex;
}
.left__main {
    flex: 1;
    overflow: hidden;
    opacity: 0;
}
.wrapper__left__menus {
    width: fit-content;
    display: grid;
    grid-template-columns: repeat(9, min-content);
    grid-gap: 40px 60px;
}

.wrapper__label {
    flex-shrink: 0;
}
.top__label {
    text-wrap: nowrap;
    font-size: 18px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.44;
    letter-spacing: normal;
    text-align: left;
    color: #fff;
}

.wrapper__children {
    margin-top: 12px;
}
.item__label {
    text-wrap: nowrap;
    font-size: 14px;
    font-weight: 300;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.71;
    letter-spacing: normal;
    text-align: left;
    color: rgba(255, 255, 255, 0.6);
    &:hover {
        color: #fff;
    }
}
// ------------------- 导航 end -------------------
// ------------------- 固定重点页面 start -------------------
.right__main {
    flex-shrink: 0;
}
.right__main .label__right {
    display: block;
    font-size: 16px;
    margin-top: 20px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.5;
    letter-spacing: normal;
    color: var(--invalid-name);
    &:nth-child(1) {
        margin-top: 0;
    }
}
// ------------------- 固定重点页面 end -------------------

// ------------------- 线 start -------------------
.line__main,
.line {
    display: block;
    opacity: 0.3;
    background: #fff;
}
.line__main {
    width: 1px;
    margin: 0 90px 16px;
    flex-shrink: 0;
}

// 底部线
.line {
    height: 1px;
    max-width: 1376px;
    margin: 39px auto 40px;
}
// ------------------- 线 end -------------------
