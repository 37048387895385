.menuDrawer {
    position: fixed;
    top: -100vh;
    left: 0;
    z-index: 1000;
    box-sizing: border-box;
    width: 100vw;
    overflow: hidden auto;
    background-color: var(--pc-base-background);
    // height: 100vh;
    opacity: 0;
    transition: all 0.2s;

    &::-webkit-scrollbar {
        width: 4px;
        height: 4px;
    }

    &::-webkit-scrollbar-thumb {
        background: var(--pc-normal-hover-color);
        border-radius: 2px;
    }

    &::-webkit-scrollbar-track {
        background: transparent;
    }
}

.menuOpen {
    opacity: 1;
    // top: 0;
}

.menuList {
    box-sizing: border-box;
    width: 100%;
    padding: 0 0 8px;

    li {
        box-sizing: border-box;
        width: 100%;
        padding: 0 32px;
        // border-bottom: 1px solid var(--pc-line-background);

        span {
            display: block;
            width: 100%;
            height: 42px;
            overflow: hidden;
            color: #262626;
            font-size: var(--pc-font-size-14);
            line-height: 42px;
            white-space: nowrap;
            text-overflow: ellipsis;
        }

        &.active {
            background-color: #f0f0f0;
        }
    }
}

.login_box {
    width: 100%;
    padding: 12px;
    box-sizing: border-box;
    position: sticky;
    top: 0;
    z-index: 1;
    background-color: #fff;
}

.go_login {
    width: 100%;
    height: 38px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 4px;
    border: solid 1px #e5e5e5;
    cursor: pointer;
    color: #262626;
    box-sizing: border-box;

    .icon {
        line-height: 1;
        margin-left: 2px;
        font-size: 14px;
        color: #262626;
    }
}

.child_list {
}

.child_item {
    padding: 0 32px 0 48px;
    height: 40px;
    line-height: 40px;
    color: #666;

    &.s_active {
        color: #262626;
        background-color: #f7f7f8;
    }
}

// 菜单
.menus {
    .icon {
        font-size: 14px;
        transform: rotateZ(90deg);
    }

    .open {
        transform: rotateZ(-90deg);
    }

    :global {
        .ant-menu-item {
            color: #262626;
        }

        .ant-menu-submenu .ant-menu-item {
            color: #666;
        }

        .ant-menu-item,
        .ant-menu-submenu-title {
            width: 100% !important;
            margin: 0 !important;
            padding: 0 32px !important;
            height: 40px !important;
            line-height: 40px !important;
            user-select: none;
            border-radius: 0 !important;

            &:hover {
                background: transparent !important;
            }
        }

        .ant-menu-sub > .ant-menu-item {
            padding-left: 48px !important;
        }

        .ant-menu-item-active,
        .ant-menu-item-selected,
        .ant-menu-submenu-selected > .ant-menu-submenu-title {
            color: #262626 !important;
            background: transparent !important;
        }

        .ant-menu-sub.ant-menu-inline {
            background: transparent !important;
        }

        // .ant-menu-item-active,
        // .ant-menu-submenu-active {
        //     background: #f7f7f8 !important;
        // }

        .ant-menu-item-selected,
        .ant-menu-submenu-selected > .ant-menu-submenu-title {
            font-weight: 500;
            font-family: PingFangSC-Medium;
        }
    }
}

// .collapse {
//     .icon {
//         font-size: 14px;
//         transform: rotateZ(90deg);
//     }

//     .open {
//         transform: rotateZ(-90deg);
//     }

//     .f_active {
//         background-color: #f7f7f8;
//     }

//     :global {
//         .ant-collapse-header {
//             color: #262626;
//             padding: 9px 32px !important;
//         }

//         a {
//             color: #262626;
//         }

//         .ant-collapse-content-box {
//             padding: 0 !important;
//         }

//         .ant-collapse-item-active .ant-collapse-header:last-child {
//             background-color: #f7f7f8;
//         }
//     }
// }

// 菜单底部跳转
.list {
    margin: 12px 32px 0;
    border-top: 1px solid #e5e5e5;
    padding: 12px 0 24px;
    position: sticky;
    bottom: 0;
    background-color: #fff;

    li {
        height: 40px;
        line-height: 40px;
        cursor: pointer;

        a {
            color: #262626;
        }
    }
}
