.nav_box {
    width: 100%;
    height: 0;
    transition: all 0.2s;
    overflow: hidden;
    background-color: #f5f5fa;
    position: fixed;
    padding: 0 16px;
    box-sizing: border-box;
    top: 60px;
    z-index: 998;

    &.nav_box_show {
        height: 60px;
    }
}

.nav {
    max-width: 1376px;
    height: 100%;
    overflow: hidden;
    transition: all 0.3s;
    margin: 0 auto;
    background-color: #f5f5fa;
}

.nav_hide {
    display: none;
}

.list {
    display: flex;
    height: 100%;
}

.list_item {
    width: auto !important;
    padding: 0 40px;
    color: #262626;
    font-size: 16px;
    cursor: pointer;
    user-select: none;
    position: relative;

    &::after {
        content: '';
        position: absolute;
        top: 50%;
        right: 0;
        transform: translateY(-50%);
        width: 1px;
        height: 16px;
        background-color: #e5e5e5;
    }

    &:first-child {
        padding-left: 0;
    }

    &:last-child {
        &::after {
            display: none;
        }
    }

    &:hover,
    &.active {
        .aHref {
            color: var(--theme-color);
        }
    }

    &.active {
        .aHref {
            font-weight: 500;
            font-family: PingFangSC-Medium;
        }
    }

    .aHref {
        position: relative;
        display: flex;
        align-items: center;
        height: 100%;
        color: #262626;
        white-space: nowrap;
    }
}

@keyframes line_fun {
    from {
        bottom: -4px;
        opacity: 0.4;
    }

    to {
        bottom: 0;
        opacity: 1;
    }
}
