.wrapper {
    position: relative;
    box-sizing: border-box;
    width: 100%;
    min-width: 920px;
    min-height: 100vh;
    background-color: #f5f5fa;
}

.container {
    display: flex;
}
.barkly_container {
    background-color: #0c0d13;
}
