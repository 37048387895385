@menu-height: 56px;

.menu_box {
    position: fixed;
    top: 0;
    left: 0;
    overflow: hidden;
    z-index: 1000;
    box-sizing: border-box;
    width: 320px;
    height: 100vh;
    list-style-type: none;
    background-color: #fff;
    border-top: 1px solid #f5f5fa;
    transform: translateX(-320px);
    transition: transform 0.2s;
    box-shadow:
        0 5px 5px -3px rgba(0, 0, 0, 0.1),
        0 8px 10px 1px rgba(0, 0, 0, 0.06),
        0 3px 14px 2px rgba(0, 0, 0, 0.05);

    &:hover {
        .recScrollThumbY {
            background: #ebebeb !important;
        }
    }

    &.menu_login {
        padding-top: 16px;
    }
}

.recScrollTrackY {
    width: 4px !important;
    background: transparent !important;
}

.recScrollThumbY {
    width: 4px !important;
    background: transparent !important;
}

.menu_open {
    transform: translateX(0);
}

// 顶部登录按钮
.item_login {
    width: 320px;
    box-sizing: border-box;
    padding: 16px 24px;
    position: sticky;
    top: 0;
    z-index: 1;
    background-color: #fff;

    .go_login {
        width: 100%;
        height: 38px;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 4px;
        cursor: pointer;
        color: var(--color-text-primary);
        box-sizing: border-box;
        background-color: var(--page-primary-background);
    }

    .icon {
        line-height: 1;
        margin-left: 2px;
        font-size: 14px;
        color: #262626;
    }

    .go_login:hover {
        color: var(--theme-color);
        border-color: var(--theme-color);

        .icon {
            color: var(--theme-color);
        }
    }
}

.menu {
    width: 320px;
    list-style-type: none;
    box-sizing: border-box;
}

// 菜单
.menus {
    width: 100%;

    .icon {
        font-size: 14px;
        transform: rotateZ(90deg);
    }

    .open {
        transform: rotateZ(-90deg);
    }

    :global {
        .ant-menu-item {
            color: #262626;
        }

        .ant-menu-submenu .ant-menu-item {
            color: #666;
        }

        .ant-menu-item,
        .ant-menu-submenu-title {
            width: 100% !important;
            margin: 0 !important;
            padding: 0 24px !important;
            height: @menu-height !important;
            line-height: @menu-height !important;
            font-size: 16px;
            user-select: none;
            border-radius: 0 !important;

            &:hover {
                background: #f7f7f8 !important;
            }
        }

        .ant-menu-sub > .ant-menu-item {
            padding-left: 48px !important;
        }

        .ant-menu-item-active,
        .ant-menu-item-selected,
        .ant-menu-submenu-selected > .ant-menu-submenu-title {
            color: #262626 !important;
            background: transparent !important;

            &:hover {
                background: #f7f7f8 !important;
            }
        }

        .ant-menu-sub.ant-menu-inline {
            background: transparent !important;
        }

        // .ant-menu-item-active,
        // .ant-menu-submenu-active {
        //     background: #f7f7f8 !important;
        // }

        .ant-menu-item-selected,
        .ant-menu-submenu-selected > .ant-menu-submenu-title {
            font-weight: 500;
            font-family: PingFangSC-Medium;
        }
    }
}

// 菜单底部跳转
.list_box {
    width: 320px;
    box-sizing: border-box;
    padding: 12px 24px;
    position: sticky;
    bottom: 0;
    z-index: 1;
    background-color: #fff;
}

.list {
    border-top: 1px solid #e5e5e5;
    padding: 12px 0;

    li {
        height: @menu-height;
        line-height: @menu-height;
        font-size: 16px;
        cursor: pointer;

        a {
            color: #262626;
        }

        &:hover {
            a {
                color: var(--theme-color);
            }
        }
    }
}
