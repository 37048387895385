.loadMore {
    // cursor: pointer;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    box-sizing: border-box;
    width: 100%;
    height: 40px;
    margin: 48px auto 0;
    padding-left: 50px;

    &::before {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 1px;
        background-color: var(--pc-line-background);
        content: '';
    }

    span {
        color: var(--pc-normal-color);
        font-size: var(--pc-font-size-14);
        letter-spacing: 0.44px;
        -webkit-user-select: none;
        user-select: none;
    }

    ol {
        display: flex;
        margin-left: 5px;
        list-style: none;
        opacity: 0;
        @animateTime: 0.45s;

        li {
            width: 4px;
            height: 4px;
            margin: 0 6px;
            background-color: var(--pc-primary-color);
            border-radius: 50%;
            animation: scale @animateTime infinite alternate;
        }

        li:nth-child(2) {
            animation-delay: @animateTime * (1 / 3);
        }

        li:nth-child(3) {
            animation-delay: @animateTime * (2 / 3);
        }
    }

    @keyframes scale {
        from {
            transform: scale(1);
        }

        to {
            transform: scale(1.75);
        }
    }
}

.loadMoreLoading {
    span {
        color: var(--pc-primary-color);
    }

    ol {
        opacity: 1;
    }
}

.loadMoreLoaded {
    span {
        color: var(--pc-base-f1-color);
    }
}

.error {
    color: var(--pc-normal-color);
    font-size: var(--pc-font-size-14);
    text-align: center;
    cursor: pointer;
}

.empty {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 0 auto;
    padding-top: 100px;
    text-align: center;

    span {
        margin-top: 31px;
        color: var(--pc-base-f1-color);
        font-size: var(--pc-font-size-14);
    }
}

.pcLoadMore {
    box-sizing: border-box;
    width: 220px;
    height: 44px;
    border: 1px solid #2c36de;

    margin: 38px auto 0;
    color: #2c36de;
    font-size: 16px;
    line-height: 42px;
    letter-spacing: 0.2px;
    text-align: center;
    background-color: transparent;
    border-radius: 4px;
    cursor: pointer;
    // &:hover {
    //     color: var(--pc-primary-color);
    // }
}

@media screen and (max-width: 768px) {
    .loadMore {
        margin: 24px auto 0;
        padding-left: 0;

        &::before {
            display: none;
        }
    }
    .pcLoadMore {
        width: auto;
        height: auto;
        border: none;
        margin: 24px auto 0;
        line-height: 14px;
        font-size: 14px;
    }
}

@media screen and (max-width: 628px) {
    .empty {
        width: 200px;
        margin-top: 50px;
        padding: 0 16px;

        img {
            width: 100%;
        }

        span {
            margin-top: 16px;
            white-space: nowrap;
        }
    }
}

.pcLoadMore.barkly_container {
    border: 1px solid #fff;
    color: #fff;
}
@media screen and (max-width: 768px) {
    .pcLoadMore.barkly_container {
        border: none;
        color: #fff;
        text-decoration: underline;
    }
}
