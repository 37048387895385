@import '~@/styles/utils.less';

.wrapper {
    display: flex;
    &:hover {
        .title {
            color: var(--theme-color);
        }
    }
}
.cover {
    flex-shrink: 0;
    width: 100px;
    height: 100px;
    position: relative;
    margin-right: 16px;
    border-radius: 4px;
    overflow: hidden;
}

.info__wrapper {
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 8px;
}
.title {
    .maxLine(1);
    font-size: 18px;
    font-weight: 500;
    font-family: PingFangSC-Medium;
    font-stretch: normal;
    font-style: normal;
    line-height: 26px;
    letter-spacing: normal;
    text-align: left;
    color: #262626;
}
.introduction {
    .maxLine(3);
    height: 66px;
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 22px;
    letter-spacing: normal;
    text-align: left;
    color: #666;
}

.focus {
    margin: auto 0;
    margin-left: 16px;
    flex-shrink: 0;
    padding: 8px 16px;
    border-radius: 4px;
    height: 34px;
    box-sizing: border-box;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    gap: 2px;
    padding: 8px 16px;
    border-radius: 4px;
    font-size: 16px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 18px;
    letter-spacing: -0.41px;
    text-align: center;
    color: var(--color-text-primary);
    background-color: #f5f5f5;
    cursor: pointer;

    picture {
        width: 14px;
        height: 14px;
    }
    img {
        width: 14px;
        height: 14px;
    }
    span {
        flex-shrink: 0;
    }

    &.not {
        background-color: var(--theme-color);
        color: var(--invalid-name);
        &:hover {
            background-color: var(--btn-hover-color);
        }
    }
}
