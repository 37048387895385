.textOverMin {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
}

.packup {
    display: flex;
    flex-direction: column;
    box-sizing: border-box;
    width: 130px;
    height: 100%;
    background-color: #fff;
}

.header {
    box-sizing: border-box;
    height: 30px;
    padding: 2px 8px 8px;

    .openBtn {
        width: 20px;
        height: 20px;
        cursor: pointer;
    }
}

.topTip {
    padding: 16px 4px;
    text-align: center;
    background-image: linear-gradient(to right, rgba(191, 191, 191, 0.2) 0%, rgba(242, 241, 241, 0.2) 100%);

    p {
        .textOverMin();
    }

    p:first-child {
        margin-bottom: 2px;
        color: #333;
        font-weight: 500;
        font-family: PingFangSC-Medium;
        font-size: 18px;
        font-family:
            '微软雅黑',
            -apple-system,
            Arial,
            sans-serif;
        line-height: 1.44;
    }

    p:last-child {
        margin-bottom: 0;
        color: #666;
        font-size: 12px;
    }
}

.contrast {
    padding: 24px 0 40px;

    .contrastBtnBox {
        display: flex;
        justify-content: center;
        margin-top: 24px;
    }

    .contrastBtn {
        min-width: 90px;
        height: 34px;
        color: #010b27;
        font-size: 14px;
        line-height: 34px;
        letter-spacing: 0.5px;
        text-align: center;
        background-color: #00e0b9;
        border-radius: 4px;
        cursor: pointer;

        &.noData {
            color: #999;
            background-color: #ebebeb;
            cursor: not-allowed;
        }
    }

    .contrastText {
        display: flex;
        align-items: center;
        justify-content: center;
        box-sizing: border-box;
        width: 100%;
        height: 100%;
        padding: 0 6px;
        color: #262f2d;
        letter-spacing: 0.5px;
    }

    .contrastTextHover {
        &:hover {
            background-color: rgba(0, 0, 0, 0.1);
        }
    }

    .clearBox {
        display: flex;
        align-items: center;
        justify-content: center;
        margin-top: 12px;
    }

    .clearBtn {
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;

        span {
            color: #999;
        }

        & > span:last-child {
            font-size: 12px;
            letter-spacing: 0.43px;
        }
    }

    .clearBtnHover {
        &:hover {
            span {
                color: var(--pc-primary-color);
            }
        }
    }

    .clearIcon {
        margin-right: 4px;
        font-size: 16px;
        line-height: 1;
    }
}

.contrastList {
    & > li {
        margin-bottom: 24px;

        .logo {
            position: relative;
            display: flex;
            align-items: center;
            justify-content: center;
            box-sizing: border-box;
            width: 54px;
            height: 54px;
            margin: 0 auto;
            background-color: #f7f8fb;
            border: solid 1px #ebebeb;
            border-radius: 27px;
            cursor: pointer;
        }

        .img {
            border-radius: 27px;
        }

        .name {
            margin-top: 4px;
            overflow: hidden;
            color: #333;
            line-height: 1.57;
            white-space: nowrap;
            text-align: center;
            text-overflow: ellipsis;
            cursor: pointer;
        }

        .closeIcon {
            position: absolute;
            top: -4px;
            right: 0;
            display: none;
            color: #999;
            font-size: 16px;
            line-height: 1;
        }

        .logo:hover {
            .closeIcon {
                display: inline-block;
            }
        }
    }
}

.selBroker {
    .addBtn {
        display: flex;
        align-items: center;
        justify-content: center;
        box-sizing: border-box;
        width: 54px;
        height: 54px;
        margin: 0 auto;
        background-color: #f7f8fb;
        border: solid 1px #ebebeb;
        border-radius: 27px;
        cursor: pointer;
    }

    .addIcon {
        color: #979797;
        font-size: 13px;
        line-height: 1;
    }

    .tip {
        margin-top: 4px;
        color: #999;
        font-size: 14px;
        line-height: 1.57;
        text-align: center;
    }
}

.midTip {
    .textOverMin();

    height: 60px;
    padding: 0 4px;
    color: #333;
    font-weight: 500;
    font-family: PingFangSC-Medium;
    font-size: 18px;
    line-height: 60px;
    text-align: center;
    background-image: linear-gradient(to right, rgba(191, 191, 191, 0.2) 0%, rgba(242, 241, 241, 0.2) 100%);
}

.recBox {
    position: relative;
    flex: 1;
    box-sizing: border-box;
    width: 100%;

    &:hover {
        .recScrollThumbY {
            background: #ebebeb !important;
        }
    }
}

.recScrollTrackY {
    width: 4px !important;
    background: transparent !important;
}

.recScrollThumbY {
    width: 4px !important;
    background: transparent !important;
}

.recList {
    display: flex;
    flex-direction: column;
    padding-bottom: 40px;

    li {
        position: relative;
        display: flex;
        flex-direction: column;
        align-items: center;
        height: 70px;
        margin: 24px auto 0;
    }

    .logo {
        display: block;
        box-sizing: border-box;
        width: 54px;
        height: 54px;
        overflow: hidden;
        border: solid 1px #ebebeb;
        border-radius: 50%;
    }

    .addBtn {
        position: absolute;
        bottom: 0;
        left: 50%;
        display: flex;
        align-items: center;
        justify-content: center;
        box-sizing: border-box;
        min-width: 50px;
        height: 20px;
        padding: 0 4px;
        background-color: #ebfcf9;
        border: solid 1px #ebfcf9;
        border-radius: 4px;
        transform: translateX(-50%);
        cursor: pointer;

        span {
            color: #00d5b0;
        }

        & > span:last-child {
            position: relative;
            left: 2px;
            margin-left: 2px;
            font-size: 12px;
            white-space: nowrap;
        }

        &.addBtnDis {
            background-color: #f5f5f5;
            border-color: #ebebeb;
            cursor: not-allowed;

            span {
                color: #ccc;
            }
        }
    }

    .addIcon {
        font-weight: 500;
        font-family: PingFangSC-Medium;
        font-size: 9px;
        line-height: 1;

        &.addIconDis {
            color: #ccc;
        }
    }
}
