@import url('~@/styles/utils.less');

.searchWrapper {
    display: flex;

    .searchImage {
        position: relative;
        z-index: 1;
        display: flex;
        flex-shrink: 0;
        align-items: center;
        width: 112px;
        height: 74px;
        margin-right: 8px;
        overflow: hidden;
        border-radius: 4px;

        & img {
            display: block;
            width: 112px;
            height: 74px;
            object-fit: cover;
        }
    }

    .searchVideoImg {
        &::after {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            content: '';
        }

        .video_play_btn {
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            margin: auto;
            display: flex;
            justify-content: center;
            align-items: center;
            background-color: rgba(0, 0, 0, 0.3);
            z-index: 10;
        }
    }

    .searchMain {
        display: flex;
        flex: 1;
        flex-direction: column;
        overflow: hidden;
    }

    .linkBox {
        display: flex;
        flex: 1;
        flex-direction: column;
    }

    .titleWrapper {
        display: flex;
        margin-bottom: 12px;
    }

    .searchTitle {
        .maxLine(2);

        font-weight: normal;
        line-height: 22px;
        font-size: var(--pc-font-size-14);
        white-space: normal;
        color: #262626;

        span {
            color: var(--pc-base-f7-color);
        }
    }

    .searchBottom {
        display: flex;
        justify-content: space-between;
        overflow: hidden;
        font-size: 12px;
        line-height: 1.5;

        .searchBottomLeft {
            flex: 1;
            margin-right: 20px;
            display: flex;
            align-items: center;

            .searchRuleName {
                .maxLine(1);
                color: #262626;
            }

            .searchTime {
                flex-shrink: 0;
            }

            i {
                height: 12px;
                margin: 0 7.5px;
                display: inline-block;
                flex-grow: 0;
                border: solid 1px #e5e5e5;
            }

            span {
                display: flex;
                align-items: flex-end;
                color: #999;
            }

            .view_num {
                display: flex;
                align-items: center;
                span {
                    margin-right: 8px;
                }
                &::before {
                    content: '';
                    display: inline-block;
                    width: 1px;
                    height: 12px;
                    margin: 0 7.5px;
                    background-color: #e5e5e5;
                }
            }
        }
        .collect {
            flex-shrink: 0;
            font-size: 12px;
            font-weight: normal;
            font-stretch: normal;
            font-style: normal;
            line-height: 1.5;
            letter-spacing: normal;
            text-align: left;
            color: #999;
        }
    }
}
