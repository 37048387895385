.ant_popover {
    :global {
        .ant-popover-inner {
            box-sizing: border-box;
            width: 200px;
            padding: 24px 0 0 !important;
            overflow: hidden;
            border-radius: 4px !important;
        }
    }
}

.ant_popover_en {
    :global {
        .ant-popover-inner {
            width: 225px;
        }
    }
}

.extends_plan {
    position: relative;
    cursor: pointer;
}

.extends_plan_item {
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 18px;
    margin: 0 24px 24px;
    margin-bottom: 24px;
    color: #262f2d;
    font-size: var(--pc-font-size-18);
    line-height: 1;

    &:hover {
        color: var(--pc-primary-color);
    }
}

.extends_plan_left {
    display: flex;
    align-items: center;

    span {
        position: relative;

        .msg {
            position: absolute;
            top: -1px;
            right: -6px;
            width: 6px;
            height: 6px;
            background-color: #fe3434;
            border-radius: 50%;
        }
    }

    & > span:first-child {
        width: 18px;
        height: 18px;
        margin-right: 8px;
        font-size: 18px;
        line-height: normal;
    }
}

.login_btn {
    display: flex;
    align-items: center;
    justify-content: center;
    box-sizing: border-box;
    width: 98px;
    height: 40px;
    color: #262f2d;
    border: solid 1px #05e8c1;
    border-radius: 4px;
    background-color: #05e8c1;

    &:hover {
        color: #fff;
        // border-color: var(--theme-color);
    }
}

.log_up {
    width: 100%;
    height: 48px;
    color: #999999;
    line-height: 48px;
    text-align: center;
    background-color: #fbfbfb;
}

.avatar {
    display: flex;
    align-items: center;
    height: 40px;

    & > span:last-child {
        margin-left: 8px;
        color: var(--pc-normal-color);
        line-height: var(--pc-line-height-a);
        letter-spacing: 0.5px;
    }

    &:hover {
        & > span:last-child {
            color: var(--pc-normal-color);
        }
    }

    img {
        width: 24px;
        height: 24px;
        overflow: hidden;
        border-radius: 50%;
    }

    // button {
    //     width: 98px;
    //     height: 40px;
    //     color: var(--pc-button-f-color);
    //     border-radius: 4px;
    //     box-shadow: none;
    //     cursor: pointer;
    // }
}
