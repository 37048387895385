.search_contain {
    position: relative;
    display: flex;
    width: 190px;
    height: 36px;
    font-size: var(--pc-font-size-16);
    transition: width 0.3s;

    input:-internal-autofill-selected {
        transition: background-color 5000s ease-in-out 0s !important;
    }
}

.form {
    width: 100%;

    :global {
        .ant-input-affix-wrapper:hover {
            border-color: #e5e5e5 !important;
        }

        .ant-input-affix-wrapper-focused,
        .ant-input-affix-wrapper:focus {
            border-color: #e5e5e5 !important;
            box-shadow: none !important;
        }
    }
}

.search_input {
    display: flex;
    align-items: center;
    box-sizing: border-box;
    width: 100%;
    height: 100%;
    min-height: 0;
    padding: 4px 16px !important;
    font-size: var(--pc-font-size-14);
    border: solid 1px #e5e5e5;
    border-radius: 4px;
    outline: none;
    box-shadow: none;

    :global {
        .ant-input {
            box-sizing: border-box;
            width: 100%;
            height: 16px;
            min-height: 0;
            font-size: var(--pc-font-size-14);
            background-color: transparent;
            border: none;
            outline: none;
            caret-color: var(--theme-color);
            &::placeholder {
                color: #666;
            }
        }

        .ant-input-suffix {
            font-size: 16px;
        }
    }
}

.search_has {
    border-color: var(--pc-base-f2-color);
}

.search_color {
    input {
        // color: var(--theme-color);
    }
}

.search_focus {
    width: 100%;
    max-width: 900px;
}

.icon_group {
    display: flex;
    align-items: center;
}

.input_icon {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 18px;
    height: 18px;
    margin-right: 8px;
    color: var(--pc-base-f2-color);
    font-size: 16px !important;
    line-height: 1;
    border-radius: 8px;
    cursor: pointer;

    &:last-child {
        margin-right: 0;
    }
}

.sh_icon {
    color: #262626;
}
