.search_contain {
    flex: 1;
    max-width: 180px;
    height: 40px;
    display: flex;
    position: relative;
    font-size: var(--pc-font-size-16);
    // transition: all 0.1s;

    .form {
        width: 100%;
    }

    .search_input {
        display: flex;
        align-items: center;
        box-sizing: border-box;
        width: 100%;
        height: 100%;
        min-height: 0;
        padding: 0 24px 0 48px;
        font-size: var(--pc-font-size-14);
        border: solid 1px var(--pc-normal-hover-color);
        background-color: var(--pc-line-background);
        border-radius: 20px;
        outline: none;
        box-shadow: none;

        :global {
            .ant-input {
                box-sizing: border-box;
                width: 100%;
                height: 16px;
                min-height: 0;
                color: var(--pc-normal-color);
                font-size: var(--pc-font-size-14);
                background-color: transparent;
                border: none;
                outline: none;
                caret-color: var(--pc-primary-color);
            }

            .ant-input-suffix {
                font-size: 16px;
            }
        }
    }

    .search_has {
        border-color: var(--pc-base-f2-color);
    }

    .search_color {
        input {
            color: var(--pc-primary-color);
        }
    }

    input:-internal-autofill-selected {
        transition: background-color 5000s ease-in-out 0s !important;
    }
}

.search_focus {
    max-width: 100%;
}

.search_icon {
    position: absolute;
    top: 51.5%;
    left: 24px;
    color: var(--pc-base-f1-color);
    transform: translateY(-50%);
}

.close_icon {
    cursor: pointer;
    width: 16px;
    height: 16px;
    display: block;
    font-size: 16px !important;
    border-radius: 8px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: var(--pc-base-f2-color);
    position: relative;
    top: 1px;
}
