.title {
  width: 100%;
  height: 56px;
  margin-bottom: 24px;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: relative;
  -webkit-user-select: none;
  user-select: none;

  &::after {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    z-index: 1;
    width: 100%;
    height: 4px;
    background-color: var(--pc-normal-hover-color);
  }

  &>div {
    height: 100%;
    display: flex;
    align-items: center;
    font-size: var(--pc-font-size-20);
    color: var(--pc-normal-color);
    position: relative;
    z-index: 2;

    &::after {
      content: '';
      position: absolute;
      bottom: 0;
      left: 0;
      z-index: 2;
      width: 100%;
      height: 4px;
      background-color: var(--pc-primary-color);
    }
  }

  &>span {
    display: flex;
    align-items: center;
    height: 50%;
    font-size: var(--pc-font-size-12);
    color: var(--pc-base-f1-color);
    cursor: pointer;
  }
}