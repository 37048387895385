.flashNewsTitle {
    display: flex;
    justify-content: space-between;
    align-items: center;

    padding: 24px 16px;
    border-radius: 6px 6px 0 0;
    .flashNewsTitle_tag {
        margin-bottom: 0;
    }

    .flashNewsTitle_more {
        font-size: 14px;
        color: #999;
        line-height: 22px;
    }
}

.box {
    max-width: 332px;
    background-image: url('/img/newsFlash/img_news_flash.webp') !important;
    background-position: top;
    background-repeat: no-repeat;
    background-color: #fff;

    border-radius: 6px;
    overflow: hidden;
    box-sizing: border-box;
    .wrapper {
        position: relative;
        height: 100%;

        .tabsBottom {
            position: absolute;
            bottom: 0;
            right: 0;
            width: 100%;
            height: 72px;
            border-radius: 0 0 6px 6px;
            z-index: 9;
            background-image: linear-gradient(to top, #fff 0%, transparent 100%);
            pointer-events: none;
        }
    }
}

.tabsCon {
    height: calc(100% - 84px);
    overflow: hidden;
    overflow-y: scroll;
    border-radius: 0 0 6px 6px;
}

.top_box {
    z-index: 3;
    display: flex;
    flex-shrink: 0;
    justify-content: space-between;
    box-sizing: border-box;
    padding: 24px 16px 12px;
    background-color: #fff;
    border-radius: 6px 6px 0 0;
    .wrapper__title {
        position: relative;
        flex-shrink: 0;
        h2 {
            position: relative;
            z-index: 1;
            color: #000;
            font-weight: 500;
            font-family: PingFangSC-Medium;
            font-size: 24px;
            font-style: normal;
            font-stretch: normal;
            line-height: 30px;
            letter-spacing: normal;
        }

        .top_bg_icon {
            position: absolute;
            top: -10px;
            background-size: 23px 21px;
            left: -10px;
            width: 23px;
            height: 21px;
        }
    }

    .top_link {
        display: flex;
        align-items: center;
        justify-content: center;
        color: #999;
        font-size: 14px;
        line-height: 22px;
        cursor: pointer;
        &:hover {
            color: #2c36de !important;
            &::after {
                color: #2c36de !important;
            }
        }
    }

    .top_link::after {
        margin-left: 6px;
        color: #999 !important;
        font-weight: 500;
        font-family: PingFangSC-Medium;
        font-size: 12px;
        /* stylelint-disable-next-line font-family-no-missing-generic-family-keyword */
        font-family: swiper-icons;
        font-variant: normal;
        font-variant: initial;
        line-height: 1;
        letter-spacing: 0;
        text-align: center;
        text-transform: none !important;
        content: 'next';
    }
}

.tabsCon {
    scrollbar-width: none;
    scrollbar-color: #e7e7e7 #fff;

    &:hover {
        scrollbar-width: thin;
        &::-webkit-scrollbar-thumb {
            display: block;
        }
    }

    &::-webkit-scrollbar {
        width: 6px;
    }

    &::-webkit-scrollbar-track {
        background-color: transparent !important;
        box-shadow: none;
    }

    &::-webkit-scrollbar-thumb {
        display: none;
        background-color: #e7e7e7 !important;
        width: 6px;
        border-radius: 6px;
    }
}

// @media screen and (max-width: 628px) {
//     .flashNewsTit {
//         padding: 12px 0;
//         margin-bottom: 0;

//         span {
//             &:nth-of-type(2) {
//                 font-size: 20px;
//             }
//         }
//     }

//     .tabsConBox,
//     .tabsCon {
//         padding-top: 0 !important;
//     }
// }
