@import url('~@/styles/utils.less');

.editorAdvertisingCard {
    :global {
        .editor__advertising__card {
            width: 100%;
            & > div {
                padding: 0 !important;
            }
        }
    }
}

.globalEditorContent {
    overflow: hidden;
    color: #333;
    font-size: 16px;
    line-height: 1.88;
    letter-spacing: 0.5px;
    word-break: normal;

    * {
        max-width: 100% !important;
    }

    ul,
    li,
    p,
    blockquote {
        margin-bottom: 26px;
        &:last-child {
            margin-bottom: 0;
        }
    }

    h1,
    h2,
    h3,
    h4,
    h5 {
        margin: 30px 0 14px;
        color: #333;
        font-weight: 500;
        font-family: PingFangSC-Medium;
        line-height: 1.5;
        &:first-child {
            margin-top: 0;
        }
    }

    h1,
    h2 {
        font-size: 24px;
    }
    h3 {
        font-size: 22px;
    }
    h4 {
        font-size: 20px;
    }
    h5 {
        font-size: 18px;
    }

    img {
        max-width: 100% !important;
        height: auto;
        object-fit: contain;
    }

    video,
    audio {
        width: 100% !important;
    }

    video {
        object-fit: contain;
        background: #000;
    }
    table {
        max-width: 100% !important;
        border-collapse: collapse;
    }

    ul,
    ol,
    li {
        padding: revert;
    }

    ul,
    ol {
        padding-left: 22px;
    }
    ul ul,
    ol ol {
        margin-top: 26px;
        padding-left: 16px;
    }
    // ---------------- 无序列表 ----------------
    ul > li {
        color: #262f2d;
        list-style-type: disc;
        &::marker {
            font-size: 16px;
        }
    }
    ul > li > ul > li {
        list-style-type: circle;
        &::marker {
            font-size: 16px;
        }
    }
    ul > li > ul > li > ul > li {
        list-style-type: square;
        &::marker {
            font-size: 14px;
        }
    }
    ul > li > ul > li > ul > li > ul > li {
        list-style-type: disc;
        &::marker {
            font-size: 14px;
        }
    }

    // ---------------- 有序列表 ----------------
    ol > li {
        list-style-type: decimal;
    }
    ol > li > ol > li {
        list-style-type: lower-latin;
    }
    ol > li > ol > li > ol > li {
        list-style-type: lower-roman;
    }
    ol > li > ol > li > ol > li > ol > li {
        list-style-type: decimal;
    }
    .editorAdvertisingCard();
}
