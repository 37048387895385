@import '~@/styles/utils.less';

.li .top {
    display: flex;
    align-items: center;
}

.li .logo_box {
    position: relative;
    width: 116px;
    height: 77px;
    margin-right: 8px;
    overflow: hidden;
    background-color: #ffffff;
    border-radius: 4px;
}

.li .logo {
    position: relative;
    width: 100%;
    height: 100%;
    overflow: hidden;
    border: solid 1px #e5e5e5;
    box-sizing: border-box;
    border-radius: 4px;
}

.li .logo img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.li .info {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: calc(100% - 124px);
    height: 77px;
}

.li .info .info_top {
    width: 100%;
    margin: 0;
}

.li .info .info_top p {
    margin: 0;
}

.li .info .info_top .name {
    width: 100%;
    line-height: 1.57;
    color: #262626;
    .maxLine(1);
    word-break: break-all;
    margin-bottom: 8px;
}

.li .info .info_top .name span:last-child {
    height: 22px;
    line-height: 22px;
    padding: 0 10px;
    border-radius: 4px;
    background-color: #f5f5f5;
    font-size: 12px;
    color: #666;
}

.li .info .info_top .score {
    display: flex;
    align-items: center;

    span:first-child {
        position: relative;
        display: inline-block;
        margin-right: 8px;
        padding: 0 1px;
        color: #262626;
        font-weight: 500;
        font-family: PingFangSC-Medium;
        font-size: 16px;
        line-height: 1;

        strong {
            position: relative;
            z-index: 1;
            font-family: D-DIN, sans-serif;
        }

        &::after {
            position: absolute;
            bottom: 0;
            left: 0;
            width: 100%;
            height: 4px;
            background-color: #ffa216;
            border-radius: 2px;
            content: '';
        }
    }

    span:last-child {
        color: #999;
        font-size: 12px;
        line-height: 1.5;
    }
}

.li .tags {
    display: flex;
    align-items: center;

    span {
        display: inline-block;
        height: 20px;
        line-height: 20px;
        padding: 0 10px;
        color: #666;
        font-size: 12px;
        border-radius: 4px;
        white-space: pre;

        &:first-child {
            color: var(--theme-color);
            background-color: #f0f3ff;
        }
    }
}

.bot_list {
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-size: 12px;
    line-height: 1.5;
    color: #666;
    margin-top: 12px;

    .license {
        display: block;
        color: #999;
        background-color: transparent;
        overflow: hidden;
        white-space: pre;
        text-overflow: ellipsis;
    }

    .del {
        font-size: 12px;
        line-height: 1.5;
        color: #999;
        padding-left: 24px;
    }
}
