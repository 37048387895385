.nav_box {
    height: 0;
    transition: all 0.2s;
    background-color: #fff;
    overflow: hidden;
    border-top: 1px solid #f5f5f5;

    &.nav_box_show {
        height: 40px;
    }
}

.nav {
    max-width: 100%;
    height: 100%;
    overflow: hidden;
    transition: all 0.3s;
    margin: 0 auto;
}

.nav_hide {
    width: 0;
}

.list {
    display: flex;
    height: 100%;
}

.list_item {
    width: auto !important;
    padding: 0 24px;
    color: #262626;
    cursor: pointer;
    user-select: none;
    position: relative;

    &::after {
        content: '';
        position: absolute;
        top: 50%;
        right: 0;
        transform: translateY(-50%);
        width: 1px;
        height: 16px;
        background-color: #e5e5e5;
    }

    &:first-child {
        padding-left: 16px;
    }

    &:last-child {
        &::after {
            display: none;
        }
    }

    &:hover,
    &.active {
        .aHref {
            color: var(--theme-color);
        }
    }

    &.active {
        .aHref {
            font-weight: 500;
            font-family: PingFangSC-Medium;
        }
    }

    .aHref {
        position: relative;
        display: flex;
        align-items: center;
        height: 100%;
        color: #262626;
        white-space: nowrap;
    }
}

@keyframes line_fun {
    from {
        bottom: -4px;
        opacity: 0.4;
    }

    to {
        bottom: 0;
        opacity: 1;
    }
}

.barkly_container {
    border-top: 1px solid #0c0d13;
}
