.swiperContain {
    overflow: hidden;
    height: 100%;
    -webkit-user-select: none;
    user-select: none;
    position: relative;
    left: 50%;
    transform: translateX(-50%);

    :global {
        .swiper-pagination {
            height: 2px;
            bottom: 20px;
            display: flex;
            justify-content: center;
        }

        .swiper-button-disabled {
            opacity: 1;
            cursor: pointer;
            pointer-events: auto;
        }
    }

    & > div {
        height: 100%;
    }

    .swiperCon {
        border-radius: 4px;
        overflow: hidden;
    }

    .swiperSlideBox {
        width: 100%;
        height: 100%;
        position: relative;
    }

    .swiperSlide {
        box-sizing: border-box;
        height: 100%;
    }

    .swiperBullet {
        width: 28px;
        height: 2px;
        border-radius: 1px;
        background: rgba(255, 255, 255, 0.5);
        display: inline-block;
        margin-right: 4px;

        &:last-child {
            margin-right: 0;
        }
    }

    .swiperBulletActive {
        position: relative;
        overflow: hidden;

        &::after {
            content: '';
            width: 0;
            height: 100%;
            position: absolute;
            top: 0;
            left: 0;
            background-color: #fff;
            border-radius: 1px;
            animation: progressMove linear 5s 1 forwards;
        }
    }

    .swiperSlideTitle {
        position: absolute;
        left: 0;
        bottom: 0;
        width: 100%;
        min-height: 116px;
        max-height: 152px;
        background-image: linear-gradient(to bottom, rgba(38, 47, 45, 0), #262f2d);
        box-sizing: border-box;
        font-size: var(--pc-font-size-24);
        font-weight: 500;
        font-family: PingFangSC-Medium;
        color: var(--pc-base-background);
        cursor: pointer;
        z-index: 1;

        .swiperSlideTitleText {
            min-height: 36px;
            max-height: 72px;
            line-height: 150%;
            margin: 40px 24px;
            box-sizing: border-box;
            white-space: pre-wrap;
            overflow: hidden;
            text-overflow: ellipsis;
            display: -webkit-box;
            -webkit-line-clamp: 2;
            -webkit-box-orient: vertical;
            letter-spacing: 0.2px;
        }

        .swiperSlideTitleOne {
            line-height: 32px;
            padding: 0 24px;
            margin: 22px 24px;
        }
    }

    .swiperBtnNext,
    .swiperBtnPrev {
        top: 50%;
        width: 38px;
        height: 38px;
        object-fit: contain;
        background-color: rgba(255, 255, 255, 0.1);
        // box-shadow: 0 0 8px 0 rgba(0, 0, 0, 0.2);
        opacity: 0;
        border-radius: 50%;
        transition: opacity 0.8s;

        &::after {
            color: var(--pc-base-background);
            font-weight: 400;
            font-size: 12px;
        }
    }

    &:hover {
        .swiperBtnNext,
        .swiperBtnPrev {
            opacity: 1;
            background-color: #fff;
            box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.25);
            &::after {
                color: #171617;
            }
        }
    }

    .swiperBtnNext {
        right: 16px;
    }

    .swiperBtnPrev {
        left: 16px;
    }

    &:hover {
        .swiperBtnNext,
        .swiperBtnPrev {
            opacity: 1;
        }
    }

    .adTag {
        height: 24px;
        padding: 0 6px;
        position: absolute;
        top: 4px;
        right: 4px;
        border-radius: 4px;
        background-color: #000000;
        display: flex;
        align-items: center;
        z-index: 2;
        // line-height: 1;

        &.closePad {
            padding: 0 4px;
            cursor: pointer;
        }

        span {
            font-size: 14px;
            letter-spacing: 0.5px;
            color: var(--pc-base-background);
        }

        .closeIcon {
            height: 19px;
            font-size: 19px;
            padding: 0 0.5px;
            overflow: hidden;
            color: var(--pc-base-background);

            &::before {
                height: 19px;
                display: flex;
                align-items: center;
                padding: 0;
            }
        }
    }
}

@keyframes progressMove {
    0% {
        width: 0;
    }

    100% {
        width: 100%;
    }
}

@media screen and (max-width: 998px) {
    .swiperSlideTitle {
        font-size: var(--pc-font-size-18);
        min-height: 60px;
        max-height: 108px;

        .swiperSlideTitleText {
            min-height: 28px;
            max-height: 70px;
            margin: 12px 16px 22px;
        }

        .swiperSlideTitleOne {
            line-height: 150%;
            margin: 12px;
        }
    }
}
