@import url('./video.module.less'); // 视频样式
@import url('./theme.less'); // 主题样式

@font-face {
    font-family: D-DIN;
    font-display: swap;
    src: url('/font/D-DIN.ttf');
}

@font-face {
    font-family: SanFranciscoDisplay;
    font-display: swap;
    src: url('/font/SanFranciscoDisplay.otf');
}

/* Regular 字体 */
@font-face {
    font-family: 'PingFangSC';
    font-display: swap;
    src: url('/font/PingFang-SC-Regular.ttf');
}

/* Medium 字体 */
@font-face {
    font-family: 'PingFangSC-Medium';
    font-display: swap;
    src: url('/font/PingFang-SC-Medium.ttf');
}

html,
body {
    height: 100%;
}

* {
    margin: 0;
    padding: 0;
    scrollbar-width: thin;
    scrollbar-color: #e7e7e7 #fff;
    -webkit-tap-highlight-color: transparent;
}

canvas {
    display: block;
}

body {
    // font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen,
    //   Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, sans-serif;
    text-rendering: optimizelegibility;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    word-break: normal;
    background-color: #fff;
}

ul,
ol {
    list-style: none;
}

a {
    text-decoration: none;
}

::-webkit-scrollbar {
    width: 10px;
    height: 10px;
}

::-webkit-scrollbar-thumb {
    background: #e7e7e7;
    border-radius: 5px;
    box-shadow: inset 0 0 5px #e7e7e7;
}

::-webkit-scrollbar-track {
    background: hsla(0deg, 0%, 100%, 0.15);
    box-shadow: inset 0 0 5px #2525250d;
}

.flex {
    display: flex;
}

.ant-message {
    top: 72px !important;
}

.rc-virtual-list-scrollbar-thumb {
    width: 4px !important;
    background: #e7e7e7 !important;
}

/* stylelint-disable-next-line selector-pseudo-element-no-unknown */
input::input-placeholder {
    color: var(--pc-base-f1-color);
}

input:-moz-input-placeholder {
    color: var(--pc-base-f1-color);
}

input::-moz-input-placeholder {
    color: var(--pc-base-f1-color);
}

/* stylelint-disable-next-line selector-pseudo-class-no-unknown */
input:input-placeholder {
    color: var(--pc-base-f1-color);
}

input {
    &:autofill {
        background: #fff; // 支持火狐
    }

    // 支持chrome
    &:-webkit-autofill,
    &:-webkit-autofill:hover,
    &:-webkit-autofill:focus,
    &:-webkit-autofill:active {
        transition: background-color 5000s;
        //  -webkit-text-fill-color: #fff !important;
    }
}

#nprogress .bar {
    background: var(--theme-color) !important;
}
