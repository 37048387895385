@import '~@/styles/utils.less';

.wrapper {
    display: flex;
}
.cover {
    margin: auto 0;
    flex-shrink: 0;
    width: 68px;
    height: 68px;
    border-radius: 68px;
    position: relative;
    margin-right: 8px;
    overflow: hidden;
}

.info__wrapper {
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
}
.info__top {
    display: flex;
}
.info__top__left {
    flex: 1;
}
.title {
    .maxLine(1);
    font-size: 16px;
    font-weight: 500;
    font-family: PingFangSC-Medium;
    font-stretch: normal;
    font-style: normal;
    line-height: 24px;
    letter-spacing: normal;
    text-align: left;
    color: #262626;
}
.article__num {
    margin-top: 4px;
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 22px;
    letter-spacing: normal;
    text-align: left;
    color: #666;
    span {
        color: var(--theme-color);
    }
}
.introduction {
    .maxLine(1);
    margin-top: 8px;
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 22px;
    letter-spacing: normal;
    text-align: left;
    color: #999;
}
.focus {
    flex-shrink: 0;

    height: 26px;
    box-sizing: border-box;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    gap: 4px;
    padding: 4px 12px;

    border-radius: 4px;
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 18px;
    letter-spacing: normal;
    text-align: center;
    color: var(--color-text-primary);

    background-color: #f5f5f5;
    cursor: pointer;

    picture {
        width: 14px;
        height: 14px;
    }
    img {
        width: 14px;
        height: 14px;
    }
    span {
        flex-shrink: 0;
    }

    &.not {
        background-color: var(--theme-color);
        color: var(--invalid-name);
        padding: 4px 10px;
        &:hover {
            background-color: var(--btn-hover-color);
        }
    }
}
