.header {
    position: fixed;
    top: 0;
    z-index: 1000;
    width: 100%;
    background-color: var(--pc-base-background);
}

.top_banner {
    :global {
        .ant-image {
            img {
                min-height: 80px;
            }
        }
    }
}

.header_box {
    display: flex;
    align-items: center;
    justify-content: space-between;
    box-sizing: border-box;
    width: 100%;
    height: 45px;
    padding: 0 12px;
}

// ----------------------------------------- left
.header_left {
    display: flex;
    align-items: center;
    height: 100%;
}

.logo {
    width: 141px;
    height: 23px;
    overflow: hidden;
    cursor: pointer;

    a {
        position: relative;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        height: 100%;
    }

    img {
        object-fit: contain !important;
    }
}

// ----------------------------------------- right
.header_right {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    width: calc(100% - 222px);
    height: 100%;
}

.lang__icon {
    display: none;
    margin-right: 14px;
}

.search__icon {
    position: relative;
    width: 20px;
    height: 20px;
    margin: 0 16px;
    color: #000000;
    font-size: 16px;
    line-height: 20px;
}

.download__app {
    position: fixed;
    bottom: 37px;
    left: 12px;
    display: flex;
    align-items: center;
    justify-content: center;
    box-sizing: border-box;
    width: calc(100% - 24px);
    height: 40px;
    background-color: #2c36de;
    border: solid 1px #2c36de;
    border-radius: 4px;

    span {
        color: #ffffff;
        font-size: 16px;
        line-height: 1.5;
    }

    .download__app_icon {
        position: relative;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 22px;
        height: 22px;
        overflow: hidden;
        background: #fff;
        border-radius: 6px;
    }
}

.shade {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 99;
    display: none;
    width: 100vw;
    height: 100vh;
    background-color: #000;
    opacity: 0.5;

    &.open {
        display: block;
    }
}

.download__popup {
    position: fixed;
    bottom: -120%;
    left: 0;
    z-index: 999;
    flex-direction: column;
    align-items: center;
    box-sizing: border-box;
    width: 100%;
    background-color: #fff;
    border-radius: 20px 20px 0 0;
    transition: all 0.3s;

    &.open {
        bottom: 0;
    }

    .download__title {
        margin-top: 26px;
        color: #010b27;
        font-size: 16px;
        text-align: center;
    }

    .download__button {
        display: flex;
        flex-direction: column;
        align-items: center;
        margin-top: 25px;

        .button__wrapper {
            display: flex;
            align-items: center;
            justify-content: center;
            width: 240px;
            height: 48px;
            font-size: 14px;
            background-color: #fff;
            border: solid 1px rgba(1, 11, 39, 0.2);
            border-radius: 8px;

            &:hover {
                background-color: rgba(3, 203, 169, 0.1);
                border: solid 1px #03cba9;
            }

            .picture {
                position: relative;
                width: 32px;
                height: 32px;
            }

            .button__text {
                color: #010b27;
            }

            &:nth-child(2) {
                margin-top: 16px;
            }
        }
    }

    .download__option {
        display: flex;
        align-items: center;
        box-sizing: border-box;
        width: 100vw;
        height: 48px;
        margin-top: 38px;
        font-size: 14px;
        border-top: 1px solid #f7f7f7;

        .option__close {
            display: flex;
            flex: 1;
            align-items: center;
            justify-content: center;
            height: 100%;
            color: #999;
        }

        .option__confirm {
            display: flex;
            flex: 1;
            align-items: center;
            justify-content: center;
            box-sizing: border-box;
            height: 100%;
            color: #03cba9;
            border-left: 1px solid #f7f7f7;
        }
    }
}

.en_reg_log_btn {
    width: 120px !important;
}
