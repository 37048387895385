.before {
    color: #00c800 !important;
    border-color: #00c800 !important;
}

.progressing {
    color: #ff3141 !important;
    border-color: #ff3141 !important;
}

.end {
    color: #999999 !important;
    border-color: #999999 !important;
}

.listItem {
    display: flex;
    flex-direction: column;
    box-sizing: border-box;
    // width: 278px;
    height: 358px;
    margin-bottom: 24px;
    overflow: hidden;
    background: #fff;
    border-radius: 8px;
    box-shadow: 0 0 10px 0 rgba(0, 21, 41, 0.06);
    cursor: pointer;
    transition: all 0.2s;
    user-select: none;

    &:hover {
        transform: translateY(-5px);
    }
}

.listItemCover {
    position: relative;
    width: 100%;
    height: 152px;
}

.listItemContent {
    display: flex;
    flex: 1;
    flex-direction: column;
    justify-content: space-between;
    padding: 8px 16px 24px;
}

.title {
    display: -webkit-box; //特别显示模式
    box-sizing: border-box;
    height: 50px;
    overflow: hidden; //溢出内容隐藏
    color: #262b2e;
    font-weight: 500;
    font-family: PingFangSC-Medium;
    font-size: 18px;
    line-height: 1.5;
    text-overflow: -o-ellipsis-lastline;
    text-overflow: ellipsis; //文本溢出部分用省略号表示
    word-break: normal;
    -webkit-line-clamp: 2; //行数
    line-clamp: 2;
    -webkit-box-orient: vertical; //盒子中内容竖直排列
}

.time {
    color: #666;
    font-size: 14px;
}

.btns {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.countdown {
    padding: 6px 10px;
    color: #262b2e;
    font-size: 14px;
    line-height: 14px;
    background-color: #fff;
    border: 1px solid #e9e9e9;
    border-radius: 14px;
}

.status {
    position: relative;
    display: inline-block;
    box-sizing: border-box;
    padding: 6px 10px;
    color: #0ec370;
    font-weight: 500;
    font-family: PingFangSC-Medium;
    font-size: 14px;
    line-height: 14px;
    text-align: right;
    border: solid 1px;
    border-radius: 14px;

    i {
        font-size: 16px;
    }
}

.city {
    display: flex;
    align-items: center;
    color: #333333;
    font-size: 12px;

    i {
        width: 12px;
        height: 12px;
        margin-right: 3px;
        color: #999999;
        font-size: 12px;
        line-height: 1;
    }
}

@media screen and (max-width: 415px) {
    .list {
        box-sizing: border-box;
    }

    .listItem {
        margin-bottom: 16px;
        transition: none;

        &:hover {
            transform: translateY(0);
        }
    }

    .title {
        height: auto;
        font-weight: 500;
        font-family: PingFangSC-Medium;
        font-size: var(--pc-font-size-16);
    }
}
