.ant_popover {
    :global {
        .ant-popover-inner {
            width: 146px;
            padding: 0 !important;
        }

        .ant-popover-inner-content {
            overflow: hidden;
            border-radius: 4px;
        }
    }
}

.extends_plan {
    position: relative;
    cursor: pointer;
}

.extends_plan_list {
    width: 100%;

    li {
        text-align: center;
        height: 38px;
        line-height: 38px;

        span {
            display: inline-block;
            width: 100%;
            height: 100%;
        }

        &:hover {
            background-color: #f7f7f8;
        }
    }
}

.extends_plan_left {
    display: flex;
    align-items: center;

    span {
        position: relative;

        .msg {
            position: absolute;
            top: -1px;
            right: -6px;
            width: 6px;
            height: 6px;
            background-color: #fe3434;
            border-radius: 50%;
        }
    }

    & > span:first-child {
        width: 18px;
        height: 18px;
        margin-right: 8px;
        font-size: 18px;
        line-height: normal;
    }
}

.login_btn {
    display: flex;
    align-items: center;
    justify-content: center;
    box-sizing: border-box;
    min-width: 106px;
    padding: 0 8px;
    width: max-content;
    height: 38px;
    color: var(--invalid-name);
    background-color: var(--theme-color);

    border-radius: 4px;
    font-size: 16px;

    &:hover {
        background-color: var(--btn-hover-color);
    }
}

.log_up {
    width: 100%;
    height: 38px;
    padding: 0 16px;
    line-height: 38px;
    text-align: center;
    box-sizing: border-box;
    position: relative;

    &::after {
        content: '';
        position: absolute;
        top: 0;
        left: 16px;
        width: calc(100% - 32px);
        height: 0;
        border-top: 1px dashed #e5e5e5;
    }

    &:hover {
        color: var(--theme-color);
        background-color: #f7f7f8;
    }
}

.avatar {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 36px;

    .icon {
        width: 36px;
        height: 36px;
        border: 1px solid #e5e5e5;
        border-radius: 50%;
        box-sizing: border-box;
    }

    .name {
        margin-left: 4px;
        color: #262626;
    }

    &:hover {
        & > span:last-child {
            color: var(--pc-normal-color);
        }
    }

    img {
        width: 24px;
        height: 24px;
        overflow: hidden;
        border-radius: 50%;
    }

    button {
        width: 98px;
        height: 40px;
        color: var(--pc-button-f-color);
        border-radius: 4px;
        box-shadow: none;
        cursor: pointer;
    }
}
// 弹出的用户
.avatar_s {
    padding: 0 16px;
    height: 46px;

    .icon {
        width: 22px;
        height: 22px;
    }

    .name {
        max-width: calc(100% - 26px);
        font-size: 14px;
        line-height: 1.57;
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
    }

    &:hover {
        background-color: #f7f7f8;
    }
}

// nav上展示的用户
.avatar_l {
    .name {
        font-size: 16px;
        line-height: 1.5;
    }
}
.avatar__barkly {
    & > span:last-child {
        color: var(--invalid-name);
    }
    &:hover {
        & > span:last-child {
            color: var(--invalid-name);
        }
    }
}
