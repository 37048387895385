@font-face {
    font-family: D-DIN;
    font-display: swap;
    src: url('/font/D-DIN.ttf');
}

// 文字限制一行
.overflow__hiding__one {
    display: -webkit-box; //特别显示模式
    overflow: hidden; //溢出内容隐藏
    text-overflow: -o-ellipsis-lastline;
    text-overflow: ellipsis; //文本溢出部分用省略号表示
    word-break: normal;
    -webkit-line-clamp: 1; //行数
    line-clamp: 1;
    -webkit-box-orient: vertical; //盒子中内容竖直排列
}

// 背景图默认样式
.bg__default {
    background-repeat: no-repeat;
    background-position: center center;
    background-size: cover;
}

.wrapper {
    position: relative;
    display: flex;
    justify-content: space-between;
    box-sizing: border-box;
    min-height: 176px;
    padding: 24px 0;
    background-color: #fff !important;

    &:hover {
        .main__wrapper > .title {
            color: var(--pc-primary-color);
        }
    }

    .icon {
        position: absolute;
        top: 21px;
        left: 0;
        z-index: 1;
        display: inline-block;
        width: 24px;
        height: 29px;
    }

    .left__wrapper {
        .bg__default();

        position: relative;
        box-sizing: border-box;
        width: 217px;
        height: 128px;
        flex-shrink: 0;
        border: solid 1px #ebebeb;
    }

    .main__wrapper {
        display: flex;
        flex: 1;
        flex-direction: column;
        justify-content: space-between;
        margin-left: 18px;
        overflow: hidden;

        .title {
            .overflow__hiding__one();

            color: #010b27;
            font-size: 20px;
            font-style: normal;
            font-stretch: normal;
            line-height: 26px;
            letter-spacing: 0.5px;
            cursor: pointer;

            & > span {
                color: var(--pc-base-f7-color);
            }
        }

        .content__wrapper {
            flex: 1;
            margin-top: 8px;
            color: #999;
            font-weight: normal;
            font-size: 14px;
            font-style: normal;
            font-stretch: normal;
            line-height: 14px;
            letter-spacing: 0.5px;

            .fullName {
                .overflow__hiding__one();

                margin-bottom: 11px;
                color: #666;
                letter-spacing: 0.5px;

                & > span {
                    color: var(--pc-base-f7-color);
                }
            }

            .supervise {
                .overflow__hiding__one();
            }

            .mobile__score {
                display: none;
                align-items: center;
                color: #262f2d;
                font-size: 12px;
                letter-spacing: 0.5px;

                .score {
                    color: #fe3434;
                    font-weight: normal;
                    font-size: 18px;
                    font-family: D-DIN, sans-serif;
                    font-style: normal;
                    font-stretch: normal;
                    line-height: normal;
                    letter-spacing: 0.5px;
                }
            }

            .mobile__star {
                display: none;
            }
        }

        .tags__list {
            .overflow__hiding__one();

            .tags__item {
                padding: 5px 12px;
                color: #ff8502;
                font-weight: normal;
                font-size: 12px;
                font-stretch: normal;
                letter-spacing: 0.43px;
                background-color: rgba(255, 133, 2, 0.15);
                border-radius: 2px;
            }

            .tags__mobile {
                display: none;
                color: #999;
                font-weight: normal;
                font-size: 12px;
                font-style: normal;
                font-stretch: normal;
                line-height: normal;
                letter-spacing: 0.43px;
            }
        }
    }

    .right__wrapper {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: flex-end;
        width: 174px;

        .grade__text {
            margin-bottom: 8px;
            color: #fe3434;
            font-weight: normal;
            font-size: 40px;
            font-family: D-DIN, sans-serif;
            font-style: normal;
            font-stretch: normal;
            line-height: normal;
            letter-spacing: 1px;
        }
    }
}

.contrast__wrapper {
    display: flex;
    justify-content: flex-end;
    width: 100%;

    .contrast {
        display: flex;
        align-items: center;
        justify-content: center;
        box-sizing: border-box;
        width: 72px;
        height: 34px;
        margin-bottom: 24px;
        color: #00d5b0;
        font-size: 14px;
        line-height: 1.5;
        letter-spacing: 0.5px;
        border: solid 1px #00d5b0;
        border-radius: 17px;

        &:hover {
            color: #262f2d;
            background-color: rgba(5, 232, 193, 0.48);
            border: solid 1px #008c74;
        }
    }
}

.contrast__wrapper_pc {
    position: absolute;
    top: 24px;
    right: 0;
    width: 72px;
    height: 34px;
    cursor: pointer;
}

.grade__warpper {
    display: flex;
    align-items: center;
    height: 16px;

    .star {
        position: relative;
        display: inline-block;
        width: 16px;
        height: 16px;
        margin-left: 4px;

        &:first-child {
            margin-left: 0;
        }
    }
}

.tabs__wrapper {
    position: relative;
    box-sizing: border-box;
    padding-right: 20px;

    .operate {
        position: absolute;
        top: 10px;
        right: 0;
        width: 18px;
        height: 20px;
        cursor: pointer;
    }

    .tabs__item {
        margin-right: 50px;
        padding: 2px;
        color: #262f2d;
        font-weight: normal;
        font-size: 18px;
        font-style: normal;
        font-stretch: normal;
        line-height: 40px;
        letter-spacing: 0.5px;
        cursor: pointer;

        &:last-child {
            margin: 0;
        }

        &.active {
            position: relative;
            font-weight: 500;
            font-family: PingFangSC-Medium;
            background-repeat: no-repeat;
            background-position: bottom;
            background-size: 100% 12px;
        }
    }
}

.mobile {
    display: none !important;
}

.search__list {
    margin-bottom: 24px;
    padding: 24px;
}

.businessTag {
    top: 24px !important;
    right: -24px !important;
}

@media screen and (max-width: 768px) {
    .wrapper {
        min-height: 0;
        margin-bottom: 24px;
        padding: 0;

        &:hover {
            .main__wrapper > .title {
                color: #010b27;
            }
        }

        &.all__wrapper {
            margin-bottom: 16px;
        }

        &.search__list {
            padding: 0;
        }

        &:first-child {
            margin-top: 4px;
        }

        .icon {
            top: -1px;
            left: 0;
        }

        .left__wrapper {
            width: 120px;
            height: 90px;
        }

        .right__wrapper {
            display: none;
        }

        .main__wrapper {
            .title {
                color: #010b27;
                font-size: 16px;
            }

            .content__wrapper {
                margin-top: 0;

                .fullName {
                    display: none;
                }

                .supervise {
                    display: none;
                }

                .mobile__score {
                    display: block;

                    span {
                        margin-right: 4px;
                    }

                    .score_box {
                        margin-bottom: 8px;
                    }
                }

                .mobile__star {
                    display: block;
                }
            }

            .tags__list {
                .tags__item {
                    display: none;
                }

                .tags__mobile {
                    display: block;
                }
            }
        }

        .contrast__wrapper {
            &.mobile {
                // display: block !important;
                display: flex !important;
                justify-content: flex-end;
            }

            justify-content: flex-start;
            margin-top: 12px;

            .contrast {
                width: 64px;
                height: 28px;
                margin-bottom: 0;
                font-size: 12px;
                border-radius: 17px;
            }
        }
    }

    .contrast__wrapper_pc {
        display: none;
    }

    .grade__warpper {
        .star {
            width: 10px;
            height: 10px;
        }
    }

    .businessTag {
        top: 0 !important;
        right: 0 !important;
    }
}
