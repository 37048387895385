.overlay {
    z-index: 1000;
    cursor: pointer;

    :global {
        .ant-popover-inner {
            padding: 0 !important;
        }
        .ant-popover-inner-content {
            overflow: hidden;
            border-radius: 4px;
        }
    }

    .p {
        display: flex;
        align-items: center;
        justify-content: center;
        min-width: 92px;
        height: 38px;
        color: #333;
        background-color: #ffffff;

        &:hover {
            background-color: #f7f7f8;
        }

        &.active {
            color: var(--theme-color);
            background-color: #f7f7f8;
        }
    }

    &:hover {
        cursor: pointer;
    }
}

.content {
    display: flex;
    align-items: center;
    justify-content: center;
    box-sizing: border-box;
    min-width: 94px;
    height: 38px;
    line-height: 38px;

    .prefix {
        color: #000;
        font-size: 16px;
    }

    .suffix {
        color: #000;
        font-size: 12px;
    }

    .text {
        font-size: 16px;
        margin: 0 4px;
        color: #262626;
    }

    &:hover {
        background-color: #f7f7f8;
    }
}
.barkly_container {
    border-color: #fff;
    .prefix {
        color: #fff;
    }
    .suffix {
        color: #fff;
    }
    .text {
        color: #fff;
    }
    &:hover {
        background-color: rgba(0, 0, 0, 0.5);
        border-color: #fff;

        .prefix,
        .suffix,
        .text {
            color: #fff;
        }
    }
}
