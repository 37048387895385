.wrapper {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: space-between;

    .top_bg_icon {
        position: absolute;
        top: -10px;
        left: -10px;
        background-size: 23px 21px;
        width: 23px;
        height: 21px;
    }

    .top_title {
        position: relative;
        z-index: 1;
        font-size: 24px;
        font-weight: 500;
        font-family: PingFangSC-Medium;
        line-height: 1.25;
        color: #000;
    }
}

@media screen and (max-width: 500px) {
    .wrapper {
        .top_title {
            font-size: 16px;
        }
        .top_bg_icon {
            top: -4px;
            left: -6px;
            background-size: 18px 16px;
            width: 18px;
            height: 16px;
        }
    }
}

.barkly_container {
    padding-left: 10px;
    .top_bg_icon {
        left: 0;
    }
    .top_title {
        color: #fff;
    }
}
