.wrapper__no_content {
    display: flex;
    background-color: #fff;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
}
// ---------- 暗黑模式 Start ----------
.wrapper__no_content.barkly_container {
    background-color: transparent;
}
// ---------- 暗黑模式 End ----------

.wrapper__img {
    width: 130px;
    flex-shrink: 0;
    height: 123.8px;
    flex-grow: 0;
    position: relative;
    object-fit: cover;
}
.wrapper__text {
    padding: 0 28px 0 47px;
    flex-shrink: 0;
    height: 22px;
    font-size: 14px;
    line-height: 22px;
    letter-spacing: 0.07px;
    color: #999;
    margin-top: 22px;
}

@media screen and (max-width: 768px) {
    .wrapper__img {
        width: 80px;
        height: 76.2px;
    }
}
