.customModal {
    top: 30% !important;

    :global {
        .ant-modal-content {
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            min-height: 151px;
            padding: 24px 32px !important;
            border-radius: 8px;

            .ant-modal-close {
                &:hover {
                    background: transparent !important;
                    background-color: transparent !important;
                }
            }

            .ant-modal-title {
                color: var(--pc-normal-color);
                font-weight: normal;
                font-size: 16px;
            }

            .ant-modal-footer {
                button {
                    padding: 5px 0;
                    color: #999999;
                    font-size: 16px;
                    line-height: var(--pc-line-height-a);

                    &:last-child {
                        margin-left: 24px !important;
                        color: var(--pc-normal-color);
                    }

                    &:hover {
                        color: var(--pc-primary-color);
                    }
                }
            }
        }
    }
}
