.wrapper {
    height: 18px;
    position: absolute;
    font-size: 12px;
    padding: 0 6px;
    background-color: #f5f5fa;
    line-height: 18px;
    color: var(--theme-color);
    border-radius: 4px 9px 9px 0;
}

.leftTop {
    left: 0;
    top: 0;
}

.leftBottom {
    left: 0;
    bottom: 0;
}

.rightTop {
    right: 0;
    top: 0;
    border-radius: 100px 0 0 100px;
}

.rightBottom {
    right: 0;
    bottom: 0;
    border-radius: 100px 0 0 100px;
}
