.modify_title {
    font-size: 20px;
    font-weight: 500;
    font-family: PingFangSC-Medium;
    line-height: 1.4;
    text-align: center;
    margin-bottom: 40px;
}

.modify_form {
    :global {
        .ant-form-item {
            margin-bottom: 40px;
        }

        .ant-form-item-label {
            padding-bottom: 12px;
        }

        .ant-form-item-label > label {
            height: auto !important;
            font-size: 16px;
            line-height: 1.5;
            color: #262626;

            &.ant-form-item-required {
                &::before {
                    display: none !important;
                }
            }
        }

        .ant-form-item-explain {
            padding-top: 8px;
        }

        .ant-form-item-explain-error {
            line-height: 1.5;
            color: #f00 !important;
        }

        .ant-input-affix-wrapper,
        .ant-input-number-input {
            padding: 11px 16px !important;
        }

        .ant-input-suffix {
            cursor: pointer;
        }
    }

    .form_tip {
        line-height: 1.57;
        color: #999;
        margin-bottom: 12px;
    }

    .form_val {
        height: 22px;
        line-height: 1.57;
        color: #262626;
        margin-bottom: 12px;
    }

    .input_mobile {
        padding: 0 16px 0 0 !important;

        :global {
            .ant-input {
                padding-left: 16px !important;
            }

            .ant-input-prefix {
                margin-right: 0;
            }
        }
    }

    .phone_code {
        position: relative;

        &::after {
            content: '';
            position: absolute;
            top: 50%;
            right: 0;
            width: 1px;
            height: 16px;
            background-color: #999;
            transform: translateY(-50%);
        }
    }

    .phone_code_sel {
        height: auto;
        border: none !important;
        box-shadow: none !important;
        padding: 0;

        :global {
            .ant-select-selector {
                font-size: 16px;
                min-width: 50px;
            }

            .ant-select-selector,
            .ant-select-selector:hover,
            .ant-select-selector:focus {
                padding: 0 16px !important;
                border: none !important;
                box-shadow: none !important;
            }

            .ant-select-selection-search {
                inset-inline-start: 16px !important;
                inset-inline-end: 16px !important;
            }

            .ant-select-arrow {
                display: none;
            }

            .ant-select-selection-item {
                padding: 0 !important;
            }
        }
    }

    .btn_item {
        margin-bottom: 0 !important;
    }

    .btn {
        &.btn_dis {
            background-color: #adbaff;

            &:hover,
            &:focus {
                background-color: #adbaff !important;
            }
        }
    }
}

.popup {
    padding: 0 !important;

    :global {
        .ant-select-item {
            height: 40px;
            line-height: 30px;
            border-radius: 0;
            color: #000;
        }

        .rc-virtual-list-scrollbar {
            width: 4px !important;
        }
    }
}

.other_tip {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 42px;

    span:first-child,
    span:last-child {
        width: 23%;
        height: 1px;
        background-color: #e5e5e5;
    }

    span:nth-of-type(2) {
        margin: 0 12px;
        color: #999;
        font-size: 12px;
        line-height: 1.5;
    }
}

.verify_list {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 24px;

    li {
        padding: 0 24px;
        font-size: 14px;
        line-height: 1.57;
        color: #999;
        cursor: pointer;
        position: relative;
        display: flex;
        align-items: center;

        .icon {
            width: 16px;
            height: 16px;
            display: flex;
            align-items: center;
            justify-content: center;
        }

        .icon_normal {
            display: block;
        }

        .icon_active {
            display: none;
        }

        &::after {
            content: '';
            position: absolute;
            top: 50%;
            right: 0;
            width: 1px;
            height: 14px;
            background-color: #e5e5e5;
            transform: translateY(-50%);
        }

        &:first-child {
            padding-left: 0;
        }

        &:last-child {
            padding-right: 0;

            &::after {
                display: none;
            }
        }

        &:hover {
            color: var(--theme-color);

            .icon_normal {
                display: none;
            }

            .icon_active {
                display: block;
            }
        }

        .text {
            margin-left: 8px;
        }
    }
}
